import React from "react";
// Customizable Area Start
import {
  Box,
  Typography,
  createTheme,
  ThemeProvider,
  Breadcrumbs,
  Card,
  IconButton,
  Modal,
} from "@material-ui/core";
import dayjs from 'dayjs';
import { styled } from "@material-ui/core/styles";
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import { I18n } from "../../../components/src/languageJson/i18n";
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { datepicker, handburger, closebtn, clockIcon, cardDatepick, attendeesIcon, datePickerIcon, leftArrowIcon, RightArrowIcon, deleteIcon } from "./assets";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});
import AppNavigation from '../../../components/src/AppNavigation.web';
// Customizable Area End

import AppoinmentsControllerweb, {
  Props,
} from "./AppoinmentsControllerweb";
import { Link } from "react-router-dom";

export default class Appointments extends AppoinmentsControllerweb {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  useStyles = {
    statusProgess: {
      height: "22px",
      width: "111px",
      fontSize: "12px",
      fontWeight: 700,
      fontFamily: "Inter",
      lineHeight: "18px",
      borderRadius: "40px",
      color: "#527FE2",
      backgroundColor: "#BFDBFE",
      textAlign: "center" as "center",
      direction: this.getValueBasedOnLanguage("rtl", "ltr"),
    },
    statusPendding: {
      height: "22px",
      width: "111px",
      fontSize: "12px",
      fontWeight: 700,
      fontFamily: "Inter",
      lineHeight: "18px",
      borderRadius: "40px",
      color: "#D97706",
      backgroundColor: "#FEF3C7",
      textAlign: "center" as "center",
      direction: this.getValueBasedOnLanguage("rtl", "ltr"),
    },
    statusFinished: {
      height: "22px",
      width: "111px",
      fontSize: "12px",
      fontWeight: 700,
      fontFamily: "Inter",
      lineHeight: "18px",
      borderRadius: "40px",
      color: "#527FE2",
      backgroundColor: "#BFDBFE",
      textAlign: "center" as "center",
      direction: this.getValueBasedOnLanguage("rtl", "ltr"),
    },
    tableRow: {
      margin: "5px 0 5px 0",
      display: "flex",
      flexDirection: this.getValueBasedOnLanguage("row-reverse", "row"),
      width: "100%",
    },
    tableHead: {
      width: "100%",
      fontSize: "16px",
      fontWeight: 700,
      color: "#FFFFFF",
      fontFamily: this.getValueBasedOnLanguage("Noto Sans Arabic", "Poppins"),
      whiteSpace: "nowrap" as "nowrap",
    },
    tableBody: {
      width: "100%",
      fontSize: "16px",
      fontWeight: 400,
      color: "#000000",
      fontFamily: this.getValueBasedOnLanguage("Noto Sans Arabic", "Poppins"),
      textAlign: "center" as "center",
      direction: this.getValueBasedOnLanguage("rtl", "ltr"),
    },
    tableBodyStatus: {
      width: "100%",
      fontSize: "16px",
      fontWeight: 400,
      color: "#000000",
      fontFamily: this.getValueBasedOnLanguage("Noto Sans Arabic", "Poppins"),
      display: "flex",
      gap: 20,
      textAlign: "center" as "center",
      direction: this.getValueBasedOnLanguage("rtl", "ltr"),
    },
    subOption: {
      fontSize: "14px", lineHeight: "24px",
      fontFamily: this.getValueBasedOnLanguage("Noto Sans Arabic", "Poppins"),
      fontWeight: 400,
      direction: this.getValueBasedOnLanguage("rtl", "ltr")
    },
    options: {
      fontSize: "14px",
      lineHeight: "24px",
      fontWeight: 700,
      direction: this.getValueBasedOnLanguage("rtl", "ltr"),
      fontFamily: this.getValueBasedOnLanguage("Noto Sans Arabic", "Poppins"),
    },
    navLinks: {
      fontWeight: 400,
      fontSize: "14px",
      fontFamily: this.getValueBasedOnLanguage("Noto Sans Arabic", "Poppins"),
      color: "#000000",
      textDecoration: "none",
    },
  }


  CardBox = styled(Card)({
    height: "200vh",
    backgroundColor: "fffffff",
    border: "1 px solid #CBD5E1",
    width: "100%",
    padding: '30px',
    maxWidth: '93%',
    borderRadius: "10px",
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
    "@media (max-width:560px)": {
      overflow: "scroll",
      display: "flex"
    },

  });
  BreadcrumbsCust = styled(Breadcrumbs)({
    display: 'flex',
    flexWrap: 'nowrap',
    '& .MuiBreadcrumbs-ol': {
      display: 'flex',
      flexWrap: 'nowrap',
    },
  });


  // Customizable Area End
  render() {
    // Customizable Area Start
    const { appointmentsList } = this.state;
    const weekDates = this.getWeekDates(this.state.currentDate);
    const times = this.generateTimes();

    const appointments: any[] = [
      {
        colIndex: 0,
        rowIndex: 0,
        title: 'Appointment-1',
        time: '11:00 AM',
        date: '28th Nov 2024',
        attendees: 'John Doe',
        attendees1: '2'
      },
      {
        colIndex: 2,
        rowIndex: 3,
        title: 'Appointment-2',
        time: '11:00 AM',
        date: '28th Nov 2024',
        attendees: 'Jane Smith',
        attendees1: '2'
      },
      {
        colIndex: 0,
        rowIndex: 2,
        title: 'Appointment-3',
        time: '11:00 AM',
        date: '28th Nov 2024',
        attendees: 'David Johnson',
        attendees1: '2'
      },
    ];

    return (
      <div style={{ display: 'flex', flexDirection: this.state.currentLanguage === 'ar' ? "row-reverse" : "row" }} data-testid="language-container">
        <AppNavigation />
        <div style={{ flex: 1, height: '200vh' }}>
          <ThemeProvider theme={theme}>
            <div style={{ backgroundColor: '#F1F5FC', padding: '50px 20px' }}>
              <div
                style={{
                  alignSelf: "center",
                  display: "flex",
                  flexDirection: this.state.currentLanguage === "ar" ? "row-reverse" : "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                  padding: "16px",
                }}
              >
                <this.BreadcrumbsCust
                  separator={this.getValueBasedOnLanguage(<NavigateBeforeIcon />, <NavigateNextIcon />)}
                  style={{
                    color: "#000000",
                    whiteSpace: "nowrap",
                    display: "flex",
                    flexDirection: this.state.currentLanguage === "ar" ? "row-reverse" : "row",
                  }}
                >
                  <Link
                    to=""
                    style={{
                      ...this.useStyles.navLinks,
                      textAlign: this.state.currentLanguage === "ar" ? "right" : "left",
                    }}
                  >
                    {I18n.t("home")}
                  </Link>
                  <Link
                    to=""
                    style={{
                      ...this.useStyles.navLinks,
                      fontWeight: "700",
                      color: "#3f51b5",
                      textAlign: this.state.currentLanguage === "ar" ? "right" : "left",
                    }}
                  >
                    {I18n.t("appointmentmanagementText")}
                  </Link>
                </this.BreadcrumbsCust>
              </div>
              <div
                style={{
                  marginTop: '30px',
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                }}
              >
                <Box>
                  <div
                    style={{
                      display: "flex",
                      justifyContent:"space-between",
                      flexDirection: this.state.currentLanguage === "ar" ? "row-reverse" : "row",
                      padding: "20px 20px",
                    }}
                  >
                    <Typography
                      noWrap
                      variant="h6"
                      style={{
                        fontWeight: "700",
                        fontFamily: "Poppins",
                        textAlign: this.state.currentLanguage === "ar" ? "right" : "left",
                      }}
                    >
                      {I18n.t("appoinmentheadingText")}
                    </Typography>
                    <button
                      style={{
                        color: "#527FE2",
                        border: "1px solid #527FE2",
                        fontWeight: "700",
                        padding: '12px',
                        width: '213px',
                        borderRadius: "8px",
                        fontFamily: "Poppins",
                        display: 'flex',
                        justifyContent: 'center',
                        backgroundColor: 'transparent',
                        textAlign: this.state.currentLanguage === "ar" ? "right" : "left",
                      }}
                    >
                      {I18n.t("setAvailability")}
                    </button>
                  </div>
                </Box>
              </div>
              <this.CardBox style={{ borderRadius: "16px" }}>
                <div
                  style={{
                    display: "flex",
                    gap: "16px",
                    alignItems: "center",
                    padding: "8px 10px",
                    justifyContent: "space-between",
                    backgroundColor: "#fffff",
                    borderRadius: "8px",
                    flexDirection: this.state.currentLanguage === "ar" ? "row-reverse" : "row",
                  }}
                >
                  <div
                    style={{
                      position: "relative",
                      borderRadius: "8px",
                      backgroundColor: "white",
                      display: "inline-block",
                    }}
                  >
                    <select
                      style={{
                        width: "100%",
                        fontWeight: "700",
                        padding: "10px 16px",
                        backgroundColor: "white",
                        borderRadius: "8px",
                        border: "1px solid #527FE2",
                        paddingLeft: this.state.currentLanguage === "ar" ? "16px" : "40px",
                        paddingRight: this.state.currentLanguage === "ar" ? "40px" : "16px",
                        direction: this.state.currentLanguage === "ar" ? "rtl" : "ltr",
                        textAlign: this.state.currentLanguage === "ar" ? "right" : "left",
                      }}
                    >
                      <option>
                        {this.state.currentLanguage === "ar" ? "نوفمبر 2024" : "Nov 2024"}
                      </option>
                    </select>

                    <img
                      src={datePickerIcon}
                      style={{
                        position: "absolute",
                        top: "50%",
                        left: this.state.currentLanguage === "ar" ? "unset" : "16px",
                        right: this.state.currentLanguage === "ar" ? "16px" : "unset",
                        height: "18px",
                        transform: "translateY(-50%)",
                        width: "18px",
                        pointerEvents: "none",
                      }}
                    />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "5px",
                      padding: "10px",
                      backgroundColor: "#F8FAFC",
                      justifyContent: "center",
                      borderRadius: "10px",
                      flexDirection: this.state.currentLanguage === "ar" ? "row-reverse" : "row",
                    }}
                  >
                    <img
                      src={datepicker}
                      alt="Home"
                      onClick={() => this.handleIconClick("home")}
                      data-test-id="datepickerIcon"
                      style={{
                        width: "23px",
                        height: "23px",
                        cursor: "pointer",
                        padding: "10px",
                        borderRadius: "8px",
                        backgroundColor: this.state.activeIcon === "home" ? "white" : "transparent",
                        color: this.state.activeIcon === "home" ? "black" : "#94A3B8",
                        transition: "background-color 0.3s",
                        boxShadow: this.state.activeIcon === "home" ? "0 4px 8px rgba(0, 0, 0, 0.2)" : "none",
                      }}
                    />
                    <img
                      src={handburger}
                      alt="Settings"
                      onClick={() => this.handleIconClick("settings")}
                      data-test-id="handburgerIcon"
                      style={{
                        width: "30px",
                        cursor: "pointer",
                        height: "30px",
                        borderRadius: "8px",
                        backgroundColor: this.state.activeIcon === "settings" ? "white" : "transparent",
                        color: this.state.activeIcon === "settings" ? "black" : "#94A3B8",
                        transition: "background-color 0.3s",
                        boxShadow: this.state.activeIcon === "settings" ? "0 4px 8px rgba(0, 0, 0, 0.2)" : "none",
                        padding: "5px",
                      }}
                    />
                  </div>
                </div>
                <div>
                  <div
                    style={{
                      display: 'grid',
                      gridTemplateColumns: '1fr 2fr 2fr 2fr 2fr 2fr 1fr',
                      marginTop: '10px',
                      borderCollapse: 'collapse',
                      width: '100%',
                      direction: this.state.currentLanguage === "ar" ? "rtl" : "ltr"
                    }}
                  >
                    <div
                      onClick={this.handlePrev}
                      style={{
                        backgroundColor: '#527FE2',
                        padding: '8px',
                        textAlign: 'center',
                        fontWeight: '700',
                        border: '1px solid #CBD5E1',
                        borderTopRightRadius: this.state.currentLanguage === "en" ? '0px' : '10px',
                        borderTopLeftRadius: this.state.currentLanguage === "en" ? '10px' : '0px',
                        color: 'white',
                        cursor: 'pointer',
                      }}
                      data-test-id="previousDate"
                    >
                      <img src={this.state.currentLanguage === "en" ? RightArrowIcon : leftArrowIcon} style={{ marginTop: "5px" }} />
                    </div>
                    {weekDates.map((date, index) => (
                      <div
                        key={index}
                        style={{
                          backgroundColor: '#527FE2',
                          padding: '8px',
                          textAlign: 'center',
                          fontWeight: '700',
                          color: 'white',
                          border: '1px solid #CBD5E1',
                        }}
                      >
                        {`${date.toLocaleString('en-US', { weekday: 'short' })} ${date.getDate()}`}
                      </div>
                    ))}
                    <div
                      onClick={this.handleNext}
                      style={{
                        padding: '8px',
                        textAlign: 'center',
                        fontWeight: '700',
                        backgroundColor: '#527FE2',
                        color: 'white',
                        border: '1px solid #CBD5E1',
                        borderTopRightRadius: this.state.currentLanguage === "en" ? '10px' : '0px',
                        borderTopLeftRadius: this.state.currentLanguage === "en" ? '0px' : '10px'

                      }}
                      data-test-id="nextDate"
                    >
                      <img src={this.state.currentLanguage === "en" ? leftArrowIcon : RightArrowIcon} style={{ marginTop: "5px" }} />
                    </div>
                  </div>
                  <div
                    style={{
                      display: 'grid',
                      gridTemplateColumns: '1fr 2fr 2fr 2fr 2fr 2fr 1fr',
                      gridTemplateRows: `repeat(${times.length}, 100px)`,
                      borderCollapse: 'collapse',
                      width: '100%',
                      height: 'auto',
                      borderRadius: '0px',
                      overflow: 'hidden',
                      direction: this.state.currentLanguage === "ar" ? "rtl" : "ltr"

                    }}
                  >
                    {times.map((time, rowIndex) => (
                      <React.Fragment key={rowIndex}>
                        <div
                          style={{
                            gridColumn: '1',
                            fontWeight: '700',
                            fontFamily: "Poppins",
                            color: '#000',
                            border: '1px solid #CBD5E1',
                            padding: '8px',
                            backgroundColor: '#fffff',
                            textAlign: 'center',
                          }}
                        >
                          {time}
                        </div>

                        {weekDates.map((date, colIndex) => (
                          <div
                            key={`${rowIndex}-${colIndex}`}
                            data-test-id={`slot-${rowIndex}-${colIndex}`}
                            onMouseEnter={(e) => {
                              if (!(appointments.some(appointment => appointment.colIndex === colIndex && appointment.rowIndex === rowIndex))) {
                                e.currentTarget.style.backgroundColor = '#DBEAFE';
                              }
                            }}
                            onMouseLeave={(e) => {
                              if (!(appointments.some(appointment => appointment.colIndex === colIndex && appointment.rowIndex === rowIndex))) {
                                e.currentTarget.style.backgroundColor = '#fff';
                              }
                            }}
                            style={{
                              padding: '16px',
                              border: '1px solid #CBD5E1',
                              position: 'relative',
                              backgroundColor: '#fffff',
                            }}
                            onClick={() => this.navigateTo('/AddAppointmentweb')}
                          >
                            {appointments.map((appointment, index) => (
                              colIndex === appointment.colIndex && rowIndex === appointment.rowIndex && (
                                <div
                                  key={`${appointment.colIndex}-${appointment.rowIndex}`}
                                  onMouseEnter={(e) => {
                                    e.currentTarget.style.backgroundColor = '#DBEAFE';
                                  }}
                                  onMouseLeave={(e) => {
                                    e.currentTarget.style.backgroundColor = '#F8FAFC';
                                  }}
                                  style={{
                                    position: 'absolute',
                                    top: '50%',
                                    left: '50%',
                                    transform: 'translate(-50%, -50%)',
                                    width: '80%',
                                    backgroundColor: '#F8FAFC',
                                    color: '#0F172A',
                                    padding: '8px',
                                    textAlign: 'center',
                                    fontWeight: '700',
                                    borderRadius: '8px',
                                    fontSize: '14px',
                                    boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
                                  }}
                                  onClick={() => this.setSelectedAppointment(appointment)}
                                  data-test-id={`appointment${index}`}
                                >
                                  {appointment.title}
                                  <p style={{ fontSize: '8px', fontWeight: '400' }}>{appointment.time}</p>
                                </div>
                              )
                            ))}
                          </div>
                        ))}
                        {this.state.selectedAppointment && this.state.selectedAppointmentPosition && (
                          <div
                            style={{
                              position: 'absolute',
                              top: this.state.selectedAppointmentPosition.top,
                              left: this.state.selectedAppointmentPosition.left,
                              transform: 'translate(-50%, -50%)',
                              width: '300px',
                              backgroundColor: '#FFFFFF',
                              borderBottomLeftRadius: '24px',
                              borderBottomRightRadius: '24px',
                              borderTopRightRadius: '24px',
                              boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                              padding: '16px',
                              zIndex: '100',
                            }}
                          >
                            <div
                              style={{
                                alignItems: 'center',
                                display: 'flex',
                                justifyContent: 'space-between',
                                gap: '8px',
                                padding: '10px 10px 0px 10px'
                              }}
                            >
                              <h3
                                style={{
                                  fontSize: '16px',
                                  margin: 0,
                                  color: '#111827',
                                }}
                              >
                                {this.state.selectedAppointment?.title || 'No Appointment Selected'}
                              </h3>
                              <img src={closebtn}
                                style={{
                                  marginTop: '8px',
                                  display: 'block',
                                  width: '12px',
                                  color: '#111827',
                                  border: 'none',
                                  borderRadius: '4px',
                                  cursor: 'pointer',
                                }}
                                onClick={() => this.setSelectedAppointment(null)}
                                data-test-id="closecard"
                              />
                            </div>
                            <div>
                              <p style={{ fontSize: '14px', marginBottom: '4px', paddingLeft: '10px' }}>
                                With: {this.state.selectedAppointment.attendees}
                              </p>
                            </div>
                            <div
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: "10px",
                                padding: '8px'
                              }}
                            >
                              <div
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  gap: '4px',
                                  padding: '8px'
                                }}
                              >
                                <img
                                  src={cardDatepick}
                                  alt="Date"
                                  style={{
                                    width: '18px',
                                    height: '18px',
                                    cursor: 'pointer',
                                    borderRadius: '8px',
                                    padding: '10px',
                                  }}
                                />
                                <p
                                  style={{
                                    fontSize: '14px',
                                    margin: 0,
                                    color: '#1F2937',
                                  }}
                                >
                                  Date <br />
                                  {this.state.selectedAppointment?.date || 'Not Selected'}
                                </p>
                              </div>

                              <div
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  gap: '4px',
                                }}
                              >
                                <img
                                  src={clockIcon}
                                  alt="Time"
                                  style={{
                                    width: '18px',
                                    height: '18px',
                                    cursor: 'pointer',
                                    borderRadius: '8px',
                                    padding: '10px',
                                  }}
                                />
                                <p
                                  style={{
                                    fontSize: '14px',
                                    margin: 0,
                                    color: '#1F2937',
                                  }}
                                >
                                  Time <br />
                                  {this.state.selectedAppointment?.time || 'Not Selected'}
                                </p>
                              </div>
                            </div>
                            <div
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: '4px',
                                padding: '8px'
                              }}
                            >
                              <img
                                src={attendeesIcon}
                                alt="Time"
                                style={{
                                  width: '25px',
                                  cursor: 'pointer',
                                  borderRadius: '8px',
                                  padding: '10px',
                                }}
                              />
                              <p
                                style={{
                                  fontSize: '14px',
                                  margin: 0,
                                  color: '#1F2937',
                                }}
                              >
                                Attendees <br />
                                {this.state.selectedAppointment?.attendees1 || 'Not Selected'}
                              </p>
                            </div>
                            <div style={{ display: 'flex', gap: "10px" }}>
                              <button
                                style={{
                                  marginRight: '8px',
                                  padding: '8px 16px',
                                  backgroundColor: '#527FE2',
                                  color: '#FFFFFF',
                                  fontWeight: '700',
                                  border: 'none',
                                  borderRadius: '4px',
                                  cursor: 'pointer',
                                  fontSize: '12px'
                                }}

                              >
                                Reschedule
                              </button>
                              <button
                                style={{
                                  padding: '8px 16px',
                                  color: '#527FE2',
                                  fontWeight: '700',
                                  borderRadius: '4px',
                                  cursor: 'pointer',
                                  border: '1px solid #527FE2',
                                  fontSize: '12px'
                                }}
                                onClick={() => this.handleOpenDeleteModal()}
                                  data-test-id="cancel-Appointment"
                              >
                                Cancel Appointment
                              </button>
                            </div>
                          </div>
                        )}
                        <div
                          style={{
                            gridColumn: '7',
                            padding: '8px',
                            textAlign: 'center',
                            backgroundColor: '#fffff',
                            border: '1px solid #CBD5E1',
                          }}
                        >
                        </div>
                      </React.Fragment>
                    ))}
                  </div>
                </div>
              </this.CardBox>
            </div>
            <Modal
              open={this.state.openDeleteModal}
              onClose={this.handleCloseDeleteModal}
              aria-labelledby="success-modal-title"
              aria-describedby="success-modal-description"
            >
              <Box
                dir={this.state.currentLanguage === "ar" ? "rtl" : "ltr"}
                style={{
                  padding: "16px 24px",
                  background: "#F1F5FC",
                  borderRadius: "8px",
                  width: "370px",
                  textAlign: "center",
                  top: "50%",
                  position: "absolute",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                }}
              >
                <img src={deleteIcon} style={{ display: "inline-block", justifyContent: "center", padding: "5px", width: "80px" }} />
                <Typography id="success-modal-description" style={{ padding: "5px", fontWeight: 700, fontFamily: "Poppins", color: "#1E293B", fontSize: "12px" }}>
                  Are You sure you want to delete this Appointment?
                </Typography>
                <Typography style={{ padding: "5px", fontFamily: "Poppins", color: "#94A3B8", fontSize: "10px", marginBottom: "8px" }}>
                  This action will delete all data of this and it cannot be retrieved
                </Typography>
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                  <button
                    style={{
                      padding: "8px 16px",
                      backgroundColor: "#DC2626",
                      color: "#fff",
                      border: "none",
                      borderRadius: "4px",
                      marginRight: "20px",
                      cursor: "pointer",
                      fontFamily: "Poppins",
                      fontWeight: 600
                    }}
                    onClick={() => this.handleDelete()}
                    data-test-id="delete-button"
                  >
                    Delete
                  </button>

                  <button
                    onClick={this.handleCloseDeleteModal}
                    style={{
                      padding: "8px 16px",
                      backgroundColor: "#527FE2",
                      color: "#fff",
                      border: "none",
                      borderRadius: "4px",
                      marginRight: "20px",
                      cursor: "pointer",
                      fontFamily: "Poppins",
                      fontWeight: 600
                    }}
                    data-test-id="cancel-button"
                  >
                    Cancel
                  </button>
                </div>
              </Box>
            </Modal>
          </ThemeProvider>
        </div>
      </div>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const webStyle = {
  appointmentContainer: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    flexWrap: "wrap",
    width: "100%",
    backgroundColor: "white",
    marginTop: "20px",
  },
  tableBox: {
    borderWidth: 1,
    borderRadius: 4,
    borderColor: "#ccc",
    padding: 15,
    marginVertical: 10,
  },
};
// Customizable Area End
