import React, { Component } from 'react';
import { Box, Typography, List, ListItem, ListItemIcon, ListItemText, Avatar } from '@material-ui/core';
import { styled } from "@material-ui/core/styles";
import { Link } from 'react-router-dom';
import CustomScrollBar from './CustomScrollBar.web';
import { Message } from "../../framework/src/Message";
import MessageEnum, { getName } from "../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../framework/src/RunEngine";
import { getStorageData } from "../../framework/src/Utilities";
import { IBlock } from "../../framework/src/IBlock";
import {
    userImgIcon, user, logout, payment, Back, inventory, organ, Survey
    , Appoin, help, peopl, role, service, notifi, desk, helpPlusImage, organ_selected, Appoin_selected, service_selected, peopl_selected, desk_unselected
} from "./imgsrc"

export interface Props {
    navigation?: any;
    id?: string;
    // Customizable Area Start
    // Customizable Area End
  }

interface S {
    // Customizable Area Start
    selectedItem: string,
    userName: string,
    userCompany: string,
    userProfileImg: string,
    fetchUserProfileCallId:string,
    isActive:boolean
    // Customizable Area End
  }
  
  interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
  }

class AppNavigation extends Component<Props, S, SS> {
    subScribedMessages: string[];
    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
    
        this.subScribedMessages = [
          // Customizable Area Start
          getName(MessageEnum.AccoutLoginSuccess),
          getName(MessageEnum.RestAPIResponceMessage),
          getName(MessageEnum.SessionResponseMessage),
          // Customizable Area End
        ];
    
        this.state = {
          // Customizable Area Start
          selectedItem: this.getSelectedItemFromStorage(),
          userName: '',
          userCompany: '',
          userProfileImg: '',
          fetchUserProfileCallId:'',
          isActive:false
          // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as unknown as IBlock, this.subScribedMessages);
    
        // Customizable Area Start
        // Customizable Area End
      }

    getSelectedItemFromStorage() {
        return localStorage.getItem('selectedItem') || 'Dashboard';
    }

    handleRemoveSelectedItem = () => {
        localStorage.removeItem('selectedItem');
    }; 

    async receive(from: string, message: Message) {
        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
              );
        
              const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
              );
        
              if(apiRequestCallId == this.state.fetchUserProfileCallId && 
                responseJson !== undefined &&
                responseJson.data
              ) {
                localStorage.setItem('merchantName', responseJson.data.attributes?.user_name);
                localStorage.setItem('merchantImg', Array.isArray(responseJson.data.attributes?.photo_image) && responseJson.data.attributes?.photo_image.length > 0
                && responseJson.data.attributes?.photo_image[0]?.url)
                this.setState({
                    userName: responseJson.data.attributes?.user_name,
                    userCompany: responseJson.data.attributes?.business_name,
                    userProfileImg: 
                        Array.isArray(responseJson.data.attributes?.photo_image) && responseJson.data.attributes?.photo_image.length > 0
                        ? responseJson.data.attributes?.photo_image[0]?.url || userImgIcon
                        : userImgIcon
                });
              }
        }
      }

    async fetchUserProfile(token:string) {
        try {
            const header = {
                token: token as string,
                "Content-Type": "application/json",
                Accept: "application/json",
            };
    
            const requestMessage = new Message(
                getName(MessageEnum.RestAPIRequestMessage)
            );

            this.setState({fetchUserProfileCallId: requestMessage.messageId})
    
            // Set endpoint
            requestMessage.addData(
                getName(MessageEnum.RestAPIResponceEndPointMessage),
                'bx_block_profile/profile/show'
            );
    
            // Set headers
            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestHeaderMessage),
                JSON.stringify(header)
            );
    
            // Set HTTP method
            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestMethodMessage),
                "GET"
            );
    
            // Send the request
            runEngine.sendMessage(requestMessage.id, requestMessage);
        } catch (error) {
            console.error('Failed to load user profile:', error);
        }
    }
    

    handleMenuItemClick = (item: string) => {
        this.setState({ selectedItem: item });
        localStorage.setItem('selectedItem', item);
    };

    async componentDidMount() {
        let tokenvalue = await getStorageData('token')
        this.fetchUserProfile(tokenvalue);
        const appNav = document.querySelector(".app-navigation"); 
        if (appNav && appNav.parentElement) {
            const siblings = Array.from(appNav.parentElement.children).filter(
                (child) => child !== appNav
            );
            siblings.forEach((sibling) => {
                if (sibling instanceof HTMLElement) { 
                    sibling.style.marginLeft = "325px"; 
                }
            });
        }
    }
    
    componentWillUnmount() {
        const appNav = document.querySelector(".app-navigation");
        if (appNav && appNav.parentElement) {
            const siblings = Array.from(appNav.parentElement.children).filter(
                (child) => child !== appNav
            );
            siblings.forEach((sibling) => {
                if (sibling instanceof HTMLElement) { 
                    sibling.style.marginLeft = "0"; 
                }
            });
        }
    }

    render() {
        return (
            <Box className="app-navigation" sx={{}} style={{position:'fixed', zIndex:999, width: 325, height: '100vh', backgroundColor: '#fff', paddingTop: 20, borderRight: '1px solid #E2E8F0', fontFamily: '"Poppins", sans-serif' }}>
                <Box style={{ 
                    padding: 20,
                    zIndex:999, width:300, height:'100vh'
                }}>
                    {/* Logo */}
                    <Box style={{ display: 'flex', alignItems: 'center', paddingLeft: 16, borderBottom: '1px solid #E2E8F0', }}>
                        <Typography variant="h5" style={{ fontWeight: 'bold' }}>

                            <img src={helpPlusImage} alt="Help Plus"
                                style={{
                                    width: '184px',
                                    height: '91px'
                                }} />
                        </Typography>
                    </Box>
                    {/* Menu Items */}
                    <List>
                    <ListItem button style={{ padding: '8px 12px 8px 12px' }}>
                        <img src={Back} alt=""
                            style={{ width: '24px', paddingRight: '15px' }} />
                        <ListItemText style={{fontFamily: '"Poppins", sans-serif'}} primary="Back" />
                    </ListItem>
                    </List>
                    <CustomScrollBar height="calc(100vh - 196px)">
                        <List>
                            <List style={{ borderBottom: '1px solid #E2E8F0' }}>
                                <ListItem 
                                    button
                                    style={{
                                        color: this.state.selectedItem === 'Dashboard' ? '#527FE2' : 'inherit', 
                                        fontFamily: '"Poppins", sans-serif'
                                    }}
                                    onClick={() => this.handleMenuItemClick('Dashboard')} 
                                >
                                    <img src={this.state.selectedItem === 'Dashboard' ? desk : desk_unselected} alt=""
                                        style={{ width: '24px', paddingRight: '15px' }} />
                                    <ListItemText 
                                        primaryTypographyProps={{ style: {fontWeight: this.state.selectedItem === 'Dashboard' ? 1000 : 'normal', fontFamily: '"Poppins", sans-serif' } }}
                                        primary="Dashboard"
                                        
                                    />
                                </ListItem>

                            <ListItem
                                button
                                style={{
                                    color: this.state.selectedItem === 'Payment Admin' ? '#527FE2' : 'inherit',
                                    fontFamily: this.state.selectedItem === 'Payment Admin' ? '"Poppins", sans-serif' : 'inherit',
                                }}
                                onClick={() => this.handleMenuItemClick('Payment Admin')} 
                            >
                                <img src={payment} alt=""
                                    style={{ width: '24px', paddingRight: '15px', cursor: 'pointer' }} />

                                <ListItemText primaryTypographyProps={{ style: {fontWeight: this.state.selectedItem === 'Payment Admin' ? 700 : 'normal', fontFamily: '"Poppins", sans-serif' } }} primary="Payment Admin" />
                            </ListItem>

                            <ListItem
                                button
                                style={{
                                    color: this.state.selectedItem === 'Email and Notification' ? '#527FE2' : 'inherit',
                                    fontFamily: this.state.selectedItem === 'Email and Notification' ? '"Poppins", sans-serif' : 'inherit',
                                }}
                                onClick={() => this.handleMenuItemClick('Email and Notification')} 
                            >
                                <img src={notifi} alt=""
                                    style={{ width: '24px', paddingRight: '15px' }} />
                                <ListItemText primaryTypographyProps={{ style: {fontWeight: this.state.selectedItem === 'Email and Notification' ? 700 : 'normal', fontFamily: '"Poppins", sans-serif' } }} primary="Email and Notification" />
                            </ListItem>

                            <ListItem
                                button
                                style={{
                                    color: this.state.selectedItem === 'Role and Permission' ? '#527FE2' : 'inherit',
                                    fontFamily: this.state.selectedItem === 'Role and Management' ? '"Poppins", sans-serif' : 'inherit',
                                }}
                                onClick={() => this.handleMenuItemClick('Role and Permission')} 
                            >
                                <img src={role} alt=""
                                    style={{ width: '24px', paddingRight: '15px', }} />
                                <ListItemText primaryTypographyProps={{ style: {fontWeight: this.state.selectedItem === 'Role and Management' ? 700 : 'normal', fontFamily: '"Poppins", sans-serif' } }} primary="Role and Permission" />
                            </ListItem>
                        </List>



                        <ListItem 
                            button
                            style={{
                                color: this.state.selectedItem === 'Service Management' ? '#527FE2' : 'inherit',
                                fontFamily: this.state.selectedItem === 'Service Management' ? '"Poppins", sans-serif' : 'inherit',
                            }}
                            onClick={() => this.handleMenuItemClick('Service Management')}
                            component={Link} to="/Catalogue"
                        >
                            <img src={this.state.selectedItem === 'Service Management' ? service_selected : service} alt=""
                                style={{ width: '24px', paddingRight: '15px' }} />
                            <ListItemText primaryTypographyProps={{ style: {fontWeight: this.state.selectedItem === 'Service Management' ? 700 : 'normal', fontFamily: '"Poppins", sans-serif' } }} primary="Service Management" />
                        </ListItem>

                        <ListItem 
                            button
                            style={{
                                color: this.state.selectedItem === 'People Management' ? '#527FE2' : 'inherit',
                                fontFamily: this.state.selectedItem === 'People Management' ? '"Poppins", sans-serif' : 'inherit',
                            }}
                            onClick={() => this.handleMenuItemClick('People Management')}
                            component={Link} to="/AccountGroups"
                        >
                            <img src={this.state.selectedItem === 'People Management' ? peopl_selected : peopl} alt=""
                                style={{ width: '24px', paddingRight: '15px' }} />
                            <ListItemText primaryTypographyProps={{ style: {fontWeight: this.state.selectedItem === 'People Management' ? 700 : 'normal', fontFamily: '"Poppins", sans-serif' } }} primary="People Management" />
                        </ListItem>

                        <ListItem 
                            button
                            style={{
                                color: this.state.selectedItem === 'Request Management' ? '#527FE2' : 'inherit',
                                fontFamily: this.state.selectedItem === 'Request Management' ? '"Poppins", sans-serif' : 'inherit',
                            }}
                            onClick={() => this.handleMenuItemClick('Request Management')}
                            component={Link} to="/RequestManagement"
                        >
                            <img src={this.state.selectedItem === 'Request Management' ? peopl_selected : peopl} alt=""
                                style={{ width: '24px', paddingRight: '15px' }} />
                            <ListItemText primaryTypographyProps={{ style: {fontWeight: this.state.selectedItem === 'Request Management' ? 700 : 'normal', fontFamily: '"Poppins", sans-serif' } }} primary="Request Management" />
                        </ListItem>

                        <ListItem
                            button
                            style={{
                                color: this.state.selectedItem === 'Help AI Management' ? '#527FE2' : 'inherit',
                                fontFamily: this.state.selectedItem === 'Help AI Management' ? '"Poppins", sans-serif' : 'inherit',
                            }}
                            onClick={() => this.handleMenuItemClick('Help AI Management')}
                        >
                            <img src={help} alt=""
                                style={{ width: '24px', paddingRight: '15px' }} />
                            <ListItemText primaryTypographyProps={{ style: {fontWeight: this.state.selectedItem === 'Help AI Management' ? 700 : 'normal', fontFamily: '"Poppins", sans-serif' } }} primary="Help AI Management" />
                        </ListItem>

                        <ListItem
                            button
                            style={{
                                color: this.state.selectedItem === 'Survey Management' ? '#527FE2' : 'inherit',
                                fontFamily: this.state.selectedItem === 'Survey Management' ? '"Poppins", sans-serif' : 'inherit',
                            }}
                            onClick={() => this.handleMenuItemClick('Survey Management')}
                        >
                            <img src={Survey} alt=""
                                style={{ width: '24px', paddingRight: '15px' }} />
                            <ListItemText primaryTypographyProps={{ style: {fontWeight: this.state.selectedItem === 'Survey Management' ? 700 : 'normal', fontFamily: '"Poppins", sans-serif' } }} primary="Survey Management" />
                        </ListItem>

                        <ListItem
                            button
                            style={{
                                color: this.state.selectedItem === 'Appointment Management' ? '#527FE2' : 'inherit',
                                fontFamily: this.state.selectedItem === 'Appointment Management' ? '"Poppins", sans-serif' : 'inherit',
                            }}
                            onClick={() => this.handleMenuItemClick('Appointment Management')}
                            component={Link} to="/AppointmentManagement"
                        >
                            <img src={this.state.selectedItem === 'Appointment Management' ? Appoin_selected : Appoin} alt=""
                                style={{ width: '24px', paddingRight: '15px' }} />
                            <ListItemText primaryTypographyProps={{ style: {fontWeight: this.state.selectedItem === 'Appointment Management' ? 700 : 'normal', fontFamily: '"Poppins", sans-serif' } }} primary={"Appointment\u00A0Management"}  />
                        </ListItem>

                        <ListItem
                            button
                            style={{
                                color: this.state.selectedItem === 'Organization Management' ? '#527FE2' : 'inherit',
                                fontFamily: this.state.selectedItem === 'Organization Management' ? '"Poppins", sans-serif' : 'inherit',
                            }}
                            onClick={() => this.handleMenuItemClick('Organization Management')}
                        >
                            <img src={this.state.selectedItem === 'Organization Management' ? organ_selected : organ} alt=""
                                style={{ width: '24px', paddingRight: '15px' }} />
                            <ListItemText primaryTypographyProps={{ style: {fontWeight: this.state.selectedItem === 'Organization Management' ? 700 : 'normal', fontFamily: '"Poppins", sans-serif' } }} primary={"Organization\u00A0Management"} />
                        </ListItem>

                        <ListItem
                            button
                            style={{
                                color: this.state.selectedItem === 'Inventory Management' ? '#527FE2' : 'inherit',
                                fontFamily: this.state.selectedItem === 'Inventory Management' ? '"Poppins", sans-serif' : 'inherit',
                            }}
                            onClick={() => this.handleMenuItemClick('Inventory Management')}
                        >
                            <img src={inventory} alt=""
                                style={{ width: '24px', paddingRight: '15px' }} />
                            <ListItemText primaryTypographyProps={{ style: {fontWeight: this.state.selectedItem === 'Inventory Management' ? 700 : 'normal', fontFamily: '"Poppins", sans-serif' } }} primary="Inventory Management" />
                        </ListItem>

                        <ListItem button onClick={this.handleRemoveSelectedItem} component={Link} to="/">
                            <img src={logout} alt=""
                                style={{ width: '24px', paddingRight: '15px' }} />
                            <ListItemText primaryTypographyProps={{ style: { fontFamily: '"Poppins", sans-serif' } }} primary="Logout" style={{ color: '#ff0000' }} />
                        </ListItem>
                        </List>

                    {/* User Profile */}
                    <Link onClick={() => {
                        this.handleRemoveSelectedItem();
                        this.handleMenuItemClick('Profile')
                    }} to="/EducationalUserProfile" style={{textDecoration:"none", color:"inherit"}}>
                        <Box style={{ display: 'flex', alignItems: 'center', padding: 16, position: 'absolute', cursor:'pointer', textDecoration:"none", color:"inherit" }}>
                            <Box
                                style={{
                                    width: '56px',
                                    height: '56px',
                                    borderRadius: '50%',
                                    overflow: 'hidden', 
                                    marginRight: '15px',
                                    backgroundColor: '#f0f0f0',
                                }}
                            >
                                <img
                                    src={this.state.userProfileImg || userImgIcon}
                                    alt="User Profile"
                                    style={{
                                        width: '100%',
                                        height: '100%',
                                        objectFit: 'cover',
                                        display: 'block',
                                    }}
                                />
                            </Box>
                            <Box>
                                <Typography variant="body1" style={{fontFamily: '"Poppins", sans-serif', fontWeight: 'bold', color: this.state.selectedItem === 'Profile' ? '#527FE2' : 'inherit',}}>{this.state.userName || ''}</Typography>
                                <Typography variant="body2" color="textSecondary" style={{fontFamily: '"Poppins", sans-serif', color: this.state.selectedItem === 'Profile' ? '#527FE2' : '',}}>{this.state.userCompany || ''}</Typography>
                            </Box>
                        </Box>
                    </Link>
                    </CustomScrollBar>
                </Box>
            </Box>
        );
    }
}

export default AppNavigation;