import React from 'react';
import {
  Box,
  Typography,
  Button,
  TextField,
  Select,
  MenuItem,
  InputAdornment,
  Paper, 
} from '@material-ui/core';
import { FilterList, Send, AttachFile, Mic, ExpandMore, Schedule, Person, Storefront } from '@material-ui/icons';
import { styled } from "@material-ui/styles";
import { pdfIcon, userIcon, profileimg, bro_2x, send_icon} from "./assets";
import AppNavigation from '../../../components/src/AppNavigation.web';
import DynamicBreadcrumbs from "../../../components/src/DynamicBreadcrumbs.web";
import { I18n } from "../../../components/src/languageJson/i18n";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
//import CustomScrollBar from '../../../CustomScrollBar.web';

import TicketDetailsController, {
    Props,
} from "./TicketDetailsController.web";

const theme = createTheme({
    typography: {
      fontFamily: '"Poppins", "sans-serif"',
    },
});

const StyledCommentBox = styled(Box)(
    {
        paddingRight:'10px',
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        gap: '25px',
        overflowY: 'auto',
        maxHeight:'calc(100vh - 200px)',
        '&::-webkit-scrollbar': {
            width: '12px',
            borderRadius: '10px', 
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#527FE2',
            borderRadius: '10px', 
            border: '3px solid transparent',
            backgroundClip: 'padding-box',
          },
          '&::-webkit-scrollbar-track': {
            backgroundColor: 'transparent',
            borderRadius: '10px',
          },
          '&::-webkit-scrollbar-corner': {
            backgroundColor: 'transparent', 
          }, 
    }
)

const MarkDoneButton = styled(Button)({
    color: "white",
    borderRadius: "8px",
    backgroundColor: "#527FE2",
    textTransform: "none",
    fontFamily: '"Poppins", sans-serif',
    fontWeight:700,
    fontSize: "16px",
    padding: "10px 16px 10px 16px",
    maxWidth: "306px",
    width:"306px",
    lineHeight:"24px",
    height: "44px",
    "&:hover": {
      color: "white", backgroundColor: "#527FE2",
    },
    "@media(max-width:600px)": {
      fontSize:"18px",padding: "10px 16px 10px 16px",
    },
    
  });

const SubFieldRows = styled(Box)({
    display:"flex",alignItems:"center",gap:"5px",flexWrap:"wrap"
                
  });

const SubFieldsText = styled(Typography)({
        fontFamily:'"Poppins", sans-serif',
      fontSize:"14px",
      fontWeight:700,
      paragraph:"8px",
      color:"#64748B",
    "@media (max-width:500px )": {
      fontSize:"12px",
    },
  });

  const ProfileCatTextDes = styled(Typography)({
    marginBottom:'20px !important',
    fontFamily:'"Poppins", sans-serif',
    fontWeight:400,
    fontSize:"14px",
    color:"#334155",
    display:"flex",
    alignItems:"center",
    overflowY:'auto',
    overflowX:'hidden',
    columnGap:"8px",
    "@media (max-width:500px )": {
      fontSize:"12px",
    },

    maxHeight:'70px',
    '&::-webkit-scrollbar': {
    width: '12px',
    borderRadius: '10px', 
    },
    '&::-webkit-scrollbar-thumb': {
    backgroundColor: '#527FE2',
    borderRadius: '10px', 
    border: '3px solid transparent',
    backgroundClip: 'padding-box',
    },
    '&::-webkit-scrollbar-track': {
    backgroundColor: 'transparent',
    borderRadius: '10px',
    },
    '&::-webkit-scrollbar-corner': {
    backgroundColor: 'transparent', 
    }, 
})  
  
  const ProfileCatText = styled(Typography)({
    fontFamily:'"Poppins", sans-serif',
    fontWeight:400,
    fontSize:"14px",
    color:"#334155",
    display:"flex",
    alignItems:"center",
    columnGap:"8px",
    "@media (max-width:500px )": {
      fontSize:"12px",
    },})  

    const SubFieldsTextTitle = styled(Typography)({
        fontFamily:'"Poppins", sans-serif',
        fontSize:"16px",
        fontWeight:700,
        paragraph:"8px",
        variant:"h6",
        marginBottom:"5px"
  });

class TicketDetails extends TicketDetailsController {
    constructor(props: Props) {
        super(props);
    }
    
    render() {
        return (
            <ThemeProvider theme={theme}>
                <div style={{ display: 'flex' }}>
                    {/* Sidebar Navigation */}
                    <AppNavigation />

            {/* Main Content */}
            <Box style={{
                fontFamily:'"Poppins", sans-serif', 
                flex: 1, display: 'flex', 
                flexDirection: 'column', 
                padding: '50px 20px', 
                background: '#F1F5FC' 
            }}>
            <ToastContainer />
            <DynamicBreadcrumbs breadcrumbItems={[I18n.t("home"), I18n.t("tickets")]}/>
                <Box style={{ display: 'flex', flexDirection:'column', justifyContent:'center'}}>
                    <Box style={{ display: 'flex',}}>
                
                        {/* Left Section - Ticket Details */}
                        <Paper style={{
                            width:'50%',
                            padding: '24px',
                            borderRadius: '20px 0 0 20px',
                            boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.1)',
                            borderRight:'1px solid #CBD5E1'
                        }}>
                        {/* Ticket Details Header */}
                        <Box style={{ display: 'flex', alignItems: 'center', marginBottom:'16px',textDecoration:"none", color:"inherit" }}>
                            <Box
                                style={{
                                    width: '56px',
                                    height: '56px',
                                    borderRadius: '50%',
                                    overflow: 'hidden', 
                                    marginRight: '15px',
                                    backgroundColor: '#f0f0f0',
                                }}
                            >
                                <img
                                    src={this.state.selectedTicket?.attributes?.photo?.trim() || profileimg}
                                    alt="User Profile Img"
                                    style={{
                                        width: '100%',
                                        height: '100%',
                                        objectFit: 'cover',
                                        display: 'block',
                                    }}
                                />
                            </Box>
                            <Box>
                                <SubFieldsTextTitle data-test-id="ticketName" variant="h6" style={{fontSize:"20px"}} >{this.state.selectedTicket?.attributes?.name}</SubFieldsTextTitle>
                                <SubFieldRows >
                                    <SubFieldsText > {I18n.t('id')}: </SubFieldsText>
                                    <ProfileCatText data-test-id="ticketId" >{this.state.selectedTicket?.id}</ProfileCatText>
                                </SubFieldRows>
                            </Box>
                        </Box>
                        <SubFieldsTextTitle variant="h6" style={{fontSize:"20px"}} >{this.state.selectedTicket?.attributes?.title}</SubFieldsTextTitle>
                        <SubFieldRows >
                        <SubFieldsText >{I18n.t('date')}: </SubFieldsText>
                        <ProfileCatText data-test-id="ticketDate">{this.state.selectedTicket?.attributes?.createdAt}</ProfileCatText>
                        </SubFieldRows>
                        <SubFieldRows style={{marginTop:'4px', marginBottom:'4px'}} >
                        <SubFieldsText > {I18n.t('status')}:</SubFieldsText>
                        <ProfileCatText data-test-id="ticketStatus" style={{color:"#D97706", backgroundColor:"#FEF3C7", borderRadius:"15px", padding:"1px 25px"}}>{this.state.selectedTicket?.attributes?.status}</ProfileCatText>
                        </SubFieldRows>
                        <SubFieldRows >
                        <SubFieldsText > {I18n.t('category')}: </SubFieldsText>
                        <ProfileCatText data-test-id="ticketCategory" >{this.state.selectedTicket?.attributes?.category}</ProfileCatText>
                        </SubFieldRows>

                        {/* Ticket Description */}
                        <SubFieldsTextTitle variant="h6" style={{fontSize:"20px", marginTop:"10px"}} >{I18n.t('description')}</SubFieldsTextTitle>
                        <ProfileCatTextDes data-test-id="ticketDescription" style={{marginBottom:"15px"}}>{this.state.selectedTicket?.attributes?.description}</ProfileCatTextDes>

                        {/* Attachment */}
                        {
                            <Box style={{  
                                display: 'flex',  
                                alignItems: 'center',  
                                gap: '12px',  
                                background: '#F1F5F9',  
                                borderRadius: '8px',  
                                padding: '12px 16px',  
                                border: '1px solid #CBD5E1',  
                                marginBottom: '20px'  
                            }}>
                                <img  
                                    src={pdfIcon}
                                    alt="PDF Icon"  
                                    style={{ width: '32px', height: '32px' }}  
                                />  
                                {
                                    this.state.selectedTicket?.attributes?.attachment && (
                                        <Box style={{ display: 'flex', flexDirection: 'column', fontFamily:'"Poppins", sans-serif' }}>  
                                            <Typography data-test-id="ticketAttachment">  
                                            {this.state.selectedTicket?.attributes?.attachment.split("/").pop() || ''}
                                            </Typography>  
                                            <Typography data-test-id="ticketAttachmentSize" style={{ fontSize: '12px', color: '#94A3B8' }}>  
                                            2MB  
                                            </Typography>  
                                        </Box>
                                    )
                                }
                                
                            </Box>
                        }

                        {/* Action Fields */}
                        <Box style={{ display: 'flex', flexDirection: 'column', gap: '15px' }}>
                        {/* Allocation Field */}
                        <Box style={{ position: 'relative', }}>
                        <TextField
                            data-test-id="ticketAllocatedTo"
                            variant="outlined"
                            placeholder={I18n.t('allocateUserPlaceholder')}
                            fullWidth
                            onClick={this.handleDropdownToggle}
                            value={this.state.selectedUser || ""}
                            InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                <Person style={{ color: '#DADADA' }} />
                                </InputAdornment>
                            ),
                            endAdornment: (
                                <InputAdornment position="end">
                                <FilterList style={{ cursor: 'pointer', color: '#DADADA' }} />
                                </InputAdornment>
                            ),
                            }}
                        />

                        {/* Dropdown Menu */}
                        {this.state.isAllocationDropdownOpen && (
                            <Box style={{
                            position: 'absolute',
                            top: '100%',
                            left: 0,
                            right: 0,
                            display: 'flex',
                            background: '#FFFFFF',
                            border: '1px solid #E5E7EB',
                            boxShadow: '0 4px 6px rgba(0,0,0,0.1)',
                            zIndex: 10,
                            borderRadius: '8px !important',
                            paddingLeft:'8px',
                            paddingRight:'8px',
                            marginTop: '4px',
                            }}>
                            {/* Left Column: Users */}
                            <Box style={{ flex: 1, maxHeight: '200px', overflowY: 'auto', borderRight: '1px solid #E5E7EB' }}>
                                {this.state.users.map(user => (
                                <Box
                                    data-test-id="ticketUsers"
                                    key={user.id}
                                    style={{
                                    padding: '8px 12px',
                                    cursor: 'pointer',
                                    background: this.state.selectedUserId === user.id ? '#E5E7EB' : 'transparent',
                                    }}
                                    onClick={() => this.handleUserSelect(user.id)}
                                >
                                    {user.name}
                                </Box>
                                ))}
                            </Box>

                                    {/* Right Column: Departments */}
                                    <Box style={{
                                    flex: 1,
                                    maxHeight: '200px',
                                    overflowY: 'auto',
                                    borderLeft: '1px solid #E5E7EB',
                                    padding: '8px',
                                    }}>
                                    {this.state.departments.map(department => (
                                        <Box
                                        data-test-id="ticketDepartments"
                                        key={department.id}
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            gap: '8px',
                                            padding: '8px 12px',
                                            cursor: 'pointer',
                                            background: this.state.selectedDepartment === department.id ? '#E5E7EB' : 'transparent',
                                            borderRadius: '4px'
                                        }}
                                        onClick={() => this.handleDepartmentSelect(department.id)}
                                        >
                                        {/* Checkbox */}
                                        <input
                                            data-test-id="ticketDepartmentCheckbox"
                                            type="checkbox"
                                            checked={this.state.selectedDepartment === department.id}
                                            readOnly
                                            style={{
                                            pointerEvents: 'none',
                                            accentColor: '#527FE2',
                                            }}
                                        />
                                        {/* Department Name */}
                                        <Typography data-test-id="ticketDepartmentName" style={{ fontSize: '14px', fontFamily: '"Poppins", sans-serif' }}>
                                            {department.name}
                                        </Typography>
                                        </Box>
                                    ))}
                                    </Box>

                            </Box>
                        )}
                        </Box>
                        <Select
                                data-test-id="ticketBrand"
                                style={{borderRadius:"8px", paddingLeft:'8px', paddingRight:'8px'}}
                                value={this.state.selectedBrand}
                                onChange={this.handleBrandChange}
                                displayEmpty
                                fullWidth
                                variant="outlined"
                                open={this.state.isBrandDropdownOpen}
                                onClose={() => this.setState({ isBrandDropdownOpen: false })}
                                onOpen={() => this.setState({ isBrandDropdownOpen: true })}
                                IconComponent={() => (
                                    <div style={{ cursor: "pointer" }}>
                                        <ExpandMore data-test-id="openBrandDropdown" style={{ color: "#DADADA" }} />
                                    </div>
                                )}
                                renderValue={
                                this.state.selectedBrand !== ''
                                    ? undefined
                                    : () => <span style={{ color: '#A0AEC0' }}>{I18n.t('brandPlaceHolder')}</span>
                                }
                                startAdornment={
                                <InputAdornment position="start">
                                    <Storefront style={{ color: '#DADADA' }} />
                                </InputAdornment>
                                }
                            >

                                        {/* Options */}
                                        {this.state.brands.map((brand) => (
                                            <MenuItem key={brand.id} value={brand.id}>
                                            {brand.name}
                                            </MenuItem>
                                        ))}
                                </Select>

                        <Select
                            data-test-id="ticketTime"
                            style={{borderRadius:"8px", paddingLeft:'8px', paddingRight:'8px'}}
                            value={this.state.selectedTime}
                            onChange={this.handleTimeChange}
                            displayEmpty
                            fullWidth
                            variant="outlined"
                            open={this.state.isTimeDropdownOpen}
                            onClose={() => this.setState({ isTimeDropdownOpen: false })}
                            onOpen={() => this.setState({ isTimeDropdownOpen: true })}
                            IconComponent={() => (
                                <div style={{ cursor: "pointer" }}>
                                    <ExpandMore data-test-id="openTimeDropdown" style={{ color: "#DADADA" }} />
                                </div>
                            )}
                            renderValue={
                            this.state.selectedTime !== ''
                                ? undefined
                                : () => <span style={{ color: '#A0AEC0' }}>{I18n.t('timePlaceHolder')}</span>
                            }
                            startAdornment={
                            <InputAdornment position="start">
                                <Schedule style={{ color: '#DADADA' }} />
                            </InputAdornment>
                            }
                        >
                            
                            {/* Options */}
                            <MenuItem value="3 Hrs">3 Hrs</MenuItem>
                            <MenuItem value="12 Hrs">12 Hrs</MenuItem>
                            <MenuItem value="48 Hrs">48 Hrs</MenuItem>
                            <MenuItem value="5 days">5 days</MenuItem>
                        </Select>
                        </Box>
                        
                        </Paper>

                                {/* Right Section - Comments */}
                                <Paper
                                style={{
                                    width: '50%',
                                    padding: '24px',
                                    borderRadius: '0 20px 20px 0',
                                    boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.1)',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    gap: '16px',
                                }}
                                >
                                <Typography variant="h6" style={{ 
                                    fontWeight: 700, 
                                    fontSize:'20px',
                                    lineHeight:'26px',
                                    marginBottom: '16px' 
                                    }}>
                                     {I18n.t('comments')}
                                </Typography>
                                <StyledCommentBox>
                                    {/* Map over messages in state */}
                
                                    { 
                                        !this.state.selectedTicketComments || this.state.selectedTicketComments?.length === 0 
                                            ?   (
                                                <Box
                                                    style={{
                                                        display:'flex',
                                                        flexDirection:'column',
                                                        justifyContent:'center',
                                                        alignItems:'center',
                                                        height:'100%'
                                                    }}
                                                >
                                                    <Box
                                                    style={{
                                                        display:'flex',
                                                        flexDirection:'column',
                                                        justifyContent:'center',
                                                        alignItems:'center',
                                                        gap:'10px'
                                                    }}
                                                >
                                                    <img  
                                                        src={bro_2x}
                                                        alt="placeholder image"  
                                                        style={{ width: '115px', height: '116px' }}  
                                                    />  
                                                    <Typography 
                                                        style={{
                                                            lineHeight:'22px',
                                                            fontSize:'16px'
                                                        }}
                                                    >
                                                        {I18n.t('noCommentsAdded')}
                                                    </Typography>
                                                </Box>
                                                </Box>
                                                
                                            )
                                            :   this.state.selectedTicketComments?.map((comment, index) => (
                                        <Box
                                            key={index}
                                            style={{
                                            display: 'flex',
                                            flexDirection: comment.attributes.account.role_id === '1' ? 'row-reverse' : 'row',
                                            gap: '8px',
                                            }}
                                        >
                                            <Box
                                            style={{
                                                width: '38px',
                                                height: '38px',
                                                borderRadius: '50%',
                                                overflow: 'hidden',
                                                backgroundColor: '#F1F5F9',
                                            }}
                                            >
                                            <img
                                                src={comment.attributes.account.role_id === '2' ? this.state.selectedTicket?.attributes?.photo || profileimg : this.getMerchantProfileImg() || profileimg}
                                                alt="Profile"
                                                style={{
                                                width: '100%',
                                                height: '100%',
                                                objectFit: 'cover',
                                                }}
                                            />
                                            </Box>
                                            <Box
                                                style={{
                                                    backgroundColor: comment.attributes.account.role_id === '1' ? '#E2E8F0' : '#E0E7EF',
                                                    padding: '8px 16px 16px 16px',
                                                    borderRadius: comment.attributes.account.role_id === '1' ? '8px 1px 8px 8px' : '1px 8px 8px 8px',
                                                    maxWidth: '80%',
                                                }}
                                            >
                                                <span style={{display:'flex', justifyContent:'space-between', alignItems:'center', gap:'15px'}}>
                                                    <Typography
                                                        style={{
                                                            fontSize: '20px',
                                                            fontWeight: 700,
                                                            marginBottom: '4px',
                                                            lineHeight:'26px'
                                                        }}
                                                    >
                                                    {comment.attributes.account.role_id === '1' ? this.getMerchantName() : this.state.selectedTicket?.attributes?.name}
                                                    </Typography>
                                                    
                                                    <Typography
                                                        style={{
                                                            fontSize: '14px',
                                                            color: '#94A3B8',
                                                            fontWeight: 400,
                                                            lineHeight:'20px'
                                                        }}
                                                    >
                                                    {comment.attributes.created_at}
                                                    </Typography>
                                                </span>
                                            
                                                <Typography style={{ fontSize: '14px', color: '#334155' }}>
                                                    {comment.attributes.comment}
                                                </Typography>
                                            </Box>
                                        </Box>
                                                ))}

                                </StyledCommentBox>

                                {/* Input Field for Adding Comments */}
                                <Box
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    padding: '8px 22px 8px 10px',
                                    borderRadius: '24px', 
                                    backgroundColor: '#EFF6FF', 
                                    border: '1px solid #CBD5E1',
                                    marginTop: '16px',
                                }}
                                >
                                {/* Text Input */}
                                <input
                                    type="text"
                                    placeholder={I18n.t('typeComment')}
                                    value={this.state.newComment}
                                    onChange={(e) => this.setState({ newComment: e.target.value })}
                                    style={{
                                    flex: 1, 
                                    border: 'none',
                                    outline: 'none',
                                    padding: '2px 12px',
                                    fontFamily: '"Poppins", sans-serif',
                                    fontSize: '14px',
                                    color: '#64748B',
                                    backgroundColor: 'transparent', 
                                    }}
                                />

                                {/* Attachment Icon */}
                                <AttachFile
                                    style={{
                                    color: '#64748B',
                                    marginRight: '8px',
                                    cursor: 'pointer',
                                    }}
                                />

                                {/* Mic Icon */}
                                <Mic
                                    style={{
                                    color: '#64748B',
                                    marginRight: '8px',
                                    cursor: 'pointer',
                                    }}
                                />

                                {/* Send Button */}
                                <Button
                                    data-test-id="sendCommentButton"
                                    style={{
                                    backgroundColor: '#527FE2',
                                    color: 'white',
                                    borderRadius: '50%',
                                    width: '40px',
                                    height: '40px',
                                    minWidth: '40px',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    padding: '0',
                                    }}
                                    onClick={this.handleSendComment}
                                >
                                     <img
                                        src={send_icon}
                                        alt="Send"
                                        style={{
                                            width: '32px', 
                                            height: '32px', 
                                        }}
                                    />
                                </Button>
                                </Box>

                                </Paper>

                                
                            </Box>
                            {/* Mark as Done Button */}
                            <Box data-test-id="ticketDoneBtn" 
                                style={{display:"flex",justifyContent:"center",margin:"3rem"}}> 
                                <MarkDoneButton>Mark Ticket As done</MarkDoneButton>
                                
                            </Box>

                        </Box>
                    </Box>
                </div>
            </ThemeProvider>  
        
        );
    }
}

export default TicketDetails;