import React from "react";

// Customizable Area Start
import {
  Box,
  Button,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
  createTheme,
  styled,
} from "@material-ui/core";
import { passImg, background, backButton, helpTitle } from "./assets";
import PhoneInput from "react-phone-number-input";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import LanguageIcon from "@material-ui/icons/Language";
import { I18n } from "../../../components/src/languageJson/i18n";


const BoxWidth647 = styled(Box)({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  backgroundColor: "#FFFFFF",
  maxWidth: "100%",
  width: "647px",
  "@media (min-width:1000px) and (max-width:1280px)": {
    width: "580px"
  },
  "@media (min-width:1280px) and (max-width:1400px)": {
    width: "550px"
  },
  "@media (min-width:600px) and (max-width:720px)": {
    width: "410px"
  },
})
const BoxWidth = styled(Box)({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  marginBottom: "60px",
  "@media (min-width:600px) and (max-width:720px)": {
    width: "410px"
  },
  "@media (min-width:200px) and (max-width:410px)": {
    width: "260px"
  },
})
const BoxWidth100 = styled(Box)({
  width: "100%",
  "@media (min-width:410px) and (max-width:600px)": {
    width: "80%"
  },
  "@media (min-width:200px) and (max-width:410px)": {
    width: "100%"
  }
})

const GridBoxFir = styled(Box)({
  height: "100%",
  maxWidth: "100%",
  display: "flex",
  justifyContent: "flex-end",
  flexDirection: "column",
  marginTop: "100px",
  "@media (max-width:1000px) and (min-width:1280px)": {
    marginTop: "200px",
  },
  "@media (max-width:1280px) and (min-width:1400px)": {
    marginTop: "200px",
  },
  "@media (max-width:310px) and (min-width:610px)": {
    marginTop: "100px",
  },
});

const ErrorText = styled(Typography)({
  marginTop: "2px",
  fontSize: "14px",
  color: "rgb(249, 75, 75)",
  fontFamily: 'Poppins, sans-serif',
  fontWeight: 400,
  lineHeight: "1.5",
  letterSpacing: "0.00938em",
});

// Customizable Area End

import EmailAccountLoginController, {
  Props,
} from "./EmailAccountLoginController";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});

export default class EmailAccountLoginBlock extends EmailAccountLoginController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
}
  // Customizable Area Start
  InputField = styled(TextField)(({ currentLanguage, error }: any) => ({
    "&:has(+ p)": {
      marginBottom:"4px"
    },
    marginBottom: "24px",
    "& input::placeholder": {
      color: "#94A3B8",
      opacity: 1,
    },
    "& .MuiInputBase-input[type='date']": {
      lineHeight: "19.2px",
      textTransform: "uppercase",
      color: "#334155",
    },
    "& .MuiFormHelperText-root.Mui-error": {
      color: "#DC2626",
      fontSize: "12px",
      direction: this.getValueBasedOnLanguage("rtl", "ltr"),
      fontFamily: this.getValueBasedOnLanguage("Noto Sans Arabic", "Poppins"),
      fontWeight: 400,
      lineHeight: "18px",
    },
    "& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline": {
      borderColor: "#F87171",
    },

    "& .MuiInputBase-root": {
      color: "#334155",
      textAlign: this.getValueBasedOnLanguage("right", "left"),
      direction: this.getValueBasedOnLanguage("rtl", "ltr")
    },
    "& .MuiOutlinedInput-root.Mui-focused.MuiOutlinedInput-notchedOutline": {
      borderColor: "#CBD5E1",
      borderWidth: "1px",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "#CBD5E1",
      borderWidth: "1px",
      borderRadius: "8px",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: this.getBorderColorTwo(!!this.state.errorMessagePassword, "default"),
        borderWidth: "1px",
        borderRadius: "8px",
      },

      "&:hover fieldset": {
        borderColor: this.getBorderColorTwo(!!this.state.errorMessagePassword, "hover"),
      },

      "&.Mui-focused fieldset": {
        borderColor: this.getBorderColorTwo(!!this.state.errorMessagePassword, "focus"),
        borderWidth: "1px",
      },
    },

    "& .MuiOutlinedInput-input": {
      padding: "20px 8px",
    },

    "& .MuiFormHelperText-contained": {
      marginLeft: "0px",
      marginRight: "0px",
    },
    "& .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #CBD5E1",
    },
    "@media (max-width:990px )": {
      minWidth: "unset",
      maxWidth: "unset",
    },
  }));

  ImageGrid = styled(Grid)({
    "@media (max-width:899px )": {
      display: "none",
    },
  });

  LeftGrid = styled(Grid)({
    '@media (max-width:1020px)': {
      display: 'none' as 'none',
    },
  });

  GridContainer = styled(Grid)({
    width: "80vw",
    height: "100vh",
    '@media (max-width:1020px)': {
      justifyContent: "center",
    },

  })

  styles: { [key: string]: React.CSSProperties } = {
    subHeading: {
      display: "flex",
      justifyContent: "center",
      transition: "margin-bottom 0.3s ease",
      fontFamily: this.getValueBasedOnLanguage("Noto Sans Arabic", "Poppins"),
      direction: this.getValueBasedOnLanguage("rtl", "ltr"),
      fontSize: "20px ",
      fontWeight: 500,
      lineHeight: "24px ",

      marginBottom: "64px",
    },
    passwordHeading: {
      fontFamily: this.getValueBasedOnLanguage("Noto Sans Arabic", "Poppins"),
      direction: this.getValueBasedOnLanguage("rtl", "ltr"),
      fontSize: "14px ",
      fontWeight: 400,
      lineHeight: "24px ",
      marginTop: "-20px",
      cursor: "pointer",
      marginBottom: "4px",
    },
    passwordHeadingItems: {
      fontFamily: this.getValueBasedOnLanguage("Noto Sans Arabic", "Poppins"),
      direction: this.getValueBasedOnLanguage("rtl", "ltr"),
      fontSize: "12px ",
      fontWeight: 400,
      lineHeight: "24px ",
      cursor: "pointer",
      display: "flex",
      alignItems: "center",
      gap: "6px",
    },
    termsHeading: {
      fontFamily: this.getValueBasedOnLanguage("Noto Sans Arabic", "Poppins"),
      direction: this.getValueBasedOnLanguage("rtl", "ltr"),
      fontSize: "16px ",
      fontWeight: 400,
      lineHeight: "24px ",
      cursor: "pointer",
      display: "flex",
      alignItems: "center",
      gap: "6px",
    },
    termsHeadingBold: {
      fontFamily: this.getValueBasedOnLanguage("Noto Sans Arabic", "Poppins"),
      direction: this.getValueBasedOnLanguage("rtl", "ltr"),
      fontSize: "16px ",
      fontWeight: 600,
      lineHeight: "24px",
      textDecoration: "underline",
    },
    buttonStyle: {
      width: "100%",
      background: "#527FE2",
      height: "61px",
      padding: "16px",
      gap: "8px",
      borderRadius: "8px",
      color: "white",
      marginTop: "30px",
      fontFamily: this.getValueBasedOnLanguage("Noto Sans Arabic", "Poppins"),
      fontSize: "20px ",
      fontWeight: 600,
      lineHeight: "24px",
      cursor: "pointer",
      textTransform: "capitalize",
      direction: this.getValueBasedOnLanguage("rtl", "ltr"),
    },
    forgotBox: {
      display: "flex",
      justifyContent: this.getValueBasedOnLanguage("start", "end")
    },
    forgotHeading: {
      fontSize: "16px ",
      color: '#475569',
      cursor: 'pointer',
      marginTop: '-20px',
      display: 'flex',
      justifyContent: this.getValueBasedOnLanguage("end", "start"),
      fontFamily: this.getValueBasedOnLanguage("Noto Sans Arabic", "Poppins"),
      direction: this.getValueBasedOnLanguage("rtl", "ltr"),
    },
    accountStyleBold: {
      fontFamily: this.getValueBasedOnLanguage("Noto Sans Arabic", "Poppins"),
      direction: this.getValueBasedOnLanguage("rtl", "lrt"),
      fontSize: "16px ",
      cursor: "pointer",
      lineHeight: "24px",
      color: "#527FE2",
      marginLeft: "8px"
    },
    languageSwitchTextStyle: {
      margin: "0 5px",
      fontFamily: this.getValueBasedOnLanguage("Noto Sans Arabic", "Poppins"),
      fontSize: "16px ",
      textAlign: this.getValueBasedOnLanguage("right", "left"),
      color: "#555",
      cursor: "pointer",
      fontWeight: 400,
      direction: this.getValueBasedOnLanguage("rtl", "ltr"),
    },
    inActiveButton: {
      fontWeight: 400,
      margin: "0 7px",
      borderRadius: "4px",
      border: "1px solid #E0E7FF",
      padding: "6px 12px",
      fontFamily: this.getValueBasedOnLanguage("Noto Sans Arabic", "Poppins"),
      fontSize: "14px",
      textTransform: "none",
      color: "#000",
      backgroundColor: "#fff",
      cursor: "pointer",
      textAlign: this.getValueBasedOnLanguage("right", "left"),
      direction: this.getValueBasedOnLanguage("rtl", "ltr"),
    },
    activeButton: {
      fontWeight: 400,
      display: "flex",
      alignItems: "center",
      borderRadius: "4px",
      border: "1px solid #E0E7FF",
      padding: "6px 12px",
      margin: "0 10px",
      fontFamily: this.getValueBasedOnLanguage("Noto Sans Arabic", "Poppins"),
      fontSize: "14px",
      textTransform: "none",
      color: "#527FE2",
      backgroundColor: "#E0E7FF",
      cursor: "pointer",
      textAlign: this.getValueBasedOnLanguage("right", "left"),
      direction: this.getValueBasedOnLanguage("rtl", "ltr"),
    },
    container: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      margin: "10px 0",
      marginTop: "12px",
      flexDirection: this.getValueBasedOnLanguage("row-reverse", "row")
    },
    errorMsgBox: {
      borderLeft: this.getValueBasedOnLanguage("", "4px solid red"),
      borderRight: this.getValueBasedOnLanguage("4px solid red", ""),
      padding: "12px",
      backgroundColor: "#FEE2E2",
      marginBottom: "24px",
    },
    errormsg: {
      direction: this.getValueBasedOnLanguage("rtl", "ltr"),
      fontFamily: this.getValueBasedOnLanguage("Noto Sans Arabic", "Poppins"),
      fontSize: "14px",
      fontWeight: 400,
      lineHeight: "18px",
      color: "#DC2626",
    }
  };

  WelBackTypo = styled(Typography)({
    fontFamily: this.getValueBasedOnLanguage("Noto Sans Arabic", "Poppins"),
    fontSize: "56px ",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontWeight: 600,
    lineHeight: "56px ",
    cursor: "pointer",
    marginTop: "24px",
    whiteSpace: "nowrap",
    direction: this.getValueBasedOnLanguage("rtl", "ltr"),
    "@media(max-width: 360px)": {
      fontSize: "30px ",
    },
    "@media(max-width: 410px)": {
      fontSize: "34px ",
    },
    "@media(max-width: 610px)": {
      fontSize: "36px ",
    },
    "@media(max-width: 710px)": {
      fontSize: "34px ",
    },
  });


  NoAccTypo = styled(Typography)({
    fontFamily: this.getValueBasedOnLanguage("Noto Sans Arabic", "Poppins"),
    direction: this.getValueBasedOnLanguage("rtl", "ltr"),
    fontSize: "14px ",
    lineHeight: "22px",
    fontWeight: 400,
    color: "#000",
    "@media(max-width:720px)": {
      fontSize: "14px"
    },
  })

  WelBackSubTypo = styled(Typography)({
    fontFamily: this.getValueBasedOnLanguage("Noto Sans Arabic", "Poppins"),
    fontSize: "30px ",
    fontWeight: 700,
    lineHeight: "40px ",
    color: "#000000",
    direction: this.getValueBasedOnLanguage("rtl", "ltr"),
    "@media(max-width: 360px)": {
      fontSize: "12px ",
    },
    "@media(max-width: 410px)": {
      fontSize: "14px ",
    },
    "@media(max-width: 610px)": {
      fontSize: "14px ",
    },
    "@media(max-width: 710px)": {
      fontSize: "24px ",
    },

  });

  webStyle = {
    boxTitle: {
      display: "flex", justifyContent: "center", height: "100%"
    },
    subBox: {
      display: "flex",
      flexDirection: "column" as "column", gap: "64px",
      width: this.getValueBasedOnLanguage("90%", "100%"),
      margin: "auto",
      textAlign: "center" as "center"
    },
    welcomeBox: {
      display: "flex", alignItems: "center",
      gap: "16px", flexWrap: "wrap" as "wrap",
      textAlign: this.getValueBasedOnLanguage("right", "left"),
      flexDirection: this.getValueBasedOnLanguage("row-reverse", "row"),
    },
    helpTitle: {
      fontFamily: this.getValueBasedOnLanguage("Noto Sans Arabic", "Poppins"),
      direction: this.getValueBasedOnLanguage("rtl", "ltr"),
      fontSize: "58px",
      fontWeight: 600, color: "#FFFFFF",
      whiteSpace: "nowrap",
    },
    helpSubTitle: {
      fontFamily: this.getValueBasedOnLanguage("Noto Sans Arabic", "Poppins"),
      direction: this.getValueBasedOnLanguage("rtl", "ltr"),
      fontSize: "30px",
      lineHeight: "48px", fontWeight: 400, color: "#FFFFFF", whiteSpace: "wrap",
      textAlign: "left" as "left",
    },
    imgTit: {
      height: "74px",
      width: "183px",
      textAlign: "center" as "center",
    },
    sign: {
      fontFamily: this.getValueBasedOnLanguage("Noto Sans Arabic", "Poppins"),
      direction: this.getValueBasedOnLanguage("rtl", "ltr"),
      fontSize: "30px ",
      alignItems: "center",
      fontWeight: 700,
      justifyContent: "center",
      lineHeight: "40px",
      color: "#000000",
      marginTop: "24px",
      marginBottom: "24px",
    },
  }


  PhoneStyle = styled(PhoneInput)(({ borderColor, currentLanguage }: any) => ({
    border: `1px solid ${borderColor || "#CBD5E1"}`,
    borderRadius: 8,
    height: 59,
    marginBottom: "24px",
    zIndex: 1,
    position: "relative",
    display: "flex",
    alignItems: "center",
    paddingLeft: this.getValueBasedOnLanguage("0", "10px"),
    "&:hover": {
    borderColor: "#527FE2", 
    },
    "&:focus-within": {
      borderColor: "#527FE2", 
    },
    "&:has(+ p)": {
      marginBottom:"4px",
    },
    "& input": {
      border: "none",
    },
    "& input:focus": {
      border: "none",
      outline: "none",
    },
    "& .PhoneInputCountry": {
      display: "flex",
      alignItems: "center",
      gap: "8px", 
    },
    "& .PhoneInputInput": {
      color: "#334155",
      fontFamily: this.getValueBasedOnLanguage("Noto Sans Arabic", "Poppins"),
      direction: this.getValueBasedOnLanguage("rtl", "ltr"),
      fontSize: "16px",
      fontWeight: 400,
      background: "transparent",

      lineHeight: "24px",
      textAlign: this.getValueBasedOnLanguage("right", "left")
    },
    "& input:focus-visible": {
      border: "none",
      outline: "none",
    },
    ...(currentLanguage === "ar" && {
      direction: "rtl",
    }),
  }));

  // Customizable Area End
  render() {
    return (
      // Customizable Area Start
      // Required for all blocks
      <>
        <Grid container>
          <Grid
            item
            lg={6}
            md={4}
            sm={4}
            xs={12}
            style={{ paddingBlock: "30px" }}
          >
          </Grid>
        </Grid>

        <Box style={{ width: "100vw", height: "100vh", overflow: "auto", display: "flex", justifyContent: "center", backgroundImage: `url(${background})`, backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: "center", position: "absolute" }}>


          <this.GridContainer container>
            <this.LeftGrid
              item
              lg={6}
              md={4}
            >
              <Box style={this.webStyle.boxTitle}>
                <Box style={this.webStyle.subBox}>
                  <Box style={this.webStyle.welcomeBox}>
                    <Typography style={{
                      ...this.webStyle.helpTitle,
                      fontSize: 'clamp(3rem, 4vw, 4.5rem)',
                      textAlign: this.getValueBasedOnLanguage("right", "left"),
                      direction: this.getValueBasedOnLanguage("rtl", "ltr"),
                    }}>
                      {I18n.t("welcometo")}
                    </Typography>
                    <img src={helpTitle} style={this.webStyle.imgTit} />
                  </Box>
                  <Typography style={{
                    ...this.webStyle.helpSubTitle,
                    fontSize: 'clamp(1.5rem, 1.8vw, 2.2rem)',
                    textAlign: this.getValueBasedOnLanguage("right", "left"),
                    direction: this.getValueBasedOnLanguage("rtl", "ltr"),
                  }}>
                    {I18n.t("loginTitle")}
                  </Typography>
                </Box>
              </Box>
            </this.LeftGrid>
            <Grid
              item
              lg={6}
              md={8}
              sm={12}
              xs={12}
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "center",
              }}
            >

              <GridBoxFir>
                <Box
                  style={{ height: "131px", backgroundColor: "#000000", borderTopLeftRadius: "45px", borderTopRightRadius: "45px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                  <Box style={{ height: "74px", width: "183px" }}>
                    <img src={helpTitle} style={{ height: "100%", width: "100%" }} />
                  </Box>

                </Box>
                <BoxWidth647>
                  <Box
                    style={{
                      width: "535px",
                    }}
                  />

                  <BoxWidth>
                    <Box style={{ display: "flex", flexDirection: "column", gap: "24px", justifyContent: "center", textAlign: "center", marginBottom: "24px", marginTop: "34px" }}>
                      <this.WelBackTypo> {I18n.t("welcomeback")} !</this.WelBackTypo>
                      <this.WelBackSubTypo> {I18n.t("login")}</this.WelBackSubTypo>
                    </Box>
                    <Box
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "100%",
                      }}
                    >
                      <BoxWidth100>
                        {this.state.accNotFoundError && (
                          <Box style={this.styles.errorMsgBox}>
                            <Typography style={this.styles.errormsg}>
                              {
                                this.state.accNotFoundError
                              }
                            </Typography>
                          </Box>
                        )}

                        <div style={{ position: "relative" }}>
                          {!this.state.phone && (
                            <label
                              style={{
                                position: "absolute",
                                top: 18,
                                left: this.state.currentLanguage === "ar" ? "auto" : 100,
                                right: this.state.currentLanguage === "ar" ? 100 : "auto",
                                fontFamily: this.getValueBasedOnLanguage("Noto Sans Arabic", "Poppins"),
                                direction: this.getValueBasedOnLanguage("rtl", "ltr"),
                                fontSize: "16px",
                                fontWeight: 400,
                                lineHeight: "24px",
                                color: "#94A3B8",
                                textAlign: this.state.currentLanguage === "ar" ? "right" : "left",
                              }}
                              htmlFor=""
                            >
                              {I18n.t("phoneNumber")}
                            </label>
                          )}

                          <this.PhoneStyle
                            data-test-id="phoneTestID"
                            value={this.state.phone}
                            onChange={(e: any) => {
                              this.handlePhoneNumber(e);
                            }}
                            onBlur={() => this.validatePhoneNumberOnBlur()}
                            phone
                            defaultCountry="AE"
                            countries={[]}
                            international
                            countryCallingCodeEditable={false}
                            borderColor={
                              this.state.errorMessagePhone || this.state.accNotFoundError ? "#FCA5A5" : "#CBD5E1"
                            }
                            currentLanguage={this.state.currentLanguage}
                          />
                          {
                            this.state.errorMessagePhone && (
                              <ErrorText
                                className="error-text"
                                style={{
                                  marginBottom:"20px",
                                  textAlign: this.getValueBasedOnLanguage("right", "left"),
                                  direction: this.getValueBasedOnLanguage("rtl", "ltr")
                                }}
                                >
                                  {this.state.errorMessagePhone}
                                </ErrorText>
                              )
                          }
                        </div>
                        <Box>
                          <this.InputField
                            id="password"
                            data-test-id="passwordTestID"
                            onChange={(e: { target: { value: string } }) => this.handlePassword(e.target.value)}
                            placeholder={I18n.t('passwordPlaceholder')}
                            value={this.state.password}
                            type={this.state.enablePasswordField ? "password" : ""}
                            error={!!this.state.errorMessagePassword}
                            variant="outlined"
                            fullWidth
                            currentLanguage={this.state.currentLanguage}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <img src={passImg} alt="Password Icon" />
                                </InputAdornment>
                              ),
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={this.handleClickShowPassword}
                                    onMouseDown={this.handleMouseDownPassword}
                                    edge="end"
                                    data-test-id="show"
                                  >
                                    {this.state.enablePasswordField ? (
                                      <VisibilityOff />
                                    ) : (
                                      <Visibility />
                                    )}
                                  </IconButton>
                                </InputAdornment>
                              ),
                            }}
                          />
                          {
                            this.state.errorMessagePassword && (
                              <ErrorText
                                className="error-text"
                                style={{
                                  marginBottom:"20px",
                                  textAlign: this.getValueBasedOnLanguage("right", "left"),
                                  direction: this.getValueBasedOnLanguage("rtl", "ltr")
                                }}
                                >
                                  {this.state.errorMessagePassword}
                                </ErrorText>
                              )
                          }
                        </Box>

                        <Box style={this.styles.forgotBox}>
                          <Typography data-test-id="NavigatetoForgotPassword" onClick={this.NavigatetoForgotPassword} style={this.styles.forgotHeading}>
                            {I18n.t("forgotPassword")}
                          </Typography>
                        </Box>
                        <Button
                          variant="contained"
                          style={this.styles.buttonStyle}
                          data-test-id="nextBtnTestId"
                          onClick={this.handleLogin}
                        >
                          {I18n.t("login")}
                        </Button>
                        <Box
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            marginTop: "50px"
                          }}
                        >
                          <this.NoAccTypo>
                            {I18n.t("noAccount")}
                            <span data-test-id="signuppage" onClick={this.goToResgistrationScreen} style={this.styles.accountStyleBold}>{I18n.t("signUp")}</span>
                          </this.NoAccTypo>

                        </Box>

                        <Box style={this.styles.container}>
                          <IconButton style={this.styles.iconButton}>
                            <LanguageIcon style={this.styles.icon} />
                          </IconButton>
                          <Typography style={this.styles.languageSwitchTextStyle}>
                            {I18n.t("languageLabel")}
                          </Typography>
                          <Box
                            style={{
                              display: "flex",
                              flexDirection: this.getValueBasedOnLanguage("row-reverse", "row"),
                              textAlign: this.getValueBasedOnLanguage("right", "left"),
                              direction: this.getValueBasedOnLanguage("rtl", "ltr"),
                            }}>
                            <Button
                              data-test-id="englishSwtich"
                              style={
                                this.state.currentLanguage === "en"
                                  ? this.styles.activeButton
                                  : this.styles.inActiveButton
                              }
                              onClick={() => this.changeLanguage("en")}
                            >
                              English
                            </Button>
                            <Button
                              data-test-id="arabicSwtich"
                              style={
                                this.state.currentLanguage === "ar"
                                  ? this.styles.activeButton
                                  : this.styles.inActiveButton
                              }
                              onClick={() => this.changeLanguage("ar")}
                            >
                              عربي
                            </Button>
                          </Box>
                        </Box>

                      </BoxWidth100>
                    </Box>
                  </BoxWidth>
                </BoxWidth647>
              </GridBoxFir>
            </Grid>
          </this.GridContainer>
        </Box>

      </>
      // Customizable Area End
    );
  }
}
