import React from "react";
// Customizable Area Start
import {
  Box,
  Typography,
  createTheme,
  ThemeProvider,
  Breadcrumbs,
  Card,
  Modal,
} from "@material-ui/core";
import { styled } from "@material-ui/core/styles";
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import { I18n } from "../../../components/src/languageJson/i18n";
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { clockIcon2, datePickerIcon, modalErrorIcon, modalSuccessIcon, dropdownPersonIcon, dropdownArrow, attendeesIcon2 } from "./assets";
import { KeyboardDatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

import AppNavigation from '../../../components/src/AppNavigation.web';
// Customizable Area End

import AddAppoinmentsControllerweb, {
  Props,
} from "./AddAppoinmentControllerweb";
import { Link } from "react-router-dom";
import { MuiPickersUtilsProvider } from "@material-ui/pickers/MuiPickersUtilsProvider";
import { DatePicker } from "@material-ui/pickers/DatePicker/DatePicker";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});

export default class AddAppointments extends AddAppoinmentsControllerweb {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  useStyles = {
    ButtonStyles: {
      marginTop: '2rem',
      backgroundColor: '#527FE2',
      border: 'none',
      borderRadius: '8px',
      fontWeight: 700,
      fontSize: '12px',
      padding: '12px',
      fontFamily: "Poppins",
      color: '#ffff',
      width: '300px',

    },
    ModalStyles: {
      position: "absolute",
      left: "50%",
      transform: "translate(-50%, -50%)",
      width: 400,
      backgroundColor: '#ffff',
      top: "50%",
      border: "none",
      borderRadius: '8px',
      boxShadow: 24,
      padding: '16px 24px',
    },
    Cancelbtn: {
      marginTop: "16px",
      fontWeight: 600,
      fontFamily: 'Poppins',
      backgroundColor: '#527FE2',
      padding: '12px',
      width: '400px',
      color: '#ffff',
      border: "none",
      borderRadius: "8px"
    },
    DropdownStyle: {
      padding: "10px",
      borderBottom: "1px solid #E2E8F0",
      fontSize: "14px",
      fontFamily: "Poppins",
      cursor: "pointer",
      transition: "background-color 0.2s ease",
    },
    navLinks: {
      fontSize: "14px",
      fontWeight: 400,
      fontFamily: this.getValueBasedOnLanguage("Noto Sans Arabic", "Poppins"),
      color: "#000000",
      textDecoration: "none",
    },
  }


  CardBox = styled(Card)({
    backgroundColor: "fffffff",
    width: "140%",
    padding: '30px',
    maxWidth: '93%',
    border: "1 px solid #CBD5E1",
    borderTopRightRadius: this.state.currentLanguage === "ar" ? "16px" : "0px",
    borderBottomRightRadius: this.state.currentLanguage === "ar" ? "16px" : "0px",
    borderTopLeftRadius: this.state.currentLanguage === "ar" ? "0px" : "16px",
    borderBottomLeftRadius: this.state.currentLanguage === "ar" ? "0px" : "16px",
    margin: "60px 0px 30px 0px",
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
    "@media (max-width:560px)": {
      // overflow: "scroll",
      display: "flex"
    },
  });
  BreadcrumbsCust = styled(Breadcrumbs)({
    display: 'flex',
    flexWrap: 'nowrap',
    '& .MuiBreadcrumbs-ol': {
      display: 'flex',
      flexWrap: 'nowrap',
    },
  });

  // Customizable Area End
  render() {
    // Customizable Area Start

    return (
      <div style={{ display: 'flex', flexDirection: this.state.currentLanguage === "ar" ? 'row-reverse' : "row", height: "100vh" }}>
        <AppNavigation />
        <div style={{ flex: 1, height: '165vh', overflowY: "hidden" }}>
          <ThemeProvider theme={theme}>
            <div style={{ backgroundColor: '#F1F5FC', padding: '60px 20px 0px 20px', height: "153vh" }}>
              <Box
                style={{
                  width: "96%",
                  alignSelf: "center",
                  direction: this.state.currentLanguage === "ar" ? "rtl" : "ltr",
                }}
              >
                <this.BreadcrumbsCust
                  separator={this.getValueBasedOnLanguage(
                    this.state.currentLanguage === "ar" ? <NavigateNextIcon /> : <NavigateBeforeIcon />,
                    this.state.currentLanguage === "ar" ? <NavigateBeforeIcon /> : <NavigateNextIcon />
                  )}
                  style={{
                    color: "#000000",
                    height: "9px",
                    width: "5px",
                    whiteSpace: "nowrap",
                    textAlign: this.state.currentLanguage === "ar" ? "right" : "left",
                  }}
                >
                  <Link
                    href=""
                    to={""}
                    style={{
                      ...this.useStyles.navLinks,
                      textAlign: this.state.currentLanguage === "ar" ? "right" : "left",
                    }}
                  >
                    {I18n.t("home")}
                  </Link>
                  <Link
                    href=""
                    to={""}
                    style={{
                      ...this.useStyles.navLinks,
                      textAlign: this.state.currentLanguage === "ar" ? "right" : "left",
                    }}
                  >
                    {I18n.t("appointmentmanagementText")}
                  </Link>
                  <Link
                    href=""
                    to={""}
                    style={{
                      ...this.useStyles.navLinks,
                      fontWeight: 700,
                      color: "#3f51b5",
                      textAlign: this.state.currentLanguage === "ar" ? "right" : "left",
                    }}
                  >
                    {I18n.t("createNewAppoinmentHeadingText")}
                  </Link>
                </this.BreadcrumbsCust>
              </Box>

              <this.CardBox
              id="card-box-id"
                style={{
                  padding: "26px 28px",
                  backgroundColor: "white",
                  boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                }}
              >
                <Typography
                  noWrap
                  variant="h6"
                  style={{
                    fontWeight: 700,
                    fontFamily: "Poppins",
                    marginBottom: "20px",
                    fontSize: "15px",
                    textAlign: this.state.currentLanguage === "ar" ? "right" : "left",
                  }}
                >
                  {I18n.t("createNewAppoinmentHeadingText")}
                </Typography>
                <form>
                  <div style={{ marginBottom: "15px", position: "relative", width: "100%" }}>
                    <div
                      style={{
                        width: "98%",
                        borderRadius: "8px",
                        display: "flex",
                        border: "1px solid #CBD5E1",
                        fontSize: "14px",
                        color: "#94A3B8",
                        fontFamily: "Poppins",
                        padding: "10px 8px",
                        position: "relative",
                        backgroundColor: "#FFFFFF",
                        flexDirection:
                          this.state.currentLanguage === "ar" ? "row-reverse" : "row",
                        alignItems: "center",
                      }}
                    >
                      <span
                        style={{
                          position: "absolute",
                          [this.state.currentLanguage === "ar" ? "right" : "left"]: "10px",
                          top: "50%",
                          transform: "translateY(-50%)",
                        }}
                      >
                        <img
                          src={dropdownPersonIcon}
                          style={{
                            marginRight:
                              this.state.currentLanguage === "ar" ? "0" : "10px",
                            marginLeft: this.state.currentLanguage === "ar" ? "10px" : "0",
                          }}
                        />
                      </span>
                      <span
                        style={{
                          flex: 1,
                          textAlign: this.state.currentLanguage === "ar" ? "right" : "left",
                          fontSize: "14px",
                          color: "#94A3B8",
                          marginLeft: this.state.currentLanguage === "en" ? "30px" : "0px",
                          marginRight: this.state.currentLanguage === "ar" ? "30px" : "0px",

                        }}
                      >
                        {this.state.selectedPerson || I18n.t("CustomerName")}
                      </span>
                      <img
                        src={dropdownArrow}
                        alt="Dropdown Icon"
                        style={{
                          position: "absolute",
                          [this.state.currentLanguage === "ar" ? "left" : "right"]: "20px",
                          top: "50%",
                          transform: "translateY(-50%)",
                        }}
                        onClick={this.toggleDropdown}
                        data-test-id="customerDropdownButton"
                      />
                    </div>
                    {this.state.isDropdownVisible && (
                      <div
                        style={{
                          borderRadius: "8px",
                          position: "absolute",
                          width: "284px",
                          padding: "0",
                          top: "110%",
                          border: "1px solid #CBD5E1",
                          backgroundColor: "#FFFFFF",
                          zIndex: 10,
                          [this.state.currentLanguage === "ar" ? "left" : "right"]: "0",
                          boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                        }}
                      >

                        {["James Fisher", "Michael Johnson", "Steve King", "Joseph Thomas", "Oliver Foster"].map((person, index) => (
                          <div
                            key={index}
                            style={this.useStyles.DropdownStyle}
                            onClick={() => this.selectCustomer(person)}
                            data-test-id={`selectCustomer${index + 1}`}
                            onMouseEnter={(e) => {
                              const target = e.target as HTMLElement;
                              target.style.backgroundColor = "#DBEAFE";
                            }}
                            onMouseLeave={(e) => {
                              const target = e.target as HTMLElement;
                              target.style.backgroundColor = "transparent";
                            }}
                          >
                            {person}
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                  <div style={{ marginBottom: "15px", position: "relative", width: "100%" }}>
                    <div
                      style={{
                        alignItems: "center",
                        color: "#94A3B8",
                        border: "1px solid #CBD5E1",
                        width: "98%",
                        padding: "10px 8px",
                        borderRadius: "8px",
                        position: "relative",
                        fontFamily: "Poppins",
                        backgroundColor: "#FFFFFF",
                        fontSize: "14px",
                        display: "flex",
                        flexDirection:
                          this.state.currentLanguage === "ar" ? "row-reverse" : "row",
                      }}
                    >
                      <span
                        style={{
                          top: "50%",
                          transform: "translateY(-50%)",
                          position: "absolute",
                          [this.state.currentLanguage === "ar" ? "right" : "left"]: "10px",
                        }}
                      >
                        <img
                          style={{
                            marginLeft: this.state.currentLanguage === "ar" ? "10px" : "0",
                            marginRight:
                            this.state.currentLanguage === "ar" ? "0" : "10px",
                          }}
                          src={dropdownPersonIcon}
                        />
                      </span>
                      <span
                        style={{
                          marginLeft: this.state.currentLanguage === "en" ? "30px" : "0px",
                          color: "#94A3B8",
                          fontSize: "14px",
                          marginRight: this.state.currentLanguage === "ar" ? "30px" : "0px",
                          flex: 1,
                          textAlign: this.state.currentLanguage === "ar" ? "right" : "left",

                        }}
                      >
                        {this.state.selectedPerson || I18n.t("AddApoinmentPersontomeet")}
                      </span>
                      <img
                        alt="Dropdown Icon"
                        style={{
                          [this.state.currentLanguage === "ar" ? "left" : "right"]: "20px",
                          top: "50%",
                          position: "absolute",
                          transform: "translateY(-50%)",
                        }}
                        data-test-id="toggleDropdownButton"
                        src={dropdownArrow}
                        onClick={this.toggleDropdown}
                      />
                    </div>
                    {this.state.isDropdownVisible && (
                      <div
                      style={{
                          zIndex: 10,
                          position: "absolute",
                          width: "284px",
                          top: "110%",
                          border: "1px solid #CBD5E1",
                          [this.state.currentLanguage === "ar" ? "left" : "right"]: "0",
                          backgroundColor: "#FFFFFF",
                          borderRadius: "8px",
                          boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                          padding: "0",
                        }}
                      >

                        {["James Fisher", "Michael Johnson", "Steve King", "Joseph Thomas", "Oliver Foster"].map((person, index) => (
                          <div
                          data-test-id={`selectPerson${index + 1}`}
                            key={index}
                            style={this.useStyles.DropdownStyle}
                            onClick={() => this.selectPerson(person)}
                            onMouseLeave={(e) => {
                              const target = e.target as HTMLElement;
                              target.style.backgroundColor = "transparent";
                            }}
                            onMouseEnter={(e) => {
                              const target = e.target as HTMLElement;
                              target.style.backgroundColor = "#DBEAFE";
                            }}
                          >
                            {person}
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                  {["date", "time", "attendees"].map((field, idx) => (
                    <div style={{ marginBottom: "15px", height: field === "date" ? "55px" : "auto", paddingBottom: field === "date" ? "-10px" : "auto" }} key={idx}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: this.state.currentLanguage === "ar" ? "row-reverse" : "row",
                          alignItems: "center",
                          border: "1px solid #CBD5E1",
                          borderRadius: "8px",
                          padding: "10px 10px",
                          justifyContent: "space-between",
                          backgroundColor: "#FFFFFF",
                          fontWeight: 800,
                          fontFamily: "Poppins",
                        }}
                      >
                        {field === "date" ? (
                          <>
                            <div style={{ width: "90%" }}>
                              <img
                                src={datePickerIcon}
                                style={{ margin: "5px", padding: "0px 5px" }}
                                alt="datePickerIcon" />
                              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <DatePicker
                                  InputProps={{
                                    disableUnderline: true,
                                  }}
                                  style={{
                                    paddingTop: "2px"
                                  }}
                                  variant="inline"
                                  format="dd/MM/yyyy"
                                  id="date-picker-inline"
                                  label=""
                                  value={this.state.selectedDate}
                                  onChange={this.handleDateChange}
                                />
                              </MuiPickersUtilsProvider>
                            </div>
                            <img
                              src={datePickerIcon}
                              style={{ marginRight: "5px" }}
                              alt="Clock Icon"
                            />
                          </>
                        ) : field === "time" ? (
                          <>
                            <label
                              htmlFor={field}
                              style={{
                                marginRight: this.state.currentLanguage === "ar" ? "0" : "10px",
                                marginLeft: this.state.currentLanguage === "ar" ? "10px" : "0",
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <img
                                src={clockIcon2}
                                style={{ marginRight: "5px" }}
                                alt="Clock Icon"
                              />
                            </label>
                            <input
                              type="time"
                              id={field}
                              value={this.state.time}
                              onChange={(e) => this.handleInputChange("time", e.target.value)}
                              data-test-id="timeinput"
                              style={{
                                flex: 1,
                                textAlign: this.state.currentLanguage === "ar" ? "right" : "left",
                                fontSize: "14px",
                                color: "#000",
                                border: "none",
                                outline: "none",
                                background: "transparent",
                                appearance: "none",
                                MozAppearance: "textfield",
                                WebkitAppearance: "none",
                              }}
                              aria-label="Select appointment time"
                            />
                            <img
                              src={clockIcon2}
                              style={{ marginRight: "5px" }}
                              alt="Clock Icon"
                            />
                          </>
                        ) : (
                          <div
                            style={{
                              width: "100%",
                              display: "flex",
                              flexDirection: this.state.currentLanguage === "en" ? "row" : "row-reverse",
                            }}
                          >
                            <img src={attendeesIcon2} alt="Attendees Icon" />
                            <label
                              htmlFor="attendees"
                              style={{
                                marginRight: this.state.currentLanguage === "en" ? "10px" : "0",
                                marginLeft: this.state.currentLanguage === "en" ? "0" : "10px",
                                display: "flex",
                                alignItems: "center",
                              }}
                            />
                            <input
                              type="text"
                              id="attendees"
                              value={this.state.attendee}
                              data-test-id="attendeesInput"
                              onChange={(e) => this.setState({ attendee: e.target.value })}
                              style={{
                                flex: 1,
                                textAlign: this.state.currentLanguage === "ar" ? "right" : "left",
                                fontSize: "14px",
                                color: "#94A3B8",
                                fontFamily: "Poppins",
                                border: "none",
                                outline: "none",
                                background: "transparent",
                                width: "100%",
                              }}
                              placeholder={I18n.t("AddApoinmentAttendes")}
                              aria-label="Add attendees"
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  ))}


                </form>
              </this.CardBox>
              <Box
                style={{
                  direction: this.state.currentLanguage === "ar" ? "rtl" : "ltr",
                }}
              >

                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: this.state.currentLanguage === "ar" ? "row-reverse" : "row"
                  }}
                >
                  <div>
                    <button
                      data-test-id="modalopen"
                      onClick={this.handleOpen}
                      style={{
                        ...this.useStyles.ButtonStyles,
                      }}
                    >
                      {I18n.t("AddAppoinmentsaveBtnText")}
                    </button>

                    <Modal
                      open={this.state.openSuccessModal}
                      onClose={this.handleClose}
                      aria-labelledby="success-modal-title"
                      aria-describedby="success-modal-description"
                    >
                      <Box
                        dir={this.state.currentLanguage === "ar" ? "rtl" : "ltr"}
                        style={{
                          padding: "16px 24px",
                          background: "#fff",
                          borderRadius: "8px",
                          width: "400px",
                          textAlign: "center",
                          top: "50%",
                          position: "absolute",
                          left: "50%",
                          transform: "translate(-50%, -50%)",
                        }}
                      >
                        <img src={modalSuccessIcon} alt="Success Icon" />
                        <Typography id="success-modal-description" variant="h6" style={{ fontFamily: "Poppins", fontSize: '18px', fontWeight: 600, marginTop: '10px' }}>
                          {I18n.t("AddApoinmentSuccessmsg")}
                        </Typography>
                        <button onClick={this.handleClose} style={{
                          ...this.useStyles.Cancelbtn,
                        }} data-test-id="success-modal-close-button">
                          {I18n.t("AddAppoinmentcancelBtnText")}
                        </button>
                      </Box>
                    </Modal>

                    <Modal
                      open={this.state.openErrorModal}
                      onClose={this.handleClose}
                      aria-labelledby="error-modal-title"
                      aria-describedby="error-modal-description"
                    >
                      <Box
                        dir={this.state.currentLanguage === "ar" ? "rtl" : "ltr"}
                        style={{
                          padding: "20px",
                          background: "#fff",
                          borderRadius: "8px",
                          width: '400px',
                          margin: "auto",
                          textAlign: "center",
                          top: "50%",
                          position: "absolute",
                          left: "50%",
                          transform: "translate(-50%, -50%)",
                        }}
                      >
                        <img src={modalErrorIcon} />
                        <Typography id="error-modal-description" variant="h6" style={{ fontFamily: "Poppins", fontWeight: 600, padding: "16px", fontSize: '14px' }}>
                          {I18n.t("AddApoinmentErrormsg")}
                        </Typography>
                        <div
                          style={{
                            marginTop: "10px",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                          }}
                        >
                          <button
                            onClick={this.handleTryAgain}
                            style={{
                              backgroundColor: "#527FE2",
                              color: "#FFFFFF",
                              padding: "10px",
                              fontWeight: 600,
                              fontFamily: "Poppins",
                              width: "400px",
                              border: "none",
                              borderRadius: "8px",
                              marginBottom: "10px",
                            }}
                            data-test-id="error-modal-try-again-button"
                          >
                            {I18n.t("AddAppoinmentTryAgain")}
                          </button>
                          <button


                            onClick={this.handleClose}
                            style={{
                              borderRadius: "8px",
                              backgroundColor: "#FFFFFF",
                              color: "#527FE2",
                              padding: "10px",
                              fontWeight: 600,
                              fontFamily: "Poppins",
                              width: "400px",
                              border: '1px solid #527FE2'
                            }}
                            data-test-id="error-modal-cancel-button"
                          >
                            {I18n.t("AddAppoinmentcancelBtnText")}
                          </button>
                        </div>
                      </Box>
                    </Modal>
                  </div>
                </div>
              </Box>
            </div>
          </ThemeProvider>
        </div>
      </div>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const webStyle = {
  appointmentContainer: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    flexWrap: "wrap",
    width: "100%",
    backgroundColor: "white",
    marginTop: "20px",
  },
  tableBox: {
    borderWidth: 1,
    borderRadius: 4,
    borderColor: "#ccc",
    padding: 15,
    marginVertical: 10,
  },
};
// Customizable Area End
