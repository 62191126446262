// export const helpPlusImage = require("./images/helpls.png")
export const helpPlusImage = require("./assets/helpls.png")
export const desk = require("./assets/view_.png")
export const desk_unselected = require("./assets/desk_unselected.png")
export const notifi = require("./assets/image_notifications_none_24px.png")
export const service = require("./assets/image_overview.png")
export const service_selected = require("./assets/service_selected.png")
export const role = require("./assets/image_perm_device_information_24px.png")
export const peopl = require("./assets/image_group_add_24px.png")
export const peopl_selected = require("./assets/peopl_selected.png")
export const help = require("./assets/image_chat_bubble_outline_24px.png")
export const Appoin = require("./assets/group_uil_calender.png")
export const Appoin_selected = require("./assets/Appoin_selected.png")
export const Survey = require("./assets/group_Style=Round.png")
export const organ = require("./assets/group_ph_bank-light.png")
export const organ_selected = require("./assets/organ_selected.png")
export const inventory = require("./assets/group_inventory_2.png")
export const Back = require("./assets/back.png")
export const payment = require("./assets/payment.png")
export const logout = require("./assets/logout.png")
export const user = require("./assets/user.png")
export const userImgIcon = require("./assets/profileimg.png")
export const checked = require("./assets/checked.png")
export const unchecked = require("./assets/unchecked.png")