import React from 'react';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Typography from '@material-ui/core/Typography';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';

interface BreadcrumbProps {
  breadcrumbItems: string[]; // An array of breadcrumb items as props
}

class DynamicBreadcrumbs extends React.Component<BreadcrumbProps> {
  render() {
    const { breadcrumbItems } = this.props;

    return (
      <div style={{ paddingBottom: '50px' }}>
        <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
          {breadcrumbItems.map((item, index) => (
            <Typography
              key={index}
              style={
                index === breadcrumbItems.length - 1 // Last item is active
                  ? { fontWeight: 700, color: '#3f51b5' }
                  : { color: 'inherit' }
              }
            >
              {item}
            </Typography>
          ))}
        </Breadcrumbs>
      </div>
    );
  }
}

export default DynamicBreadcrumbs;
