import React from 'react';
import { Modal, Typography, Button } from '@material-ui/core';
import { Link } from "react-router-dom";
import {cancleRoundRed,checkedRound } from "../../blocks/customform/src/assets";

interface CustomModalProps {
    dialogOpen: boolean;
    successError: boolean;
    handleDialogClose: () => void;
    typographyText: string;
    buttonName: string;
}

class CustomModal extends React.Component<CustomModalProps> {
  render() {

    const {
        dialogOpen,
        successError,
        handleDialogClose,
        typographyText,
        buttonName,
    } = this.props;

    const stylesDialog = {
        modal: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        modalContent: {
            backgroundColor: '#FFFFFF',
            padding: '20px',
            borderRadius: '8px',
            outline: 'none',
            maxWidth: '500px',
            textAlign: 'center' as 'center',
            display:"flex",
            flexDirection:"column" as 'column',
            justifyContent:"center" as 'center',
        },
        imgCancle: {
            height:"100%",
            width:"100%",
        },
        buttonContainer: {
            marginTop: '40px',
            display: 'flex',
            flexDirection:"column" as 'column',
            gap:"10px",
        },
        buttonContainer1: {
            display: 'flex',
            flexDirection:"column" as 'column',
            gap:"30px",
            alignItems:"center" as 'center',
        },
        errorTitle: {
            fontFamily:"Poppins",
            fontSize:"20px",
            fontWeight: 700,
            color:"#1E293B",
            lineHeight:"20px",
        },
        tryAgain:{
            height:"48px",
            fontFamily:"Poppins",
            fontSize:"18px",
            fontWeight: 600,
            backgroundColor:"#527FE2",
            lineHeight:"22px",
            color:"#FFFFFF",
            borderRadius:"8px",
            textTransform: "capitalize" as 'capitalize',
        },
        cancle:{
            height:"48px",
            fontFamily:"Poppins",
            fontSize:"18px",
            fontWeight: 600,
            backgroundColor:"#527FE2",
            lineHeight:"22px",
            color:"#FFFFFF",
            borderRadius:"8px",
            border:"1px solid #527FE2",
            textTransform: "capitalize" as 'capitalize',
        },
    };

    return (
      <Modal
        open={dialogOpen}
        onClose={handleDialogClose}
        style={stylesDialog.modal}
      >
        <div style={stylesDialog.modalContent}>
          <div style={stylesDialog.buttonContainer1}>
            {successError ? (
              <>
                <div style={{ height: "63.75px", width: "63.75px" }}>
                    <img src={cancleRoundRed} alt="Cancle" style={stylesDialog.imgCancle} />
                </div>
                <Typography style={stylesDialog.errorTitle}>
                  Required fields are missing.
                </Typography>
              </>
            ) : (
              <>
                <div style={{ height: "70px", width: "70px" }}>
                    <img src={checkedRound} alt="Cancle" style={stylesDialog.imgCancle} />
                </div>
                <Typography style={stylesDialog.errorTitle}>
                  {typographyText}
                </Typography>
              </>
            )}
          </div>
          <div style={stylesDialog.buttonContainer}>
            {successError ? (
              <>
                <Button
                  variant="contained"
                  style={stylesDialog.tryAgain}
                  onClick={handleDialogClose}
                >
                  Try Again
                </Button>
                <Button
                  variant="outlined"
                  style={stylesDialog.cancle}
                  onClick={handleDialogClose}
                >
                  Cancel
                </Button>
              </>
            ) : (
              <>
                <Button
                  variant="outlined"
                  style={stylesDialog.cancle}
                  component={Link}
                  to="/"
                >
                  {buttonName}
                </Button>
              </>
            )}
          </div>
        </div>
      </Modal>
    );
  }
}

export default CustomModal;
