export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const card = require("../assets/card.svg");
export const vat = require("../assets/vat.svg");
export const home = require("../assets/home.svg");
export const email = require("../assets/email.svg");
export const attatchfile = require("../assets/attatchfile.svg");
export const taxino = require("../assets/taxino.svg");
export const authId = require("../assets/authId.svg");
export const iban = require("../assets/iban.svg");
export const categories = require("../assets/categorie.svg");
export const auth = require("../assets/auth.svg");
export const deleteimg = require("../assets/deleteimg.svg");
export const location = require("../assets/location.svg");
export const time = require("../assets/time.svg");
export const ophours = require("../assets/timer.svg");
export const addIcon = require("../assets/addIcon.svg");
export const branchCodeIcon = require("../assets/branchCode.svg");
export const unchecked = require("../assets/unchecked.png");
export const checked = require("../assets/checked.png");
export const branch = require("../assets/branch.png");
export const cancleRoundRed = require("../assets/RoundCancle.svg");
export const checkedRound = require("../assets/checkedRound.svg");
export const ProfileImg = require("../assets/image1.png");
export const topBackImg = require("../assets/TopBackImg.png");







