import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import moment, { Moment } from "moment";
import { TimeSlot } from "./types";
// Customizable Area End

export const configJSON = require("./config.js");

interface Appointment {
  colIndex: number;
  rowIndex: number;
  title: string;
  time: string;
  date: string;
  attendees: string;
  attendees1: string;
}

export interface Props {
  navigation: any;
  id: string;

  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  available_date: Moment;
  start_time: Moment | null;
  end_time: Moment | null;
  id: string | number;
  token: string;
  appointmentsList: TimeSlot[];
  showPicker: boolean;
  currentLanguage: string;
  currentDate: Date;
  activeIcon: 'home' | 'settings' | null;
  selectedAppointment: Appointment | null;
  selectedAppointmentPosition: { top: string; left: string } | null;
  openDeleteModal: boolean;
  currentData:any
  // Customizable Area End
}

export interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class AppointmentsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start

  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start

      // Customizable Area End
    ];

    let endTime = new Date();
    endTime.setMinutes(endTime.getMinutes() + 30);

    this.state = {
      // Customizable Area Start
      id: 0,
      start_time: moment(new Date()),
      end_time: moment(endTime),
      available_date: moment(new Date()),
      appointmentsList: [],
      token: "",
      showPicker: false,
      currentLanguage: localStorage.getItem("GET_SELECTED_LANGUAGE") || 'en',
      currentDate: new Date(),
      activeIcon: 'home',
      selectedAppointment: null,
      currentData:null,
      selectedAppointmentPosition: null,
      openDeleteModal: false
      // Customizable Area End
    };

    // Customizable Area Start
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    await super.componentDidMount();
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  getValueBasedOnLanguage = (firstValue: any, secondValue: any) => {
    return this.state.currentLanguage === "ar" ? firstValue : secondValue;
  };

  getWeekDates(date: Date): Date[] {
    const startOfWeek = date.getDate() - date.getDay();
    const dates: Date[] = [];
    for (let i = -1; i <= 3; i++) {
      const nextDate = new Date(date);
      nextDate.setDate(startOfWeek + i);
      dates.push(nextDate);
    }
    return dates;
  }
  /*istanbul ignore next*/
  handleOpenDeleteModal = () => {
    this.setState({ openDeleteModal: true });
  };
  handleDelete = () => {

  }
  handleCloseDeleteModal = () => {
    this.setState({ openDeleteModal: false });
  };

  handlePrev = () => {
    const prevDate = new Date(this.state.currentDate);
    prevDate.setDate(this.state.currentDate.getDate() - 5);
    this.setState({ currentDate: prevDate });
  };

  handleNext = () => {
    const nextDate = new Date(this.state.currentDate);
    nextDate.setDate(this.state.currentDate.getDate() + 5);
    this.setState({ currentDate: nextDate });
  };

  generateTimes() {
    const start = 10;
    const end = 20;
    const times = [];

    for (let hour = start; hour <= end; hour++) {
      const formattedTime = `${hour % 12 === 0 ? 12 : hour % 12} ${hour < 12 ? 'AM' : 'PM'}`;
      times.push(formattedTime);
    }
    return times;
  }

  handleIconClick = (icon: 'home' | 'settings') => {
    this.setState({ activeIcon: icon });
  };

  closeAppointmentModal = () => {
    this.setState({ selectedAppointment: null });
  };
  navigateTo = (route: string) => {
    window.location.href = route;
  }
  setSelectedAppointment = (appointment: Appointment | null, e?: React.MouseEvent) => {
    let selectedAppointmentPosition = { top: '50%', left: '50%' }; 

    /* istanbul ignore next */
    if (appointment) {
      switch (true) {
        case appointment.colIndex === 0 && appointment.rowIndex === 0:
          selectedAppointmentPosition = { top: '53%', left: '50%' };
          break;
        case appointment.colIndex === 2 && appointment.rowIndex === 3:
          selectedAppointmentPosition = { top: '45rem', left: '75%' };
          break;
        case appointment.colIndex === 0 && appointment.rowIndex === 2:
          selectedAppointmentPosition = { top: '35rem', left: '50%' };
          break;
        default:
          selectedAppointmentPosition = { top: '50%', left: '50%' };
      }
    }

    this.setState({
      selectedAppointment: appointment,
      selectedAppointmentPosition,
    });
  };


  // Customizable Area End
}
