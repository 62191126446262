import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData } from ".../../../packages/framework/src/Utilities";
import moment from "moment";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  arrayHolder: any;
  token: string;
  // Customizable Area Start
  serchInput:string;
  isLoading: boolean;
  filterModalOpen: boolean;
  selectedDate: any;
  selectedUpdateDate: any;
  startFlag: boolean;
  updateFlag: boolean;
  noServiceMsg: string;
  successDialog: boolean;
  deleteId: number;
  successUpdateDialog: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class CatalogueController extends BlockComponent<Props, S, SS> {
    // Customizable Area Start
  deleteServiceApiCallId:string="";
  searchServiceApiCallId:string="";
  getProductApiCallId: any;
  timeout: any;
    // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
        // Customizable Area Start
    
      arrayHolder: [],
      token: "",
      serchInput:"",
      isLoading: true,
      filterModalOpen: false,
      selectedDate: new Date(),
      selectedUpdateDate: new Date(),
      startFlag: false,
      updateFlag: false,
      noServiceMsg: "",
      successDialog: false,
      deleteId: 0,
      successUpdateDialog: false
        // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start
    // Customizable Area End
  }

  async componentDidMount() {
      // Customizable Area Start
    super.componentDidMount();
    let tokenvalue = await getStorageData('token')
    this.getListRequest();
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener("willFocus", () => {
      });
    }
  // Customizable Area End

  }

  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };
   // Customizable Area Start

  goToAddService=()=>{
    const msg = new Message(getName(MessageEnum.NavigationMessage));

    msg.addData(
      getName(MessageEnum.NavigationTargetMessage),
      'AddService'
    );

    msg.addData(
      getName(MessageEnum.NavigationPropsMessage), this.props)
      this.send(msg);

   
  }

  toDateFromMoment(value: any) {
    if (!value) return null;

    return moment(value).format('DD/MM/YYYY');
  }
  getListRequest = async () => {

    let tokenvalue = await getStorageData('token');
    const queryParam = this.state.serchInput ? `query=${this.state.serchInput}` : '';
    console.log(this.state.serchInput,"this.state.serchInput")
    const startDate = this.state.startFlag &&`created_at=${this.toDateFromMoment(this.state.selectedDate)}`;
    const updateDate = this.state.updateFlag && `updated_at=${this.toDateFromMoment(this.state.selectedUpdateDate)}`;

    const header = {
      "Content-Type": configJSON.productApiContentType,
      token: tokenvalue,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getProductApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.productAPiEndPoint}?${startDate}&${updateDate}&${queryParam}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  DeleteService = async (id: number) => {
    let tokenvalue = await getStorageData('token')
    const header = {
      "Content-Type": configJSON.productApiContentType,
      token: tokenvalue,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.deleteServiceApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_catalogue/catalogues/${id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeDelete
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleSearchInput=(event:any)=>{

    if (this.timeout) clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      this.setState({ serchInput:event.target.value }, () => {
        this.getListRequest();
      });
    }, 500);
    this.setState({ serchInput: event.target.value });
console.log(event.target.value,"event.target.value");

  }

  async receive(from: string, message: Message) {
    // Customizable Area Start

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      
      this.getServiceResp(apiRequestCallId,responseJson,errorReponse)
      this.deleteServiceResp(apiRequestCallId,responseJson) 
    }
    // Customizable Area End
  }

  getServiceResp = (apiRequestCallId:any,responseJson:any,errorReponse:any) => {
    if (apiRequestCallId === this.getProductApiCallId) {
      if (responseJson && !responseJson.errors && responseJson.data) {
        this.setState({ arrayHolder: responseJson.data, isLoading: false, filterModalOpen:false });
        runEngine.debugLog("arrayHolder", this.state.arrayHolder);
      }
      else {
        this.setState({ arrayHolder: [], noServiceMsg: responseJson.message && responseJson.message.en ? responseJson.message.en :responseJson.message , isLoading: false})
        this.parseApiCatchErrorResponse(errorReponse);
      }
    }
  }

  deleteServiceResp = (apiRequestCallId:any,responseJson:any) => {
    if (apiRequestCallId === this.deleteServiceApiCallId) {
      if (responseJson) {
        this.getListRequest();
        this.setState({ successDialog: false, successUpdateDialog: true})
      }
    }
  }


  handleClose = () => {
    this.setState({ filterModalOpen: false})
  };

  openFilter = () => {
    this.setState({ filterModalOpen: true })
  }

  handleDateChange = (date:any) => {
    this.setState({ selectedDate: date, startFlag: true });
  };

  handleUpdateDateChange = (date:any) => {
    this.setState({ selectedUpdateDate: date, updateFlag: true });
  };

  applyFilter = () => {
    this.getListRequest();
  }

  clearFilter = () => {
    this.setState({startFlag: false, updateFlag: false, selectedDate: new Date(), selectedUpdateDate: new Date()})
  }

  successDeleteOpen = (id:number) => {
    this.setState({ successDialog: true, deleteId: id})
  }

  successDelete = () => {
    this.setState({ successDialog: false, })
  }

  deleteEmployeeCall = () => {
    this.DeleteService(this.state.deleteId);
  }

  handleUpdateClose = () => {
    this.setState({ successUpdateDialog: false});
  };
  

  // Customizable Area End
}
