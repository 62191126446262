import React from "react";
import { Box, Button, Typography, IconButton } from "@material-ui/core";
import LanguageIcon from "@material-ui/icons/Language";
import { I18n } from "./languageJson/i18n";

interface LanguageSwitcherProps {
  currentLanguage: string;
  changeLanguage: (language: string) => void;
  getValueBasedOnLanguage: (firstValue: any, secondValue: any) => any;
}

const LanguageSwitcher: React.FC<LanguageSwitcherProps> = ({
  currentLanguage,
  changeLanguage,
  getValueBasedOnLanguage,
}) => {
  const styles: { [key: string]: React.CSSProperties } = {
    container: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      margin: "10px 0",
      marginTop: "75px",
      flexDirection: getValueBasedOnLanguage("row-reverse", "row"),
    },
    languageSwitchTextStyle: {
      margin: "0 5px",
      fontFamily: getValueBasedOnLanguage("Noto Sans Arabic", "Poppins"),
      fontSize: "16px",
      textAlign: getValueBasedOnLanguage("right", "left"),
      color: "#555",
      cursor: "pointer",
      fontWeight: 400,
      direction: getValueBasedOnLanguage("rtl", "ltr"),
    },
    inActiveButton: {
      fontWeight: 400,
      margin: "0 7px",
      borderRadius: "4px",
      border: "1px solid #E0E7FF",
      padding: "6px 12px",
      fontFamily: getValueBasedOnLanguage("Noto Sans Arabic", "Poppins"),
      fontSize: "14px",
      textTransform: "none" as "none",
      color: "#000",
      backgroundColor: "#fff",
      cursor: "pointer",
      textAlign: getValueBasedOnLanguage("right", "left"),
      direction: getValueBasedOnLanguage("rtl", "ltr"),
    },
    activeButton: {
      fontWeight: 400,
      display: "flex",
      alignItems: "center",
      borderRadius: "4px",
      border: "1px solid #E0E7FF",
      padding: "6px 12px",
      margin: "0 10px",
      fontFamily: getValueBasedOnLanguage("Noto Sans Arabic", "Poppins"),
      fontSize: "14px",
      textTransform: "none" as "none",
      color: "#527FE2",
      backgroundColor: "#E0E7FF",
      cursor: "pointer",
      textAlign: getValueBasedOnLanguage("right", "left"),
      direction: getValueBasedOnLanguage("rtl", "ltr"),
    },
  };

  return (
    <Box style={styles.container}>
      <IconButton>
        <LanguageIcon />
      </IconButton>
      <Typography style={styles.languageSwitchTextStyle}>
        {I18n.t("languageLabel")}
      </Typography>
      <Box
        style={{
          display: "flex",
          flexDirection: getValueBasedOnLanguage("row-reverse", "row"),
          textAlign: getValueBasedOnLanguage("right", "left"),
          direction: getValueBasedOnLanguage("rtl", "ltr"),
        }}
      >
        <Button
          data-test-id="englishSwitch"
          style={
            currentLanguage === "en" ? styles.activeButton : styles.inActiveButton
          }
          onClick={() => changeLanguage("en")}
        >
          English
        </Button>
        <Button
          data-test-id="arabicSwitch"
          style={
            currentLanguage === "ar" ? styles.activeButton : styles.inActiveButton
          }
          onClick={() => changeLanguage("ar")}
        >
          عربي
        </Button>
      </Box>
    </Box>
  );
};

export default LanguageSwitcher;
