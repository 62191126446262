import React from "react";
// Customizable Area Start
import { Box, Button, Card, InputAdornment, Table, TableBody, TableCell, TableHead, TableRow, TextField, Breadcrumbs,
  Typography,Accordion,
  AccordionSummary,
  AccordionDetails,
  Select,
  MenuItem, Dialog } from "@material-ui/core";
import { createTheme, styled, ThemeProvider } from "@material-ui/core/styles";
import { DeleteOutline, Edit, FilterList, SearchOutlined } from "@material-ui/icons";
import AppNavigation from '../../../components/src/AppNavigation.web';
import { I18n } from "../../../components/src/languageJson/i18n";
import DynamicBreadcrumbs from "../../../components/src/DynamicBreadcrumbs.web";
import { cancelIcon, checkMark, noData } from "../../accountgroups/src/assets";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

// Customizable Area End

import CatalogueController, { Props } from "./CatalogueController";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});

export default class Catalogue extends CatalogueController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  conditionalRenderObject(condition:any,trueStmt:any,falseStmt:any){
    return condition ? condition[trueStmt] : falseStmt
  }

  renderTableContent = () => {
    if (this.state.isLoading) {
      return null; // or a loading spinner/component if necessary
    }
  
    if (this.state.arrayHolder.length <= 0) {
      return (
        <TableRow>
          <TableCell colSpan={5}>
            <NoData className="testingClass">
              <img src={noData} alt="no_data" />
              <Typography className="noEmpText">
              {this.state.noServiceMsg}
              </Typography>
            </NoData>
          </TableCell>
        </TableRow>
      );
    }
  
    return this.state.arrayHolder.map((row: any, index: any) => (
      <TableRow style={useStyles.tableRow} key={index}>
        <TableCellBody style={{ width: "16.6%" }} data-test-id={`name-${row.id}`}>
          {row.attributes.name}
        </TableCellBody>
        <TableCellBody style={{ width: "16.6%" }}>
          {row.attributes.price}$
        </TableCellBody>
        <TableCellBody style={{ width: "16.6%" }}>
          {this.toDateFromMoment(row.attributes.created_at)}
        </TableCellBody>
        <TableCellBody style={{ width: "16.6%" }}>
          {this.toDateFromMoment(row.attributes.updated_at)}
        </TableCellBody>
        <TableCellBody style={{ width: "16.6%" }} align="center">
          <Edit style={{ color: "#527FE2" }} />
          <DeleteOutline
            data-test-id={`delete-${row.id}`}
            onClick={() => this.successDeleteOpen(row.id)}
            style={{ color: "#DC2626", cursor: "pointer" }}
          />
        </TableCellBody>
      </TableRow>
    ));
  };

  // Customizable Area End

  render() {
    return (
      //Merge Engine DefaultContainer
      // Customizable Area Start
      <div style={{display:'flex'}}>
        <AppNavigation />
        <div style={{flex:1, backgroundColor:"#F1F5FC", padding:'50px 20px', height:'142vh'}}>
          <ThemeProvider theme={theme}>
          <DynamicBreadcrumbs breadcrumbItems={[I18n.t("home"), I18n.t("serviceManagement")]}/>
            <div style={{ width: "100%", display: "flex", justifyContent: "center", marginBottom:"20px" }}>
              <div style={{ display: "flex", justifyContent:"center", width: "80%", borderRadius: "0.5rem" }}>
                  <div className="btncontainer" style={{ display: "flex", alignItems: "center", justifyContent: "center", gap: "1rem", width: "60%" }}>
                    <InputField
                      type="search"
                      id="search"
                      data-test-id="searchinput"
                      onChange={this.handleSearchInput}
                      // onKeyPress={(event: React.KeyboardEvent) => {
                      //   if (event.key === 'Enter') {
                      //     this.SearchService();
                      //   }
                      // }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <SearchOutlined  style={{color:"#94A3B8"}}/>
                          </InputAdornment>
                        ),
                      
                      }}
                      placeholder="Search"
                      variant="outlined"
                      style={{ width: "100%",backgroundColor:"#FFFFFF", borderRadius:"25px 0 0 25px" }}
                    />
                  </div>
                  <Button onClick={this.openFilter} data-test-id="searchField" variant="outlined" style={{ fontSize: '18px', fontWeight: 700, textTransform: "none",backgroundColor:"#527FE2",borderRadius:"0 25px 25px 0" }}> <FilterList style={{color:"ffffff"}}/></Button>
              </div>
            </div>
            <CardBox className="addclassss" >
              <div className="table" style={{ display: "flex", justifyContent: "center", marginTop: "1rem" }}>
                  <Table style={{ tableLayout: 'fixed', width: '96%' }} >

                    <TableHead>
                      <TableRow style={useStyles.tableRow}>
                        <TableCellHead style={{ borderRadius: "0 0 0 24px" }}>Service</TableCellHead>
                        <TableCellHead >Service Price</TableCellHead>
                        <TableCellHead  >Creation Date</TableCellHead>
                        <TableCellHead  >Update Date</TableCellHead>
                        <TableCellHead  ></TableCellHead>
                      </TableRow>
                    </TableHead>
                  <TableBody>
                  {this.renderTableContent()}
                  </TableBody>
                    
                  </Table>
                
              </div>
            </CardBox>
            <Box data-test-id="goToAddService" onClick={this.goToAddService} 
            style={{display:"flex",justifyContent:"center",margin:"3rem"}}> 
            <AddServiceBtn>Add New Service</AddServiceBtn>
            </Box>
          </ThemeProvider>


          <FilterDialog className="setDynamic" onClose={this.handleClose} aria-labelledby="simple-dialog-title" open={this.state.filterModalOpen}>
            <div className="mainFilter">
              <div>
                <Typography className="filterText">Filters</Typography>
              </div>
              <div className="innerFilter">
                <div>
                  <Typography className="clearText" onClick={this.clearFilter} >Clear all</Typography>
                  {/* onClick={this.clearFilter} */}
                </div>
                <div onClick={this.handleClose}>
                  <img src={cancelIcon} alt="cancel_icon" />
                </div>
              </div>
            </div>
            <br />

            <AccordionBox className="setAccroedion">
              <Accordion key={1} style={{ marginBottom: '8px', height: 'auto', padding: '0' }}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  style={{ minHeight: '40px', padding: '0' }}
                >
                  <Typography className="accText">Creation Date</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <DatePicker
                        fullWidth
                        variant="inline"
                        inputVariant="outlined"
                        format="dd/MM/yyyy"
                        margin="normal"
                        id="date-picker-inline"
                        label=""
                        value={this.state.selectedDate}
                        onChange={this.handleDateChange}
                        KeyboardButtonProps={{
                          'aria-label': 'change date',
                        }}
                        InputAdornmentProps={{
                          position: 'end',
                        }}
                        InputProps={{
                          style: { width: '100%' },
                          // classes: {
                          //   root: 'outline_change'
                          // }
                        }}
                      />
                  </MuiPickersUtilsProvider>
                </AccordionDetails>
              </Accordion>

              <Accordion key={2} style={{ marginBottom: '8px', height: 'auto', padding: '0' }}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  style={{ minHeight: '40px', padding: '0' }}
                >
                  <Typography className="accText">Update Date</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <DatePicker
                        fullWidth
                        variant="inline"
                        inputVariant="outlined"
                        format="dd/MM/yyyy"
                        margin="normal"
                        id="date-picker-inline"
                        label=""
                        value={this.state.selectedUpdateDate}
                        onChange={this.handleUpdateDateChange}
                        KeyboardButtonProps={{
                          'aria-label': 'change date',
                        }}
                        InputAdornmentProps={{
                          position: 'end',
                        }}
                        InputProps={{
                          style: { width: '100%' },
                          // classes: {
                          //   root: 'outline_change'
                          // }
                        }}
                      />
                  </MuiPickersUtilsProvider>
                </AccordionDetails>
              </Accordion>

            </AccordionBox>

            <div className="setApplyBtn">
              <button className="applyBtn" onClick={this.applyFilter}>Apply</button>
              {/* onClick={this.applyFilter}  */}
            </div>

          </FilterDialog>
        </div>

          <DeleteEmpDialog
            open={this.state.successDialog}
            onClose={this.successDelete}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            className="adddynamic"
          >
            <div>
              <div style={{ textAlign: 'center' }}>
              <DeleteOutline style={{ color: 'red', width: '80px', height: '80px' }} />
              </div>
              <div style={{ textAlign: 'center', marginTop: '20px', }}>
                <Typography className="setEmpText">Are You sure you want to delete this service?</Typography>
                <Typography className="innerText">This action will delete all data of this and it cannot be retrieved</Typography>
              </div>
              <div className="flexBtn">
                <button className="deleteEmpButton" onClick={this.deleteEmployeeCall}>Delete</button>
                <button onClick={this.successDelete}className="cancleButton">Cancel</button>
              </div>
            </div>
          </DeleteEmpDialog>


        <SuccessDialog
          open={this.state.successUpdateDialog}
          onClose={this.handleUpdateClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          className="adddynamic"
        >
          <div>
            <div style={{ textAlign: 'center' }}>
              <img src={checkMark} alt="check_mark" />
            </div>
            <div style={{ textAlign: 'center', marginTop: '20px', }}>
              <Typography className="setEmpText">Service deleted successfully</Typography>
            </div>
            <div style={{ textAlign: 'center' }}>
              <button onClick={this.handleUpdateClose} className="viewEmpButton">Back</button>
            </div>
          </div>
        </SuccessDialog>
      </div>
      // Customizable Area End
      //Merge Engine End DefaultContainer
    );
  }
}

// Customizable Area Start

const SuccessDialog = styled(Dialog)({
  '& .MuiDialog-container':{
    '& .MuiPaper-root': {
      padding:'30px',
      boxShadow:'none'
    }
  },
  '& .setEmpText':{
    fontWeight:700,
    fontFmily: 'poppins'
  },
  '& .viewEmpButton': {
    // width: '100%',
    padding: '10px 200px',
    marginTop:'20px',
    fontSize: '1rem',
    color: '#fff',
    background: '#527FE2',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    fontWeight: 700,
    fontFamily: 'poppins'
  }
});


const DeleteEmpDialog = styled(Dialog)({
  '& .MuiDialog-container':{
    '& .MuiPaper-root': {
      padding:'30px',
      boxShadow:'none',
      borderRadius: '10px'
    }
  },
  '& .setEmpText':{
    fontWeight:700,
    fontFamily: 'poppins'
  },
  '& .innerText':{
    fontWeight:400,
    color: '#94A3B8',
    marginTop: '10px',
    fontFamily: 'poppins'
  },
  '& .flexBtn':{
    display: 'flex',
    gap: '10px',
    alignItems: 'end',
    justifyContent: 'end'
  },
  '& .deleteEmpButton':{
    padding: '10px 20px',
    marginTop:'20px',
    fontSize: '1rem',
    color: '#fff',
    background: 'red',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    fontWeight: 700,
    fontFamily: 'poppins'
  },
  '& .cancleButton': {
    // width: '100%',
    padding: '10px 20px',
    marginTop:'20px',
    fontSize: '1rem',
    color: '#fff',
    background: '#527FE2',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    fontWeight: 700,
    fontFamily: 'poppins'
  }
});



const useStyles = {
  tableRow: {
      margin: "5px 0 5px 0",
  }
}
const CardBox = styled(Card)({
   overflowY:"auto",height:"60vh",
   backgroundColor:"fffffff", border:"1 px solid #CBD5E1",
   width:"100%",margin:"auto"
   ,borderRadius:"1.5rem",
   "@media (max-width:560px)": {
   overflow:"scroll",
   display:"flex",
   marginTop:"20px"
  },
 
 })

const TableCellHead = styled(TableCell)({
 fontWeight:700,border:"none",backgroundColor:"#527FE2",color:"ffffff",
 fontFamily:"Poppins",fontSize:"1rem",padding:"20px 24px 20px 24px",height:"64px"

})

const TableCellBody = styled(TableCell)({
 border:"none","height": '44px',fontFamily:"Poppins",fontSize:"16px",padding:"20px 24px 20px 24px"
})
const InputField = styled(TextField)({
  '& .MuiInputBase-input::placeholder': {
    color: '#94A3B8', 
    opacity: 1,
  },
  "& .MuiInputBase-root": {
    height: "44px",
  },
  "& .MuiOutlinedInput-input": {
    padding: "0 8px", 
    height: "100%", 
    boxSizing: "border-box", 
  },
  "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
    borderColor: "#CBD5E1",
    borderWidth: "1px",
  },
  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: "#CBD5E1",
    borderWidth: "1px", borderRadius: "25px 0 0 25px",
  },
  "& .MuiInputBase-input[type='date']": {
    lineHeight: "19.2px",
    textTransform: "uppercase",
    color: "#334155",
  },
  "& .MuiFormHelperText-root.Mui-error": {
    color: "#DC2626",
    fontSize: "12px",fontFamily: "Poppins",
    fontWeight: 400,
    lineHeight: "18px",
  },
  "& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline": {
    borderColor: "#F87171",
  },
 
  "& .MuiOutlinedInput-root": {
    "&:hover fieldset": {
      borderColor: "#6EE7B7",
    },
    "&.Mui-focused fieldset": {
      borderWidth: "1px",
      borderColor: "#6EE7B7",
    },
  },
  "& .MuiFormHelperText-contained": {
    marginLeft: "0px",marginRight: "0px",
  },
  "& .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline": {
    border: "1px solid #CBD5E1",
  },
  "@media (max-width:990px)": {
    minWidth: "unset",
    maxWidth: "unset",
  },
});

const AddServiceBtn = styled(Button)({
  color: "white",
  borderRadius: "8px",
  backgroundColor: "#527FE2",
  textTransform: "none",
  fontFamily: "Poppins",fontWeight:700,
  fontSize: "16px",
  padding: "10px 16px 10px 16px",
  maxWidth: "306px",
  width:"306px",
  lineHeight:"24px",
  height: "44px",
  "&:hover": {
    color: "white", backgroundColor: "#527FE2",
  },
  "@media(max-width:600px)": {
    fontSize:"18px",padding: "10px 16px 10px 16px",
  },
  
});

const NoData = styled(Box)({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  '& .noEmpText':{
    fontWeight: 700,
    textAlign: 'center'
  }
});

const FilterDialog = styled(Dialog)({
  position: 'relative',
  '& .applyBtn':{
    fontWeight: 700,
    color: 'white',
    background: '#527FE2',
    border: 'none',
    borderRadius: '8px',
    padding: '10px 30px',
  },
  '& .setApplyBtn': {
    padding: '20px',
    bottom: 0,
    right: 0,
    position: 'absolute',
  },
  '& .innerFilter': {
    gap: '20px',
    justifyContent: 'center',
    display: 'flex',
    alignItems: 'center',
  },
  '& .mainFilter':{
    borderBottom: '1px solid #dddad6',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '20px',
    display: 'flex',
    },
  '& .filterText':{
    fontFamily: 'poppins',
    fontWeight: 700,
  },
  '& .clearText': {
    fontFamily: 'poppins',
    textDecoration: 'underline',
    cursor: 'pointer',
    color:'#527FE2',
    fontWeight: 400,
  },
  '& .MuiDialog-container':{
    right: '0',
    position: 'absolute',
    '& .MuiPaper-root':{
      margin: '0',
      height: '100%',
      maxHeight: '100%',
      boxShadow: 'none',
      width: '444px',
      '@media (max-width: 500px)': {
        width: '350px',
      },
    },
  }
});

const AccordionBox = styled(Box)({
  width: '100%',
  '& .accText':{
    fontWeight: 700,
    color:'#64748B',
  },
  '& .MuiPaper-root':{
    '& .MuiButtonBase-root':{
      padding: '0px 20px !important',
      '& .MuiIconButton-label':{
        color: 'black'
      }
    },
    '& .MuiCollapse-root':{
      background: '#F8FAFC',
      '& .MuiCollapse-wrapper':{
        '& .MuiCollapse-wrapperInner':{
          '& .MuiAccordionDetails-root':{
            padding: '0px 28px',
            background: '#F8FAFC',
            '& .MuiFormControl-root':{
              marginTop: '0px',
              marginBottom: '0px'
            }
          }
        }
      }
    }
  }
});

const SelectMain = styled(Select)({
  '& .MuiSelect-outlined.MuiSelect-outlined': {
    backgroundColor: 'transparent',
  },
})

const MenuItemSetChange = styled(MenuItem)({
  padding: '10px',
  '&:hover': {
    backgroundColor: '#DBEAFE',
  },
})

const DatePicker = styled(KeyboardDatePicker)({
  '& .MuiInputBase-root':{
    borderRadius: '12px',
    paddingRight: '0px'
  },
  '& .MuiSvgIcon-root':{
    color: 'gray',
  },
  '& .MuiButtonBase-root':{
    '&:hover': {
      background: 'transparent', // Change to the desired hover color
    },
  },
})

// Customizable Area End
