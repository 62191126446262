import { State } from "react-phone-number-input";
import { BlockComponent } from "../../../framework/src/BlockComponent";


// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config.js");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    // Customizable Area End
}

export interface S {
    // Customizable Area Start
    currentLanguage: string;
    open: boolean;
    date:string,
    time:string,
    isDropdownVisible: boolean;
    selectedPerson: string;
    attendee: string;
    openSuccessModal: boolean;
    openErrorModal: boolean;
    selectedDate: any,
    selectedUpdateDate: any,
    // Customizable Area End
}

export interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

export default class AddAppointmentControllerweb extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
   

    // Customizable Area End

    constructor(props: Props) {
        super(props);
        
       
            // Customizable Area Start
           
            // Customizable Area End



        this.state = {
            // Customizable Area Start
            currentLanguage: localStorage.getItem("GET_SELECTED_LANGUAGE") || 'en',
            open: false,
            date:"",
            time:"",
            isDropdownVisible: false,
            selectedPerson: "",
            attendee: "",
            openSuccessModal: false,
            openErrorModal: false,
            selectedDate: new Date(),
            selectedUpdateDate: new Date(),
            // Customizable Area End
        };

        // Customizable Area Start
        // Customizable Area End
    }

   
        // Customizable Area Start
        // Customizable Area End


   
       // Customizable Area Start
   
       // Customizable Area End
  

    // Customizable Area Start
    handleOpen = () => {
        if (!this.state.attendee.trim()) {
            this.setState({ openSuccessModal: true });
          } else {
            this.setState({ openErrorModal: true });
          }
    };
    handleInputChange = (field: "date" | "time", value: string) => {
        this.setState((prevState) => ({
          ...prevState,
          [field]: value,
        }));
      };
      
    handleClose = () => {
        this.setState({
            openSuccessModal: false,
            openErrorModal: false,
        })
    };
    handleTryAgain = () => {
        this.setState({ openErrorModal: false });
    };
    handleDateChange = (date:any) => {
        this.setState({ selectedDate: date});
      };

    toggleDropdown = (): void => {
        this.setState(prevState => ({ isDropdownVisible: !prevState.isDropdownVisible }));
    };
    selectPerson = (personName: string): void => {
        this.setState({ selectedPerson: personName, isDropdownVisible: false });
    };
    selectCustomer = (CustomerName: string): void => {
        this.setState({ selectedPerson: CustomerName, isDropdownVisible: false });
    };
    getValueBasedOnLanguage = (firstValue: any, secondValue: any) => {
        return this.state.currentLanguage === "ar" ? firstValue : secondValue;
    };
  
    // Customizable Area End
}
