import React, { Component } from "react";
import styled from "@material-ui/core/styles/styled";

// Styled components
const ScrollableContainer = styled("div")({
  position: "relative",
  width: "100%",
  height: "100%",
});

const ScrollableContent = styled("div")({
    overflowY: "scroll", // Enables vertical scrolling
    overflowX: "hidden", // Disables horizontal scrolling
    width: "100%",
    height: "100%",
    position: "relative",
    paddingRight: "10px", // Space for the scrollbar to avoid content overlap
  
    // Hide native scrollbar for Webkit browsers
    "&::-webkit-scrollbar": {
      display: "none",
    },
  
    // Hide native scrollbar for Firefox
    scrollbarWidth: "none",
  });
  
  const ScrollbarTrack = styled("div")({
    position: "absolute",
    top: 0,
    right: 0,
    width: "6px", // Custom scrollbar width
    height: "100%",
    backgroundColor: "#f1f1f1", // Track background
    borderRadius: "10px", // Rounded corners
  });
  
  const ScrollbarThumb = styled("div")({
    position: "absolute",
    width: "100%",
    backgroundColor: "#527FE2", // Thumb color
    borderRadius: "10px", // Rounded corners for the scrollbar
    cursor: "pointer",
  });
  
class CustomScrollBar extends Component<{ height?: string }> {
  containerRef = React.createRef<HTMLDivElement>();
  thumbRef = React.createRef<HTMLDivElement>();

  state = {
    isDragging: false,
    startY: 0,
    scrollTop: 0,
  };

  handleScroll = () => {
    const container = this.containerRef.current;
    const thumb = this.thumbRef.current;

    if (container && thumb) {
      const scrollHeight = container.scrollHeight; // Total scrollable height
      const clientHeight = container.clientHeight; // Visible height
      const scrollTop = container.scrollTop; // Current scroll position

      // Calculate thumb size and position
      const thumbHeight = (clientHeight / scrollHeight) * 100;
      const thumbTop = (scrollTop / scrollHeight) * 100;

      thumb.style.height = `${thumbHeight}%`;
      thumb.style.top = `${thumbTop}%`;
    }
  };

  handleMouseDown = (e: React.MouseEvent) => {
    e.preventDefault();
    this.setState({
      isDragging: true,
      startY: e.clientY,
      scrollTop: this.containerRef.current?.scrollTop || 0,
    });
  };

  handleMouseMove = (e: MouseEvent) => {
    if (!this.state.isDragging) return;

    const container = this.containerRef.current;
    const thumb = this.thumbRef.current;

    if (container && thumb) {
      const { startY, scrollTop } = this.state;
      const deltaY = e.clientY - startY;

      // Calculate the new scroll position
      const scrollHeight = container.scrollHeight;
      const clientHeight = container.clientHeight;
      const trackHeight = clientHeight;

      const scrollStep = (deltaY / trackHeight) * scrollHeight;
      container.scrollTop = scrollTop + scrollStep;
    }
  };

  handleMouseUp = () => {
    this.setState({ isDragging: false });
  };

  componentDidMount() {
    const container = this.containerRef.current;
    if (container) {
      container.addEventListener("scroll", this.handleScroll);
      this.handleScroll(); // Initialize thumb position
    }

    document.addEventListener("mousemove", this.handleMouseMove);
    document.addEventListener("mouseup", this.handleMouseUp);
  }

  componentWillUnmount() {
    const container = this.containerRef.current;
    if (container) {
      container.removeEventListener("scroll", this.handleScroll);
    }

    document.removeEventListener("mousemove", this.handleMouseMove);
    document.removeEventListener("mouseup", this.handleMouseUp);
  }


  render() {
    const { height = "100%" } = this.props; // Default to 100% height if not provided

    return (
      <ScrollableContainer style={{ height }}>
        <ScrollableContent ref={this.containerRef}>
          {this.props.children}
        </ScrollableContent>
        <ScrollbarTrack>
          <ScrollbarThumb 
            ref={this.thumbRef} 
            onMouseDown={this.handleMouseDown}
          />
        </ScrollbarTrack>
      </ScrollableContainer>
    );
  }
}

export default CustomScrollBar;
