import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData } from "../../../../packages/framework/src/Utilities";
import { toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { I18n } from "../../../components/src/languageJson/i18n";

interface IRequestDetails {
  id: string;
  type: string;
  attributes: {
    sender_id: string;
    photo:string;
    name:string;
    title: string;
    createdAt: string;
    status: string;
    category: string;
    description: string;
    attachment?: string;
  };
}

interface IRequestCommentsDetails {
  id: string;
  attributes: {
    commentable_id: string;
    comment: string;
    created_at: string; 
    updated_at: string;
    account: {
      role_id: string;
    };
  };
}

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  token: string;
  currentLanguage: string;
  allocatedTo: string;
  selectedBrand:string;
  selectedBrandId:string;
  selectedTime:string;
  selectedTicketID: string;
  selectedTicket: IRequestDetails | null;
  selectedTicketComments: IRequestCommentsDetails[] | null;
  selectedUser:string;
  selectedUserId:string;
  selectedDepartment:string;
  departments:{id:string, name:string}[];
  users:{id: string; name: string;}[];
  brands:{id:string; name:string;}[];
  isAllocationDropdownOpen: boolean;
  isBrandDropdownOpen:boolean;
  isTimeDropdownOpen:boolean;
  getTicketDetailsCallId:string;
  getDepartmentsCallId:string;
  getEmployeesCallId:string;
  getBrandsCallId:string;
  updateUserApiCalId:string;
  updateBrandApiCalId:string;
  updateTimeApiCalId:string;
  updateReadApiCalId:string;
  postCommentApiCalId:string;
  newComment: string;
  currentlyAssignedTo:string;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class TicketDetailsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  private pollingInterval: NodeJS.Timeout | null = null;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      token: "",
      currentLanguage: localStorage.getItem("GET_SELECTED_LANGUAGE") || 'en',
      allocatedTo:"",
      selectedBrand:"",
      selectedBrandId:'',
      selectedTime:"",
      selectedTicketID: "",
      selectedTicket: null,
      selectedTicketComments: null,
      selectedDepartment: 'd1',
      selectedUser: '',
      selectedUserId:'',
      departments: [],
      users: [],
      brands:[],
      isAllocationDropdownOpen:false,
      isBrandDropdownOpen:false,
      isTimeDropdownOpen:false,
      getTicketDetailsCallId:'',
      getDepartmentsCallId:'',
      getEmployeesCallId:'',
      getBrandsCallId:'',
      updateUserApiCalId:'',
      updateBrandApiCalId:'',
      updateTimeApiCalId:'',
      updateReadApiCalId:'',
      postCommentApiCalId:'',
      newComment: '', 
      //comments: [], ,
      currentlyAssignedTo:'',
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if(apiRequestCallId == this.state.getDepartmentsCallId && 
        responseJson !== undefined &&
        Array.isArray(responseJson.data)
      ) {
          const mappedDepartments = responseJson.data.map((department: any) => ({
            id: department.attributes.id.toString(), 
            name: department.attributes.name, 
          }));
    
          this.setState({ departments: mappedDepartments });
      }

      if(apiRequestCallId == this.state.getEmployeesCallId &&
        responseJson.employees.data !== undefined &&
        Array.isArray(responseJson.employees.data)
      ) {
          const employee = responseJson.employees.data.map((emp: any) => ({
            id: emp.attributes.id.toString(), 
            name: emp.attributes.name, 
          }));
    
          this.setState({ users: employee }); 
      }

      if(apiRequestCallId == this.state.getBrandsCallId &&
        responseJson.manufacturers !== undefined &&
        Array.isArray(responseJson.manufacturers)
      ) {
          const brandsList = responseJson.manufacturers.map((brand: any) => ({
            id: brand.id.toString(), 
            name: brand.name, 
          }));
    
          this.setState({ brands: brandsList });
      }

      if(apiRequestCallId === this.state.getTicketDetailsCallId &&
        responseJson?.request?.data
      ) {

        const ticketDetails = this.extractTicketDetails(responseJson.request.data);
        this.setState(ticketDetails);
      
      }

      if(apiRequestCallId === this.state.getTicketDetailsCallId &&
        responseJson !== undefined &&
        responseJson.comments.data
      ) {
        const mappedComments = 
        responseJson.comments.data.length > 0 
          ? responseJson.comments.data.map((comment: any) => ({
            id: comment.id,
            attributes: {
              commentable_id: comment.attributes.commentable_id,
              comment: comment.attributes.comment,
              created_at: this.formatDateTime(comment.attributes.created_at),
              updated_at: this.formatDateTime(comment.attributes.updated_at),
              account: {
                role_id: String(comment.attributes.account.role_id),
              },
            },
          })) 
          : [];
      
        this.setState({ selectedTicketComments: mappedComments });
      
      } else if (apiRequestCallId == this.state.postCommentApiCalId &&
        responseJson.errors){
        toast.error('Unable to add your reply at this time. Please try again later.');
      }

      if(apiRequestCallId == this.state.updateUserApiCalId) {
        console.log("user updated : ", responseJson)
      }

      if(apiRequestCallId == this.state.updateBrandApiCalId) {
        console.log("brand updated : ", responseJson)
      }

      if(apiRequestCallId == this.state.updateTimeApiCalId) {
        console.log("time updated : ", responseJson)
      }

      if(apiRequestCallId == this.state.updateReadApiCalId) {
        console.log("read updated : ", responseJson)
      }

    }
    
  }

  private extractTicketDetails(data: any) {
    const manufacturerName = data.attributes.manufacturer_name || '';
    const alreadySelectedBrand = this.state.brands.find(
      (brand) => brand.name.toUpperCase() === manufacturerName.toUpperCase()
    )?.id || '';
  
    return {
      selectedTicket: {
        id: data.id,
        type: data.type,
        attributes: {
          sender_id: data.attributes.sender_id,
          name: data.attributes.sender_full_name,
          photo: data.attributes.sender_photo_url || '',
          title: data.attributes.request_text,
          createdAt: this.formatDateTime(data.attributes.created_at),
          status: data.attributes.status.replace("_", " ").toUpperCase(),
          category: data.attributes.request_type,
          description: data.attributes.description,
          attachment: data.attributes.attachments?.[0] || '',
        },
      },
      selectedUser: data.attributes.assigned_to || '',
      selectedBrand: alreadySelectedBrand,
      selectedTime: data.attributes.time_description || '',
    };
  }
  
  
  componentDidMount = async () => {
    this.handleTicketDetails();
    this.getDepartmentList();
    this.getBrandsList();
    this.markTicketAsRead();
    this.getToken();

    // Start polling for new comments every 10 seconds
    this.pollingInterval = setInterval(this.handleTicketDetails, 5000); // Poll every 10 seconds

    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener("willFocus", () => {
        this.getToken();
      });
    }

    if (this.isPlatformWeb() === false) {
        this.props.navigation.addListener("willFocus", () => {
            this.getToken();
        });
    }
  };

  async componentWillUnmount(): Promise<void> {
    // Clear the polling interval if it exists
    if (this.pollingInterval) {
      clearInterval(this.pollingInterval);
    }

  }
  
  componentDidUpdate(prevProps: Props, prevState: S) {
    if (prevState.selectedTicketComments !== this.state.selectedTicketComments) {
      // Sort comments by created_at
      const sortedComments =
      this.state.selectedTicketComments?.sort(
        (a, b) =>
          new Date(a.attributes.created_at).getTime() -
          new Date(b.attributes.created_at).getTime()
      ) || null;
  
      // No need to merge comments or handle temporary comments
      this.setState({ selectedTicketComments: sortedComments });
    }
  }

  getToken = () => {
    const tokenMsg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(tokenMsg);
  };

  getMerchantName = () => {
    return localStorage.getItem('merchantName') || 'You';
  }

  getMerchantProfileImg = () => {
    return localStorage.getItem('merchantImg');
  }

  formatDateTime = (isoDateTime:string) => {
    const isoDate = isoDateTime;
    const date = new Date(isoDate);
    const formattedDate = `${date.getDate()} ${date.toLocaleString("en-US", { month: "short" })} ${date.getFullYear()} ${date.toLocaleTimeString("en-US", { hour: "2-digit", minute: "2-digit", hour12: true })}`;
    return formattedDate;
  }

  markTicketAsRead = async() => {
    const selectedTicketID = localStorage.getItem('selectedTicketID')
    const header = {
      token: localStorage.getItem("token"),
    }; 

    const formData:any = new FormData();
    formData.append("is_read", true);

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    const newCallId = requestMessage.messageId;
    this.setState({ updateReadApiCalId: newCallId });
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    const endPoint = `bx_block_request_management/requests/${selectedTicketID}/is_read_update`

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
     "PUT"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };


  handleSendComment = () => {
    const { newComment } = this.state;
  
    if (!newComment.trim()) {
      return; 
    }
  
    this.postMerchantComment();

  };
   

  handleTicketDetails = async () => {
    const tokenL  =  await getStorageData('token')
    const ticketID  =  await getStorageData('selectedTicketID')
    const headers = {
      "Content-Type": configJSON.requestApiContentType,
      token: tokenL,
    };

    const getAllRequestMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    const newCallId = getAllRequestMsg.messageId;

    this.setState({ getTicketDetailsCallId: newCallId });
  
    const endPoint = `bx_block_request_management/requests/${ticketID}`;

    getAllRequestMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );

    getAllRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getAllRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getReceivedRequestApiMethod
    );
    runEngine.sendMessage(getAllRequestMsg.id, getAllRequestMsg);
  };

  getDepartmentList = async () => {
    const tokenL  =  await getStorageData('token')
    const headers = {
      "Content-Type": configJSON.requestApiContentType,
      token: tokenL,
    };

    const getAllRequestMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    const newCallId = getAllRequestMsg.messageId;

    this.setState({ getDepartmentsCallId: newCallId });
  
    const endPoint = 'bx_block_fullteamvisibility/departments/department_listing';

    getAllRequestMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );

    getAllRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getAllRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getReceivedRequestApiMethod
    );
    runEngine.sendMessage(getAllRequestMsg.id, getAllRequestMsg);
  };

  getEmployeesList = async (depID:string) => {
    const tokenL  =  await getStorageData('token')
    const headers = {
      "Content-Type": configJSON.requestApiContentType,
      token: tokenL,
    };

    const getAllRequestMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    const newCallId = getAllRequestMsg.messageId;

    this.setState({ getEmployeesCallId: newCallId });
  
    const endPoint = `bx_block_fullteamvisibility/employees_in_department/${depID}`;

    getAllRequestMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );

    getAllRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getAllRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getReceivedRequestApiMethod
    );
    runEngine.sendMessage(getAllRequestMsg.id, getAllRequestMsg);
  };

  getBrandsList = async () => {
    const headers = {
      "Content-Type": configJSON.requestApiContentType,
    };

    const getAllRequestMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    const newCallId = getAllRequestMsg.messageId;

    this.setState({ getBrandsCallId: newCallId });
  
    const endPoint = 'account_block/manufacturers/names';

    getAllRequestMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );

    getAllRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getAllRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getReceivedRequestApiMethod
    );
    runEngine.sendMessage(getAllRequestMsg.id, getAllRequestMsg);
  };

  updateSelectedUser = async() => {
    const selectedTicketID = await getStorageData('selectedTicketID')
    const header = {
      token: localStorage.getItem("token"),
    }; 

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    const newCallId = requestMessage.messageId;
    this.setState({ updateUserApiCalId: newCallId });
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    const endPoint = `bx_block_request_management/requests/${selectedTicketID}/assignee?assigned_to=${this.state.selectedUserId}`

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
     "PATCH"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  updateSelectedBrand = async() => {
    const selectedTicketID = await getStorageData('selectedTicketID')
    const header = {
      token: localStorage.getItem("token"),
    }; 

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    const newCallId = requestMessage.messageId;
    this.setState({ updateBrandApiCalId: newCallId });
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    const endPoint = `bx_block_request_management/requests/${selectedTicketID}/select_manufacturer?manufacturer_id=${this.state.selectedBrandId}`

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
     "PATCH"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  updateSelectedTime = async() => {
    const selectedTicketID = await getStorageData('selectedTicketID')
    const header = {
      token: localStorage.getItem("token"),
    }; 

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    const newCallId = requestMessage.messageId;
    this.setState({ updateTimeApiCalId: newCallId });
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    const endPoint = `bx_block_request_management/requests/${selectedTicketID}/select_time?data=${this.state.selectedTime}`

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
     "PATCH"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  postMerchantComment = async() => {
    const selectedTicketID = await getStorageData('selectedTicketID')
    const header = {
      token: localStorage.getItem("token"),
    }; 

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    const newCallId = requestMessage.messageId;
    this.setState({ postCommentApiCalId: newCallId });

    if (!this.state.newComment.trim()) {
      console.error("Comment is empty and cannot be posted.");
      return false;
    }

    const newComment = {
      id: `temp-${Date.now()}`,
      attributes: {
        commentable_id: selectedTicketID,
        comment: this.state.newComment.trim(),
        created_at: new Date().toISOString(),
        updated_at: new Date().toISOString(),
        account: { role_id: "1" }, 
      },
    };

    const encodedMessage = encodeURIComponent(this.state.newComment);
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    const endPoint = `bx_block_request_management/requests/${selectedTicketID}/add_comment?comment=${encodedMessage}`

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
     "POST"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    toast.success('Your reply has been added successfully.');
    this.setState((prevState) => ({
      selectedTicketComments: [...(prevState.selectedTicketComments || []), newComment],
      newComment: "",
    }));
    return true;
  };

  handleBrandDropdownToggle = () => {
    this.setState((prevState) => ({
      isBrandDropdownOpen: !prevState.isBrandDropdownOpen,
    }));
  };

  handleTimeDropdownToggle = () => {
    this.setState((prevState) => ({
      isTimeDropdownOpen: !prevState.isTimeDropdownOpen,
    }));
  };

  handleDropdownToggle = () => {
    this.setState((prevState) => ({ isAllocationDropdownOpen: !prevState.isAllocationDropdownOpen }));
  };

  handleDepartmentSelect = (departmentId: string) => {
    if (this.state.selectedDepartment !== departmentId) {
      this.setState({
        selectedDepartment: departmentId,
        selectedUser: '', 
        selectedUserId: ''
      }, () => {
        this.getEmployeesList(departmentId);
      });
    }
  };

  handleUserSelect = (userId: string) => {
    const selectedUser = this.state.users.find(user => user.id === userId);
      if(selectedUser) {
        this.setState({
          selectedUserId: userId,
          selectedUser: selectedUser.name, 
          isAllocationDropdownOpen: false,
        }, () => {
          this.updateSelectedUser(); 
        });
      } 

    toast.success(I18n.t("taskAssigned"))
   
  };

  handleAllocationChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    this.setState({allocatedTo: event.target.value as string });
  };

  handleBrandChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    this.setState({selectedBrand:event.target.value as string, selectedBrandId: event.target.value as string, isBrandDropdownOpen: false }, () => {
      this.updateSelectedBrand();
    });
    toast.success(I18n.t("brandAssigned"))
  };
  
  handleTimeChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    this.setState({selectedTime: String(event.target.value)}, () => {
      this.updateSelectedTime();
    });
    toast.success(I18n.t("timeAssigned"))
  };

  // Customizable Area End
}
