import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { switchLanguage } from "../../../components/src/languageJson/i18n";
import { getStorageData } from "../../../../packages/framework/src/Utilities";

interface IRequest {
  id: string;
  type: string;
  attributes: {
    sender_id: number;
    status: string;
    rejection_reason: string | null;
    request_text: string;
    created_at: string;
    updated_at: string;
    reviewer_group_id: number;
    sender_full_name: string;
  };
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  token: string;
  receivedRequests: IRequest[];
  rejectText: string;
  rejectTextError: string | null;
  selectedId: string | null;
  viewRequest: IRequest | null;
  filterKey: string;
  currentLanguage: string;
  selected:any;
  selectedSubOption:any;
  ticketData:any;
  getSearch: string;
  selectedTicketID: string;
  
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class RequestManagementController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getAllReceivedRequestCallId: string = "";
  updateRequestReviewCallId: string = "";
  deleteRequestCallId: string = "";
  currentLanguage!:string;
  getTicketCallId:string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      token: "",
      receivedRequests: [],
      rejectText: "",
      rejectTextError: null,
      selectedId: null,
      viewRequest: null,
      filterKey: '',
      currentLanguage: localStorage.getItem("GET_SELECTED_LANGUAGE") || 'en',
      selected:0,
      selectedSubOption:0,
      ticketData:[],
      getSearch: "",
      selectedTicketID: "",
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      runEngine.debugLog("Message Recived", message);

      const token = message.getData(getName(MessageEnum.SessionResponseToken));
      if (token) {
        this.setState({ token: token }, () => {
          this.getAllReceivedRequest();
        });
      }
    } else if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId != null) {
        this.handleApiResponse(apiRequestCallId,responseJson);
      }
    }
  }

  handleApiResponse = (apiRequestCallId : any,responseJson: any) => {
    if (
      apiRequestCallId === this.getAllReceivedRequestCallId &&
      responseJson !== undefined &&
      responseJson.data
    ) {
      this.setState({ receivedRequests: responseJson.data });
    } else if (
      apiRequestCallId === this.updateRequestReviewCallId &&
      responseJson !== undefined &&
      responseJson.data
    ) {
      this.setState({ selectedId: null, rejectText: "" });
      this.getAllReceivedRequest();
    } else if (
      apiRequestCallId === this.deleteRequestCallId &&
      responseJson !== undefined &&
      responseJson.data
    ) {
      this.getAllReceivedRequest();
    } else if (
      apiRequestCallId === this.getTicketCallId &&
      responseJson !== undefined &&
      responseJson.data     
           
    ) {
      this.setState({
        ticketData: responseJson.data
      })
      
    } 
  }

  gotToNextPage = () => {
    const message: Message = new Message(
      getName(MessageEnum.NavigationMessage)
    );
    message.addData(getName(MessageEnum.NavigationTargetMessage), "TicketDetails");
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  }
  
  componentDidMount = async () => {
      this.handleTickets();
      this.getToken();

      if (this.isPlatformWeb() === false) {
          this.props.navigation.addListener("willFocus", () => {
              this.getToken();
          });
      }
    
  };

  getToken = () => {
    const tokenMsg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(tokenMsg);
  };

  getAllReceivedRequest = () => {
    const headers = {
      "Content-Type": configJSON.requestApiContentType,
      token: this.state.token,
    };

    const getAllRequestMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getAllReceivedRequestCallId = getAllRequestMsg.messageId;

    getAllRequestMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getReceivedRequestApiEndpoint
    );

    getAllRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getAllRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getReceivedRequestApiMethod
    );
    runEngine.sendMessage(getAllRequestMsg.id, getAllRequestMsg);
  };

  updateRequestReview = (requestReviewId: string, is_accepted: boolean) => {
    const headers = {
      "Content-Type": configJSON.requestApiContentType,
      token: this.state.token,
    };

    if (!is_accepted && this.state.rejectText.length < 1) {
      this.setState({ rejectTextError: configJSON.rejectTextFieldIsRequired });
      return;
    }

    const httpBody = {
      is_accepted,
      ...(!is_accepted && {
        rejection_reason: this.state.rejectText,
      }),
    };

    const updateRequestReviewMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.updateRequestReviewCallId = updateRequestReviewMsg.messageId;

    updateRequestReviewMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.updateRequestApiEndpointStart +
        requestReviewId +
        configJSON.updateRequestApiEndpointEnd
    );

    updateRequestReviewMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    updateRequestReviewMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.updateRequestApiMethod
    );

    updateRequestReviewMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    runEngine.sendMessage(updateRequestReviewMsg.id, updateRequestReviewMsg);
  };

  deleteRequest = (deleteRequestId: string) => {
    const headers = {
      "Content-Type": configJSON.requestApiContentType,
      token: this.state.token,
    };

    const deleteRequestMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.deleteRequestCallId = deleteRequestMsg.messageId;

    deleteRequestMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.deleteRequestApiEndpoint + deleteRequestId
    );

    deleteRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    deleteRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteRequestApiMethod
    );

    runEngine.sendMessage(deleteRequestMsg.id, deleteRequestMsg);
  };

  handleTickets = async () => {
    const tokenL  =  await getStorageData('token')
    const headers = {
      "Content-Type": configJSON.requestApiContentType,
      token: tokenL,
    };

    const getAllRequestMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getTicketCallId = getAllRequestMsg.messageId;

    getAllRequestMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_request_management/requests"
    );

    getAllRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getAllRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getReceivedRequestApiMethod
    );
    runEngine.sendMessage(getAllRequestMsg.id, getAllRequestMsg);
  };
  
  handleSearchInput = (event: any) => {
    const inputGot= event.target.value.toLowerCase();
    this.setState({getSearch : inputGot})    
  }

  handleRowClick = (ticketId: string) => {
    localStorage.setItem('selectedTicketID', ticketId);
    this.setState({
      selectedTicketID:ticketId ,
    }, () => {
      this.gotToNextPage();
      //this.handleTicketDetails(ticketId)
    });
  

  };
  

  onChangeTextRejectText = (rejectText: string) => {
    this.setState({ rejectText });
  };

  setSelectedId = (selectedId: string | null) => {
    this.setState({ selectedId, rejectText: "", rejectTextError: null });
  };

  navigateHandler = () => {
    const navigationMsg = new Message(getName(MessageEnum.NavigationMessage));
    navigationMsg.addData(getName(MessageEnum.NavigationTargetMessage), "SentRequest");

    navigationMsg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);

    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPayLoadMessage)
    );
    
    navigationMsg.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);

    this.send(navigationMsg);
  };

  setViewRequest = (viewRequest: IRequest) => {
    this.setState({viewRequest})
  }

  closeViewModal = () => {
    this.setState({viewRequest: null})
  }


  onChangeTextFilterKey = (filterKey: string) => {
    this.setState({ filterKey });
  };

  getValueBasedOnLanguage = (firstValue: any, secondValue: any) => {
    return this.state.currentLanguage === "ar" ? firstValue : secondValue;
  };

  changeLanguage = (language: string) => {
    this.setState({ currentLanguage: language });
    switchLanguage(language);
  };

  switchToEnglish = () => {
    switchLanguage("en");
  };

  switchToArabic = () => {
    switchLanguage("ar");
  };

  handleOptionSelect = (index:any) => {
    this.setState({ selected: index });
    if( index=== 0 ){
     this.handleTickets();
    }
  };

  dateFormat = (inputDate:any) => {
    const date = new Date(inputDate);

    const day: string = String(date.getDate()).padStart(2, '0');
    const monthNames: string[] = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    const month: string = monthNames[date.getMonth()];
    const year: number = date.getFullYear();
    let hours: number = date.getHours();
    const minutes: string = String(date.getMinutes()).padStart(2, '0');
    const ampm: string = hours >= 12 ? 'PM' : 'AM';

    hours = hours % 12;
    hours = hours ? hours : 12; 

    const formattedDate: string = `${day} ${month} ${year} ${String(hours).padStart(2, '0')}:${minutes} ${ampm}`;

    return formattedDate
  }

  handleSubOptionSelect = (index:any) => {
    this.setState({ selectedSubOption: index });
  };

  // Customizable Area End
}