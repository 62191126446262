import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import MergeEngineUtilities from "../../utilities/src/MergeEngineUtilities";
import { isValidPhoneNumber } from "react-phone-number-input";
import { switchLanguage,I18n } from "../../../components/src/languageJson/i18n";
import { setStorageData } from ".../../../packages/framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  password: string;
  email: string;
  enablePasswordField: boolean;
  checkedRememberMe: boolean;
  placeHolderEmail: string;
  placeHolderPassword: string;
  imgPasswordVisible: any;
  imgPasswordInVisible: any;
  labelHeader: string;
  btnTxtLogin: string;
  labelRememberMe: string;
  btnTxtSocialLogin: string;
  labelOr: string;
  phone: number;
  passwordVisible: boolean;
  errorMessagePassword: string;
  errorMessagePhone:string;
  errorMsg: string;
  currentLanguage: string;
  loginAPIError:string;
  accNotFoundError:string;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class EmailAccountLoginController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiEmailLoginCallId: string = "";
  validationApiCallId: string = "";
  LoginAPIcallId:string="";
  emailReg: RegExp;
  labelTitle: string = "";
  state: {
    currentLanguage: string;
    email: string;
    password: string;
    enablePasswordField: boolean;
    checkedRememberMe: boolean;
    placeHolderEmail: any;
    placeHolderPassword: any;
    imgPasswordVisible: any;
    imgPasswordInVisible: any;
    labelHeader: any;
    btnTxtLogin: any;
    labelRememberMe: any;
    btnTxtSocialLogin: any;
    labelOr: any;
    phone: number;
    errorMessagePassword: string;
    passwordVisible: boolean;
    errorMessagePhone: string;
    errorMsg: string;
    loginAPIError:string;
    accNotFoundError:string;
  };
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
    ];

    this.state = {
      email: "",
      password: "",
      enablePasswordField: true,
      checkedRememberMe: false,
      placeHolderEmail: configJSON.placeHolderEmail,
      placeHolderPassword: configJSON.placeHolderPassword,
      imgPasswordVisible: configJSON.imgPasswordVisible,
      imgPasswordInVisible: imgPasswordInVisible,
      labelHeader: configJSON.labelHeader,
      btnTxtLogin: configJSON.btnTxtLogin,
      labelRememberMe: configJSON.labelRememberMe,
      btnTxtSocialLogin: configJSON.btnTxtSocialLogin,
      labelOr: configJSON.labelOr,
      phone:0,
      errorMessagePassword:'',
      passwordVisible: false,
      errorMessagePhone:'',
      errorMsg: '',
      currentLanguage: localStorage.getItem("GET_SELECTED_LANGUAGE") || 'en',
      loginAPIError:'',
      accNotFoundError:''
    };

    this.emailReg = new RegExp("");
    this.labelTitle = configJSON.labelTitle;
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    this.callGetValidationApi();
    this.send(new Message(getName(MessageEnum.RequestUserCredentials)));
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  btnSocialLoginProps = {
    onPress: () => this.goToSocialLogin(),
  };

  getValueBasedOnLanguage = (firstValue: any, secondValue: any) => {
    return this.state.currentLanguage === "ar" ? firstValue : secondValue;
  };

  changeLanguage = (language: string) => {
    this.setState({ currentLanguage: language });
    switchLanguage(language);
  };

  btnEmailLogInProps = {
    onPress: () => this.doEmailLogIn(),
  };

  btnPasswordShowHideProps = {
    onPress: () => {
      this.setState({ enablePasswordField: !this.state.enablePasswordField });
      this.txtInputPasswordProps.secureTextEntry =
        !this.state.enablePasswordField;
      this.btnPasswordShowHideImageProps.source = this.txtInputPasswordProps
        .secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  // Web Event Handling
  handleClickShowPassword = () => {
    this.setState({
      enablePasswordField: !this.state.enablePasswordField,
    });
  };

  setEmail = (text: string) => {
    this.setState({
      email: text,
    });
  };

  setPassword = (text: string) => {
    this.setState({
      password: text,
    });
  };

  setRememberMe = (value: boolean) => {
    this.setState({ checkedRememberMe: value });
  };

  CustomCheckBoxProps = {
    onChangeValue: (value: boolean) => {
      this.setState({ checkedRememberMe: value });
      this.CustomCheckBoxProps.isChecked = value;
    },
    isChecked: false,
  };

  btnForgotPasswordProps = {
    onPress: () => this.goToForgotPassword(),
  };

  txtInputPasswordProps = {
    onChangeText: (text: string) => {
      this.setState({ password: text });

      //@ts-ignore
      this.txtInputPasswordProps.value = text;
    },
    secureTextEntry: true,
  };

  btnPasswordShowHideImageProps = {
    source: imgPasswordVisible,
  };

  btnRememberMeProps = {
    onPress: () => {
      this.setState({ checkedRememberMe: !this.CustomCheckBoxProps.isChecked });
      this.CustomCheckBoxProps.isChecked = !this.CustomCheckBoxProps.isChecked;
    },
  };

  txtInputEmailWebProps = {
    onChangeText: (text: string) => {
      this.setState({ email: text });

      //@ts-ignore
      this.txtInputEmailProps.value = text;
    },
  };

  txtInputEmailMobileProps = {
    ...this.txtInputEmailWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputEmailProps = this.isPlatformWeb()
    ? this.txtInputEmailWebProps
    : this.txtInputEmailMobileProps;

  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start

    if (getName(MessageEnum.ReciveUserCredentials) === message.id) {
      const userName = message.getData(getName(MessageEnum.LoginUserName));

      const password = message.getData(getName(MessageEnum.LoginPassword));

      const countryCode = message.getData(
        getName(MessageEnum.LoginCountryCode)
      );

      if (!countryCode && userName && password) {
        this.setState({
          email: userName,
          password: password,
          checkedRememberMe: true,
        });

        //@ts-ignore
        this.txtInputEmailProps.value = userName;

        //@ts-ignore
        this.txtInputPasswordProps.value = password;

        this.CustomCheckBoxProps.isChecked = true;
      }
    } else if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );


      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

     

        if (apiRequestCallId === this.apiEmailLoginCallId) {
          if (responseJson && responseJson.meta && responseJson.meta.token) {
            runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
            this.saveLoggedInUserData(responseJson);
            this.sendLoginSuccessMessage();
            this.openInfoPage();
          } else {
            //Check Error Response
            this.parseApiErrorResponse(responseJson);
            this.sendLoginFailMessage();
          }

          this.parseApiCatchErrorResponse(errorReponse);
        }


        if (apiRequestCallId === this.LoginAPIcallId) {       
            this.handleAPIresponse(responseJson)

        }
      
    }
    // Customizable Area End
  }

  sendLoginFailMessage() {
    const msg: Message = new Message(getName(MessageEnum.LoginFaliureMessage));
    this.send(msg);
  }

  sendLoginSuccessMessage() {
    const msg: Message = new Message(getName(MessageEnum.LoginSuccessMessage));

    msg.addData(getName(MessageEnum.LoginUserName), this.state.email);
    msg.addData(getName(MessageEnum.CountyCodeDataMessage), null);
    msg.addData(getName(MessageEnum.LoginPassword), this.state.password);
    msg.addData(
      getName(MessageEnum.LoginIsRememberMe),
      this.state.checkedRememberMe
    );

    this.send(msg);
  }

  saveLoggedInUserData(responseJson: any) {
    if (responseJson && responseJson.meta && responseJson.meta.token) {
      const msg: Message = new Message(getName(MessageEnum.SessionSaveMessage));

      msg.addData(
        getName(MessageEnum.SessionResponseData),
        JSON.stringify(responseJson)
      );
      msg.addData(
        getName(MessageEnum.SessionResponseToken),
        responseJson.meta.token
      );

      this.send(msg);
    }
  }

  openInfoPage() {
    // Merge Engine - Navigation - btnEmailLogIn - Start
    const msg: Message = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
    // Merge Engine - Navigation - btnEmailLogIn - End
  }

  goToForgotPassword() {
    // Merge Engine - Navigation - btnForgotPassword - Start
    const msg: Message = new Message(
      getName(MessageEnum.NavigationForgotPasswordMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    msg.addData(getName(MessageEnum.NavigationForgotPasswordPageInfo), "email");
    this.send(msg);
    // Merge Engine - Navigation - btnForgotPassword - End
  }

  goToSocialLogin() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationSocialLogInMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  doEmailLogIn(): boolean {
    if (
      this.state.email === null ||
      this.state.email.length === 0 ||
      !this.emailReg.test(this.state.email)
    ) {
      this.showAlert("Error", configJSON.errorEmailNotValid);
      return false;
    }

    if (this.state.password === null || this.state.password.length === 0) {
      this.showAlert("Error", configJSON.errorPasswordNotValid);
      return false;
    }

    const header = {
      "Content-Type": configJSON.loginApiContentType,
    };

    const attrs = {
      email: this.state.email,
      password: this.state.password,
    };

    const data = {
      type: "email_account",
      attributes: attrs,
    };

    const httpBody = {
      data: data,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiEmailLoginCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.loginAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.loginAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }

  callGetValidationApi() {
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.validationApiCallId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.urlGetValidations
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }
  // Customizable Area Start

  getErrorMessage = () => {
    return (
     
      this.state.errorMessagePassword ||
      this.state.errorMessagePhone ||
      this.state.loginAPIError ||
      this.state.accNotFoundError ||  
      this.state.errorMsg
    );
  };


  handlePassword = (value: string) => {
    if (value.trim() =="") {
      this.setState({
       
        errorMessagePassword: I18n.t("incorrectPassword"),
        password: "",
      });
    }else{
      this.setState({  errorMessagePassword: "",password: value})
    }
  };

 
  handleMouseDownPassword = (event: any) => {
    event.preventDefault();
  };


  getBorderColorTwo = (error: boolean, state: "default" | "hover" | "focus") => {
    if (error) {
      return "#FCA5A5";
    }
    if (state === "default") {
      return "#CBD5E1"; 
    }
    return "#527FE2";
  };

  handlePhoneNumber = (e: any) => {
    const phoneValue = typeof e === "string" ? e.trim() : e;

    if (!phoneValue || phoneValue === "0") {
      this.setState({
        phone: e,
        errorMessagePhone: I18n.t("errorMessagePhone"),
      });
      return;
    }
    
    this.setState({
      phone: e,
      errorMessagePhone: "",
    });
  };
  

  validatePhoneNumberOnBlur = () => {
    const phoneNumber = this.state.phone.toString();

      if (!phoneNumber || phoneNumber === "0") {
        this.setState({
          errorMessagePhone: "",
        });
        return;
      }

      if (/^\+\d{1,4}$/.test(phoneNumber)) {
        this.setState({
          errorMessagePhone: I18n.t("errorMessagePhone"), 
        });
        return;
      }

      if (!isValidPhoneNumber(phoneNumber)) {
        this.setState({
          errorMessagePhone: I18n.t("errorMessagePhone"),
        });
        return;
      }
    
      this.setState({
        errorMessagePhone: "", 
      });
  };  

  handleLogin = async () => {
    if(!this.state.phone || !this.state.password){
        if(!this.state.password){
          this.setState({errorMessagePassword: I18n.t("incorrectPassword"),})
        }
        if(!this.state.phone ){
          this.setState({errorMessagePhone : I18n.t("errorMessagePhone")})
        }
        return
      }

    const data = 
    {
      "data": {
        "type": "sms_account",
        "attributes": {
          "full_phone_number": this.state.phone,
          "password":this.state.password
        }
      }}

    this.LoginAPIcallId = await this.LoginAPIcall({

        ContentType: 'application/json',
        methods: 'POST',
        endPoint: 'bx_block_login/logins',

        Body: data,


    })
}

  LoginAPIcall = async (data: {ContentType:string,methods:string,endPoint:string,Body:{data:{type:string,attributes:{
    full_phone_number:number,password:string
  }
  }
  }
  }) => {
    const { ContentType, methods, endPoint, Body } = data;
    const Header = {
      "Content-Type": ContentType,
    };
    const SignInAcoount = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    SignInAcoount.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(Header)
    );
    SignInAcoount.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    SignInAcoount.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      methods
    );
    Body &&
      SignInAcoount.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),

        JSON.stringify(Body)
      );
    runEngine.sendMessage(SignInAcoount.id, SignInAcoount);
    return SignInAcoount.messageId;
  };
goToHomeScreen(){
  const msg: Message = new Message(
    getName(MessageEnum.NavigationHomeScreenMessage)
  );
  msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
  this.send(msg);
}

goToResgistrationScreen=()=>{
  const msg: Message = new Message(
    getName(MessageEnum.NavigateEmailSignUpMessage)
  );
  msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
  this.send(msg);
}


handleAPIresponse=(responseJson:{
  errors: any;meta:{token:string,refresh_token:string,id:number}
})=>{
  if (responseJson && responseJson.meta && responseJson.meta.token) {
    setStorageData("token", responseJson.meta.token)
    this.NavigatetoAppNavigation()
  } else {
      switch (responseJson.errors[0].failed_login) {
        case "Login Failed" : 
            this.setState({
                errorMessagePassword : this.loginError(this.state.currentLanguage),
                accNotFoundError : ""
            });

            break;
        case "Account not found, or not activated" : 
            this.setState({
                accNotFoundError : this.loginAcc(this.state.currentLanguage),
                loginAPIError:""
            });
            break;
        default:
          break;
      }
  }
}

loginAcc = (languageCh: string) => {
  return languageCh === "ar"
    ? "لم يتم العثور على الحساب، أو لم يتم تفعيله"
    : "Account not found, or not activated.";
};

loginError = (languageCh: string) => {
  return languageCh === "ar"
    ? "الرجاء إدخال كلمة المرور الصحيحة للمتابعة."
    : "Please enter the correct password to proceed.";
};

NavigatetoForgotPassword=()=>{
  const navigateMessage: Message = new Message(
      getName(MessageEnum.NavigationMessage)
  );
  navigateMessage.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "ForgotPassword"
  );
  navigateMessage.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
  );

  this.send(navigateMessage)

}

NavigatetoCatalogue=()=>{
  const navigateMessage: Message = new Message(
      getName(MessageEnum.NavigationMessage)
  );
  navigateMessage.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "Catalogue"
  );
  navigateMessage.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
  );
  this.send(navigateMessage)
}

NavigatetoAppNavigation=()=>{
  const navigateMessage: Message = new Message(
      getName(MessageEnum.NavigationMessage)
  );
  navigateMessage.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "AppNavigation"
  );
  navigateMessage.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
  );
  this.send(navigateMessage)
}
  // Customizable Area End
}