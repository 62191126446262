import { CSSProperties } from "react";

export const getLanguageStyles = (
  getValueBasedOnLanguage: (firstValue: any, secondValue: any) => any
): { [key: string]: CSSProperties } => ({
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: "10px 0",
    marginTop: "75px",
    flexDirection: getValueBasedOnLanguage("row-reverse", "row"),
  },
  languageSwitchTextStyle: {
    margin: "0 5px",
    fontFamily: getValueBasedOnLanguage("Noto Sans Arabic", "Poppins"),
    fontSize: "16px",
    textAlign: getValueBasedOnLanguage("right", "left"),
    color: "#555",
    cursor: "pointer",
    fontWeight: 400,
    direction: getValueBasedOnLanguage("rtl", "ltr"),
  },
  inActiveButton: {
    fontWeight: 400,
    margin: "0 7px",
    borderRadius: "4px",
    border: "1px solid #E0E7FF",
    padding: "6px 12px",
    fontFamily: getValueBasedOnLanguage("Noto Sans Arabic", "Poppins"),
    fontSize: "14px",
    textTransform: "none" as "none", // Explicitly cast to valid type
    color: "#000",
    backgroundColor: "#fff",
    cursor: "pointer",
    textAlign: getValueBasedOnLanguage("right", "left"),
    direction: getValueBasedOnLanguage("rtl", "ltr"),
  },
  activeButton: {
    fontWeight: 400,
    display: "flex",
    alignItems: "center",
    borderRadius: "4px",
    border: "1px solid #E0E7FF",
    padding: "6px 12px",
    margin: "0 10px",
    fontFamily: getValueBasedOnLanguage("Noto Sans Arabic", "Poppins"),
    fontSize: "14px",
    textTransform: "none" as "none", // Explicitly cast to valid type
    color: "#527FE2",
    backgroundColor: "#E0E7FF",
    cursor: "pointer",
    textAlign: getValueBasedOnLanguage("right", "left"),
    direction: getValueBasedOnLanguage("rtl", "ltr"),
  },
});
