import React from "react";

// Customizable Area Start
import {
  Box,
  Button,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
  styled,
} from "@material-ui/core";
import { passImg, helpTitle, back, backGroundImg, unChecked, checked,arBackButton } from "./assets";
import { I18n } from "../../../components/src/languageJson/i18n";
import LanguageIcon from "@material-ui/icons/Language";
import { getLanguageStyles } from "../../../components/src/LanguageStyles.web";
import LanguageSwitcher from "../../../components/src/LanguageSwitcher.web";

const ErrorText = styled(Typography)({
  marginTop: "2px",
  fontSize: "14px",
  color: "rgb(249, 75, 75)",
  fontFamily: 'Poppins, sans-serif',
  fontWeight: 400,
  lineHeight: "1.5",
  letterSpacing: "0.00938em",
});

const BackBox = styled(Box)({
  height: "56px",
  width: "56px",
  position: "relative",
  top: "100px", left: "25px",
  cursor: "pointer",
  "@media (min-width:500px) and (max-width:1000px)": {
    top: "10px",
    left: "20px"
  },
  "@media (min-width:1000px) and (max-width:1280px)": {
    top: "35px",
    left: "20px"
  },
  "@media (min-width:1280px) and (max-width:1400px)": {
    top: "40px",
    left: "20px"
  },
  "@media (min-width:310px) and (max-width:500px)": {
    top: "0px",
    left: "10px"
  },
});

const LeftGrid = styled(Grid)({
  '@media (max-width:1020px)': {
    display: 'none' as 'none',
  },
});

const BoxWidth647 = styled(Box)({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  backgroundColor: "#FFFFFF",
  maxWidth: "100%",
  minHeight: "100%",
  width: "647px",
  "@media (min-width:1000px) and (max-width:1280px)": {
    width: "580px"
  },
  "@media (min-width:1280px) and (max-width:1400px)": {
    width: "550px"
  },
  "@media (min-width:600px) and (max-width:720px)": {
    width: "410px"
  },
});

const BoxWidth = styled(Box)({
  flexDirection: "column",
  display: "flex",
  justifyContent: "center",
  width:"100%",
  "@media (min-width:200px) and (max-width:410px)": {
    width: "260px"
  },
  "@media (min-width:600px) and (max-width:720px)": {
    width: "410px"
  },
});

const GridContainer = styled(Grid)({
  height: "100vh",
  width: "90vw",
  '@media (max-width:1020px)': {
    justifyContent: "center",
  },
});



const BoxWidth100 = styled(Box)({
  width: "90%",
  "@media (min-width:410px) and (max-width:600px)": {
    width: "80%"
  },
  "@media (min-width:200px) and (max-width:410px)": {
    width: "100%"
  }
});

const ConditionBox = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  marginBottom: "24px",
  "@media(max-width:430px)":{
    flexDirection:"column",
    gap:"6px",

  },
})


// Customizable Area End


import NewPasswordController, { Props } from "./NewPasswordController.web";
import { Visibility, VisibilityOff } from "@material-ui/icons";



export default class NewPassword extends NewPasswordController {
  constructor(props: Props) {

    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  ForgotTypo = styled(Typography)({
    fontFamily: this.getValueBasedOnLanguage("Noto Sans Arabic","Poppins"),
    direction: this.getValueBasedOnLanguage("rtl", "ltr"),
    fontSize: "48px ",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontWeight: 700,
    lineHeight: "56px ",
    marginTop: "24px",
    cursor: "pointer",
    whiteSpace: "nowrap",
  "@media(max-width:1280px)":{
    fontSize:"41px"
  },
  "@media(max-width:1380px)":{
    fontSize:"42px"
  },
  "@media(max-width:610px)":{
    fontSize:"24px"
  },
  });
  
  ForgotSubTypo = styled(Typography)({
    fontFamily: this.getValueBasedOnLanguage("Noto Sans Arabic","Poppins"),
    direction: this.getValueBasedOnLanguage("rtl", "ltr"),
    fontSize: "20px ",
      fontWeight: 400,
      lineHeight: "28px ",
      color: "#000000",
      whiteSpace: "nowrap",
      "@media(max-width:1280px)":{
        fontSize:"18px"
      },
      "@media(max-width:1380px)":{
        fontSize:"19px"
      },
      "@media(max-width:610px)":{
        fontSize:"12px"
      },
      "@media(max-width:430px)":{
        fontSize:"10px"
      },
  });

  ErrorMsg = styled(Box)({
    fontSize: "1rem",
    fontFamily: this.getValueBasedOnLanguage("Noto Sans Arabic","Poppins"),
    direction: this.getValueBasedOnLanguage("rtl", "ltr"),
    fontWeight: 400,
    lineHeight: 1.5,
    borderRight: this.getValueBasedOnLanguage("4px solid #DC262",""),
    borderLeft: this.getValueBasedOnLanguage("","4px solid #DC2626"),
    "@media (max-width:1350px )": {
      width: "500px"
    },
    "@media (max-width:1100px )": {
      width: "426px"
    },
    "@media (max-width:500px )": {
      width: "80%"
    },
  });

  SubmitBtn = styled(Button)({
    background: "#527FE2",
    width: "100%",
    height: "56px",
    padding: "16px",
    gap: "8px", borderRadius: "8px",
    color: "#FFF",
    marginTop: "48px",
    fontFamily: this.getValueBasedOnLanguage("Noto Sans Arabic","Poppins"),
    direction: this.getValueBasedOnLanguage("rtl", "ltr"),
    fontSize: "20px ", fontWeight: 600,
    lineHeight: "24px", cursor: "pointer",
    textTransform: "none",
    '&:hover': {
      backgroundColor: "#527FE2",
      boxShadow: 'none',
    },
    '&:active': {
      backgroundColor: "#527FE2",
      boxShadow: 'none',
    }
  });

  InputField = styled(TextField)({
    marginBottom: "24px",
    fontSize: "18px",
    fontFamily: this.getValueBasedOnLanguage("Noto Sans Arabic","Poppins"),
    direction: this.getValueBasedOnLanguage("rtl", "ltr"),
    fontWeight: 400,
    lineHeight: "25px",
    "&:has(+ .error-text)": {
      marginBottom: "0",
    },
    "& input::placeholder": {
      color: "#94A3B8",
      opacity: 1,
    },
    "& .MuiInputBase-input[type='date']": {
      textTransform: "capitalize",
      color: "#334155",
      lineHeight: "25px",
    },
    "& .MuiFormHelperText-root.Mui-error": {
      direction: this.getValueBasedOnLanguage("rtl", "ltr"),
      fontFamily: this.getValueBasedOnLanguage("Noto Sans Arabic","Poppins"),
      lineHeight: "18px",
      fontWeight: 400,
      fontSize: "14px",
      color: "#DC2626",
    },
    "& .MuiOutlinedInput-root.Mui-focused.MuiOutlinedInput-notchedOutline": {
      borderColor: "#CBD5E1",  borderWidth: "1px",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderWidth: "1px", 
      borderColor: "#CBD5E1",
      borderRadius: "8px",
    },
    
    "& .MuiFormHelperText-contained": {
      marginLeft: "0px", marginRight: "0px",
    },
    "& .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #CBD5E1",
    }, 
    "& .MuiOutlinedInput-root" : {
      "&:hover fieldset" : {
        borderColor: "#527FE2",
      }, 
      "&.Mui-focused fieldset": {
        borderWidth: "1px",
        borderColor: "#527FE2",
      },
    },
    "& .MuiOutlinedInput-input": {
      padding: "20px 8px",
    },
    "& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline" : {
      borderColor: "#F87171",
    }, 
    "& .MuiInputBase-root": {
      color : "#334155" , 
    },
    "@media (max-width:990px )": {
      minWidth: "unset",  maxWidth: "unset" ,
    },

  }); 

  webStyle = {
    boxTitle: {
      display: "flex",
      justifyContent: "center",
      height: "100%"
    },
    subBox: {
      display: "flex",
      flexDirection: "column" as "column",
      gap: "64px",
      width: "100%",
      margin: "auto",
      textAlign: "center" as "center"
    },
    welcomeBox: {
      display: "flex", 
      flexDirection:this.getValueBasedOnLanguage("row-reverse", "row"),
      flexWrap: "wrap" as "wrap",
      alignItems: "center", 
      gap: "16px", 
    }, helpTitle: {
      direction: this.getValueBasedOnLanguage("rtl", "ltr"),
      fontSize: "58px", 
      lineHeight: "24px",
      fontWeight: 600, 
      whiteSpace: "nowrap",
      color: "#FFFFFF",
      fontFamily: this.getValueBasedOnLanguage("Noto Sans Arabic","Poppins"),
    }, imgTit: {
      width: "183px",
      textAlign: "center" as "center",
      height: "74px",
    }, sign: {
      fontFamily: this.getValueBasedOnLanguage("Noto Sans Arabic","Poppins"),
      alignItems: "center",
      fontSize: "30px ",
      color: "#000000",
      lineHeight: "40px",
      direction: this.getValueBasedOnLanguage("rtl", "ltr"),
      justifyContent: "center",
      marginTop: "24px",
      marginBottom: "24px",
      fontWeight: 700,
    }, helpSubTitle: {
      lineHeight: "48px",
      fontSize: "30px",
      color: "#FFFFFF",
      fontWeight: 400,
      whiteSpace: "wrap",
      textAlign:"left" as "left",
      direction: this.getValueBasedOnLanguage("rtl", "ltr"),
      fontFamily: this.getValueBasedOnLanguage("Noto Sans Arabic","Poppins"),
    },
  }

  styles: { [key: string]: React.CSSProperties } = {
    ...getLanguageStyles(this.getValueBasedOnLanguage),
    passwordHeadingItem:{
      display: "flex",
      gap:'6px',
      alignItems: "center",
      fontSize: "12px",
      fontFamily: this.getValueBasedOnLanguage("Noto Sans Arabic","Poppins"),
      direction: this.getValueBasedOnLanguage("rtl", "ltr"),
      lineHeight: "24px",
      cursor: "pointer"
    },
    apiForgotSuccess: {
      borderLeft: this.getValueBasedOnLanguage("", "4px solid red"),
      borderRight: this.getValueBasedOnLanguage("4px solid red", ""), 
      padding: "12px", 
      backgroundColor: "#D1FAE5",
      marginBottom: "24px",
    },
    apiForgotFalied: {
      borderLeft: this.getValueBasedOnLanguage("", "4px solid red"),
      borderRight: this.getValueBasedOnLanguage("4px solid red", ""), 
      padding: "12px",
      backgroundColor: "#FEE2E2",
      marginBottom: "24px",
    },
    buttonStyle: {
      background: "#527FE2",
      width: "647px",
      height: "61px",
      padding: "16px",
      gap: "8px",
      borderRadius: "8px",
      color: "white",
      marginTop: "48px",
      fontFamily: this.getValueBasedOnLanguage("Noto Sans Arabic","Poppins"),
      direction: this.getValueBasedOnLanguage("rtl", "ltr"),
      fontSize: "20px ",
      fontWeight: 600, 
      lineHeight: "24px",
      cursor: "pointer",
      textTransform: "none"
    },
    accountStyleBold: {
      fontFamily: this.getValueBasedOnLanguage("Noto Sans Arabic","Poppins"),
      direction: this.getValueBasedOnLanguage("rtl", "ltr"),
      fontSize: "16px ", cursor: "pointer",
      lineHeight: "24px",
      color: "#527FE2",
    },
    resend: {
      display: 'flex', 
      textAlign: 'center',
      justifyContent: 'center',
      flexWrap: 'wrap', 
      fontSize: '14px',
      width: '100%', 
      margin: '30px 0px',
      fontWeight: '600'
    },
    resendBtn: {
      marginRight: '4px'
    },
  };
  // Customizable Area End
  render() {
    return (
      // Customizable Area Start
      <Box sx={{ display: 'flex', height: '100vh', width: '100vw' }}>
        <Box sx={{ display: 'flex', height: '100vh', width: '100vw' }}>
          <Box style={{ width: "100vw", height: "100vh", overflow: "auto", display: "flex", justifyContent: "center", backgroundImage: `url(${backGroundImg})`, backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: "center", position: "absolute" }}>
            {this.state.currentLanguage === "en" &&
              <BackBox>
                <img style={{ height: "100", width: "100%" }} src={back} alt="backButton..." data-test-id="NavigatetoLogin" onClick={this.NavigatetoForgotPassword} />
              </BackBox>
            }
            <GridContainer container spacing={3}>
              <LeftGrid
                item  lg={6} md={4} >
                <Box style={this.webStyle.boxTitle}>
                  <Box style={this.webStyle.subBox}>
                    <Box style={this.webStyle.welcomeBox}>
                      <Typography style={{
                        ...this.webStyle.helpTitle,
                        fontSize: 'clamp(3rem, 4vw, 4.5rem)',
                        direction: this.getValueBasedOnLanguage("rtl", "ltr")
                      }}>
                        {I18n.t("welcometo")}
                      </Typography>
                      <img src={helpTitle} style={this.webStyle.imgTit} />
                    </Box>
                    <Typography style={{
                      ...this.webStyle.helpSubTitle,
                      fontSize: 'clamp(1.5rem, 1.8vw, 2.2rem)',
                      direction: this.getValueBasedOnLanguage("rtl", "ltr"),
                      textAlign: this.getValueBasedOnLanguage("right","left"),
                    }}>
                      {I18n.t("loginTitle")}
                    </Typography>
                  </Box>
                </Box>
              </LeftGrid>
              <Grid
                item
                lg={6} md={8} sm={12} xs={12}
                style={{
                  display: "flex", 
                  width: "100%", 
                  alignItems: "end",
                  justifyContent: "center",
                }}
              >
                <Box 
                style={{ 
                  maxWidth: "100%", 
                  height: "937px", 
                  overflow: "hidden" ,
                  marginTop:"100px",
                  }}
                  >
                  <Box
                    style={{ 
                      height: "131px", 
                      backgroundColor: "#000000", 
                      borderTopLeftRadius: "45px", 
                      borderTopRightRadius: "45px", 
                      display: "flex", 
                      justifyContent: "center", 
                      alignItems: "center" 
                      }}>
                    <Box style={{ height: "74px", width: "183px" }}>
                      <img src={helpTitle} style={{ height: "100%", width: "100%" }} />
                    </Box>
                  </Box>
                  <BoxWidth647>
                    <BoxWidth>
                      <Box
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          width: "100%"
                        }}
                      >
                        <BoxWidth100>
                          <Box style={{ display: "flex", flexDirection: "column", gap: "24px", justifyContent: "center", textAlign: "center", marginBottom: "24px" }}>
                            <this.ForgotTypo>{I18n.t("createNewPassword")}</this.ForgotTypo>
                            <this.ForgotSubTypo>{I18n.t("createNewPasswordSubTitle")}</this.ForgotSubTypo>
                          </Box>
                          {this.state.apiResponseMessage && (
                            <this.ErrorMsg
                              style={this.styles.apiForgotFalied}
                            >
                              <Typography data-test-id="apiResponseMessage">
                                {this.state.apiResponseMessage}
                              </Typography>
                            </this.ErrorMsg>
                          )}
 
                          {this.state.apiResponsesuccessMessage && (
                            <this.ErrorMsg
                              style={this.styles.apiForgotSuccess}
                            >
                              <Typography data-test-id="apiResponsesuccessMessage">
                                {this.state.apiResponsesuccessMessage}
                              </Typography>
                            </this.ErrorMsg>
                          )}
                          
                          <this.InputField
                            data-test-id="passTestID"
                            id="password"
                            placeholder={I18n.t("password")}
                            onChange={(event) => this.handlePassword(event.target?.value)}
                            value={this.state.newPassword}
                            fullWidth
                            type={this.state.passwordVisible ? "" : "password"}
                            variant="outlined"
                            error={this.state.errorColorshow}
                            InputProps={{
                              style: {
                                textAlign: this.getValueBasedOnLanguage("right", "left"),
                                direction: this.getValueBasedOnLanguage("rtl", "ltr")
                              },
                              startAdornment: (
                                <InputAdornment position="start">
                                  <img src={passImg} alt="Password Icon" />
                                </InputAdornment>
                              ),
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton
                                    data-test-id="showpassword"
                                    onClick={this.handleShowPassword}
                                    aria-label="toggle password visibility"
                                    edge="end"
                                  >
                                    {this.state.passwordVisible ? (
                                      <Visibility />
                                    ) : (
                                      <VisibilityOff />
                                    )}
                                  </IconButton>
                                </InputAdornment>
                              ),
                            }}
                          />
                          {
                            this.state.errorMessagePassword && (
                              <ErrorText
                                className="error-text"
                                style={{
                                  marginBottom:"1rem",
                                  textAlign: this.getValueBasedOnLanguage("right", "left"),
                                  direction: this.getValueBasedOnLanguage("rtl", "ltr")
                                }}
                              >
                                {this.state.errorMessagePassword}
                              </ErrorText>
                            )
                          }
                            <ConditionBox
                             style={{
                              textAlign: this.getValueBasedOnLanguage("right", "left"),
                              direction: this.getValueBasedOnLanguage("rtl", "ltr")
                            }}
                            >
                              <Box
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  gap: "6px",
                                  textAlign: this.getValueBasedOnLanguage("right", "left"),
                                  direction: this.getValueBasedOnLanguage("rtl", "ltr")
                                }}
                              >
                                {[
                                  { text:  `${I18n.t("oneLowercaseLetter")}`,
                                    isMet: this.state.conditionsMet.hasLowercase,
                                  },
                                  { text: `${I18n.t("oneNumber")}`,
                                    isMet: this.state.conditionsMet.hasNumber,
                                  },
                                  { text: `${I18n.t("eightChar")}`,
                                    isMet: this.state.conditionsMet.isLongEnough,
                                  },
                                ].map((item, index) => (
                                  <Typography
                                    key={index}
                                    style={this.styles.passwordHeadingItem}
                                  >
                                    <img style={{ width: "16px", height: "16px" }} src={this.getCheckStatus(item, checked, unChecked)} alt="Tick Icon" />
                                    {item.text}
                                  </Typography>
                                ))}
                              </Box>
                              <Box
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  gap: "6px",
                                  textAlign: this.getValueBasedOnLanguage("right", "left"),
                                  direction: this.getValueBasedOnLanguage("rtl", "ltr")
                                }}
                              >
                                {[
                                  { text: `${I18n.t("oneUppercaseLetter")}`,
                                    isMet: this.state.conditionsMet.hasUppercase,
                                  },
                                  { text: `${I18n.t("specChar")}`,
                                    isMet: this.state.conditionsMet.hasSpecialChar,
                                  },
                                ].map((item, index) => (
                                  <Typography
                                    key={index}
                                    style={this.styles.passwordHeadingItem}
                                  >
                                    <img style={{ width: "16px", height: "16px" }} src={this.getCheckStatus(item, checked, unChecked)} alt="Tick Icon" />
                                    {item.text}
                                  </Typography>
                                ))}
                              </Box>
                          </ConditionBox>
                          <this.InputField
                            id="confirm-password"
                            data-test-id="confirmPassTestID"
                            placeholder={I18n.t("confirmPassword")}
                            value={this.state.confirmPassword}
                            onChange={(event) => this.handlePasswordConfirm(event.target.value)}
                            type={this.state.confirmPasswordVisible ? "" : "password"}
                            variant="outlined"
                            fullWidth
                            error={this.state.errorColorshow}
                            InputProps={{
                              style: {
                                textAlign: this.getValueBasedOnLanguage("right", "left"),
                                direction: this.getValueBasedOnLanguage("rtl", "ltr")
                              },
                              startAdornment: (
                                <InputAdornment position="start">
                                  <img src={passImg} alt="Password Icon" />
                                </InputAdornment>
                              ),
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={this.handleShowConfirmPassword}
                                    edge="end"
                                  >
                                    {this.state.confirmPasswordVisible ? (
                                      <Visibility />
                                    ) : (
                                      <VisibilityOff />
                                    )}
                                  </IconButton>
                                </InputAdornment>
                              ),
                            }}
                          />
                          {
                            this.state.errorMessageConfirm && (
                              <ErrorText
                                className="error-text"
                                style={{
                                  marginBottom:"1rem",
                                  textAlign: this.getValueBasedOnLanguage("right", "left"),
                                  direction: this.getValueBasedOnLanguage("rtl", "ltr")
                                }}
                              >
                                {this.state.errorMessageConfirm}
                              </ErrorText>
                            )
                          }
                          <this.SubmitBtn
                            variant="contained"
                            data-test-id="submitBtnTestId"
                            onClick={this.handleAllValidation}
                          >
                            {I18n.t("submit")}
                          </this.SubmitBtn>

                          <LanguageSwitcher
                            currentLanguage={this.state.currentLanguage}
                            changeLanguage={this.changeLanguage}
                            getValueBasedOnLanguage={this.getValueBasedOnLanguage}
                          />

                        </BoxWidth100>
                      </Box>
                    </BoxWidth>
                  </BoxWidth647>
                </Box>
              </Grid>
            </GridContainer>
            {this.state.currentLanguage === "ar" &&
              <BackBox style={{top:'110px'}}>
                <img style={{ height: "100", width: "100%" }} src={arBackButton} alt="backButton..." data-test-id="NavigatetoLogin" onClick={this.NavigatetoForgotPassword} />
              </BackBox>
            }
          </Box>
        </Box>
      </Box>
      // Customizable Area End
    );
  }
}
// Customizable Area Start

const ImageGrid = styled(Box)({
  "@media (max-width:1050px )": {
    display: "none",
  },
});


const HeadingText = styled(Typography)({
  "@media (max-width:1050px )": {
    fontSize: "48px ",
  },

  "@media (max-width:899px )": {
    fontSize: "32",
  },

  fontFamily: "Poppins",
  fontSize: "56px ", display: "flex",
  alignItems: "center",
  fontWeight: 600, marginBottom: "9px ",
  color: "#0F172A",


});

const SubHeadingText = styled(Typography)({
  "@media (max-width:1050px )": {
    fontSize: "18px ",
  },
  "@media (max-width:899px )": {
    fontSize: "16",
  },
  display: "flex", justifyContent: "center",
  transition: "margin-bottom 0.3s ease",
  fontFamily: "Poppins ", fontSize: "22px ",
  fontWeight: 400, color: "#0F172A",
  marginBottom: "64px",


});


// Customizable Area End
